import secureLocalStorage from 'react-secure-storage'
import React, { useState } from "react";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { Input } from "@mui/material";

import { useEffect } from 'react';
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const EditUser = () => {

    const [disabled, setDisabled] = useState(false);
    const [premiumDisabled, setPremiumDisabled] = useState(false);
    let navigate = useNavigate();

    const [planName, setPlanName] = useState("null");
    const [purchaseObject, setPurchaseObject] = useState("null");
    const [whereToSet, setWhereToSet] = useState("null");
    const [premiumReason, setPremiumReason] = useState("null");

    const [uid, setUid] = useState("null");
    const [userPresent, setUserPresent] = useState(false);

    const token = secureLocalStorage.getItem("token");

    const givePremiumHandler = () => {
        // const requestOptions = {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
        //     body: JSON.stringify({ "uid": uid, "dashboardUid": secureLocalStorage.getItem("uid"), "purchaseObject": purchaseObject, "whereToSet": whereToSet, "reason": premiumReason })
        // };

        // fetch('https://healify.blockerx.net/api/dashboard/givePremium', requestOptions)
        //     .then(response => response.json())
        //     .then((data) => {
        //         if (data.status === "success") {
        //             setPremiumDisabled(true);
        //         } else if (data.status === "invalid_token") {
        //             secureLocalStorage.clear();
        //             navigate("/tokenExpired");
        //         }
        //     });
    }

    const userInfoHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": uid })
        };

        fetch('https://healify.blockerx.net/api/dashboard/userInfo', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(uid);
                console.log(data.status, data.data.length, data.data);
                if (data.status === "success" && data.data.length > 0) {
                    setUserPresent(true);
                    setDisabled(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setUserPresent(false);
                }
            });
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    return (
        <>
            <Box sx={{ flexGrow: 2 }}>
                <h1 className="header-top mt-5">Edit User Info</h1>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={methodList}
                            sx={{ width: 450 }}
                            onChange={(event, item) => {
                                setPlanName(item.label);
                                // setPurchaseObject(item.purchaseObject);
                                // setWhereToSet(item.whereToSet);
                            }}
                            renderInput={(params) => <TextField {...params} label="Which field you want to change?" />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            disablePortal
                            placeholder="Enter value"
                            id="combo-box-demo"
                            sx={{ width: 450 }}
                            onChange={(event, item) => {
                                setUid(event.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Button
                            className="h-12"
                            variant="contained"
                            sx={{ width: 450 }}
                            disabled={premiumDisabled}
                            onClick={givePremiumHandler}>
                            Edit User
                        </Button>
                    </Grid>

                </Grid>
            </Box>
        </>
    )
}

const methodList = [
    {
        label: 'Community username'
    }, {
        label: 'Friend email'
    }
];

export default EditUser;