import secureLocalStorage from 'react-secure-storage'
import { Autocomplete, Box, Button, CircularProgress, Input, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import './ReplyToReviews.css'
import { useNavigate } from 'react-router-dom';
import { EditNotificationsRounded, Star, WorkspacePremium } from '@mui/icons-material';
import { showTimeInAgoFormat } from '../../Utils/time';
import MuiAlert from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';

import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: 800,
    bgcolor: 'background.paper',
    boxShadow: 24,
    overflow: 'scroll',
    p: 4,
};

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const fiveStarReplies = [
    "Thank you for your fantastic 5-star review! We're thrilled to hear that BlockerX is helping you create a safer online experience.",
    "Thanks for your review! Please recommend our app to your friends, and don’t hesitate to shoot us a note at support@blockerx.org if you have any questions.",
    "We're delighted by your 5-star rating! It's great to know that BlockerX is making a positive impact on your digital well-being.",
    "Thanks for the 5 star rating. If you have any feedback or suggestions, please write to us at support@blockerx.org. We would love to hear from you!",
    "Your 5-star review made our day! We're grateful that BlockerX is contributing to your online safety.",
    "Thanks for your review! Please recommend our app to your friends, and don’t hesitate to shoot us a note at support@blockerx.org if you have any questions.",
    "Thank you for your outstanding 5-star review! Your support motivates us to keep BlockerX effective and reliable.",
];

const fourStarReplies = [
    "Thank you for your 4-star review! We're glad to hear that BlockerX is helping you manage your online content more effectively. If you've been enjoying our app, we'd love to have your support with a 5-star rating in the future!",
    "We appreciate your 4-star rating! We're pleased that BlockerX is assisting you in creating a safer digital environment. If you find our app valuable, consider sharing your experience with a 5-star review next time!",
    "Your 4-star review is valuable feedback for us! We're glad you find BlockerX's features like the accountability partner and community beneficial. If you continue to enjoy our app, a 5-star rating would mean the world to us!",
    "Thank you for taking the time to give us 4 stars! Your feedback helps us improve BlockerX and provide a better experience for you. If you're enjoying the app, please consider leaving a 5-star review when you can!"
];


const ReplyToReviews = () => {

    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [reviewTextInModal, setReviewTextInModal] = useState('User Review')
    const [starCountInModal, setStarCountInModal] = useState(1)
    const [authorNameInModal, setAuthorNameInModal] = useState('Unknown user')
    const [reviewModalId, setReviewModalId] = useState('')
    const [reviewLangInModal, setReviewLangInModal] = useState('')

    const [currentFilter, setCurrentFilter] = useState('Latest')
    const [currentFilterId, setCurrentFilterId] = useState('latest')

    const [aptReply, setAptReply] = useState('')

    const [repliesList, setRepliesList] = useState([])
    const [scoresList, setScoresList] = useState([])

    const handleOpen = (id, text, stars, author_name, review_lang) => {
        setReviewModalId(id)
        setReviewTextInModal(text)
        setStarCountInModal(stars)
        setAuthorNameInModal(author_name)
        setOpen(true);
        GetListOfReplies(text, stars)
        setReviewLangInModal(review_lang)
    }

    // Success and Error alerts

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const handleClose = () => setOpen(false);
    const [reviews, setReviews] = useState([])
    const [state, setState] = useState('all')

    const [displayReviews, setDisplayReviews] = useState([])

    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid")

    const GetListOfReplies = (TEXT, STARS) => {
        console.log('Count of stars : ', STARS)
        if (STARS > 3) {
            // Show four and five star replies
            if (STARS === 4) {
                const randomIndex = Math.floor(Math.random() * fourStarReplies.length);
                const randomlySelected = fourStarReplies[randomIndex];
                setAptReply(randomlySelected)
            } else {
                const randomIndex = Math.floor(Math.random() * fiveStarReplies.length);
                const randomlySelected = fiveStarReplies[randomIndex];
                setAptReply(randomlySelected)
            }
        } else {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    "message": TEXT
                })
            };
            fetch('https://healify.blockerx.net/api/parse/replyToReview', requestOptions)
                .then(response => response.json())
                .then((data) => {
                    console.log(data);
                    setAptReply(data.reply)
                    setScoresList(data.classifications)
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }

    const GmailUsersHandler = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        };
        fetch('https://dev.blockerx.net/api/dashboard/reviews', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Reviews List : ', data.data.data.reviews)
                setReviews(data.data.data.reviews)
                setDisplayReviews(data.data.data.reviews)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const RemoveRepliedReview = () => {
        let newArray = []
        reviews.forEach((eachReview) => {
            if (eachReview.reviewId !== reviewModalId) {
                newArray.push(eachReview)
            }
        })
        setReviews(newArray)
    }

    const ReplyToCommentHandler = () => {
        console.log('reviewmodalid L: ', reviewModalId)
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "id": reviewModalId,
                "reply": aptReply
            })
        };
        fetch('https://dev.blockerx.net/api/dashboard/reviews', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data ::: ', data);
                if (data.status === 200) {
                    setSuccessMessage('Replied successfully')
                    setOpenSuccessAlert(true)
                    handleClose()
                    RemoveRepliedReview()
                } else {
                    setErrorMessage('Something went wrong')
                    handleClose()
                }
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const showIntents = (text) => {
        // return text
        const words = text.split("_")
        let result = ''
        words.forEach((word) => {
            result += capitalizeFirstLetter(word) + ' '
        })
        return result
    }

    const languageNames = new Intl.DisplayNames(['en'], {
        type: 'language'
    });

    const regionNames = new Intl.DisplayNames(
        ['en'], { type: 'region' }
    );

    useEffect(() => {
        GmailUsersHandler(state)
    }, [state])

    const GoToGoogleTranslate = () => {
        window.open(`https://translate.google.com/?sl=auto&tl=${reviewLangInModal}&text=${aptReply}&op=translate&hl=en`)
    }

    const ReportForTraining = () => {
        setSuccessMessage('Reply has been added for training.')
        setOpenSuccessAlert(true)
    }

    const checkEditedReviews = (item) => {

        try {
            const userCommentTime = Number(item.comments[0].userComment.lastModified.seconds + '000')
            const developerCommentTime = Number(item.comments[1].developerComment.lastModified.seconds + '000')

            if (userCommentTime > developerCommentTime) {
                return true
            } else {
                return false
            }
        } catch {
            return false
        }
    }

    const updateReviewsAsPerFilter = (id) => {
        if (id === 'latest') {
            // display reviews == latest reviews
            setDisplayReviews(reviews)
        } else if (id === 'negative') {
            // display reviews == negative condition
            let newArray = []
            reviews.forEach((eachReview) => {
                const stars = eachReview.comments[0].userComment.starRating
                if (stars < 4) {
                    newArray.push(eachReview)
                }
            })
            setDisplayReviews(newArray)
        } else if (id === 'reply_pending') {
            // display reviews == pending reply condition
            let newArray = []
            reviews.forEach((eachReview) => {
                const stars = eachReview.comments.length
                if (stars === 1) {
                    newArray.push(eachReview)
                }
            })
            setDisplayReviews(newArray)
        } else if (id === 'edited_reviews') {
            let newArray = []
            reviews.forEach((eachReview) => {
                try {
                    const userCommentTime = Number(eachReview.comments[0].userComment.lastModified.seconds + '000')
                    const developerCommentTime = Number(eachReview.comments[1].developerComment.lastModified.seconds + '000')

                    if (userCommentTime > developerCommentTime) {
                        // only add to newArray if it is edited after developer review.
                        newArray.push(eachReview)
                    }
                } catch {
                    // Developer has not commented yet.
                }
            })
            setDisplayReviews(newArray)
        } else {
            // display reviews == latest reviews
            setDisplayReviews(reviews)
        }
    }

    return (
        <>
            <h1 className='header-top my-5'>BlockerX Reviews</h1>

            <div className='ml-5 mb-5 filter__bar__companies'>
                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentFilter}
                        options={filterList}
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setCurrentFilter(item.label)
                            setCurrentFilterId(item.id)
                            updateReviewsAsPerFilter(item.id)
                        }}
                        renderInput={(params) => <TextField {...params} label="Filter" />}
                    />
                </Grid>
            </div>
            <div class="main-div-snippets mb-20">
                {
                    displayReviews.length > 0 && displayReviews.map((item, index) => {
                        return (
                            <div className="card-list-snippets cursor-pointer" key={index}>
                                <article class="card-snippets">
                                    <div>
                                        {
                                            item.comments.length > 0 && item.comments.map((comment) => {
                                                if (comment.userComment) {
                                                    return (
                                                        <h2 className='my-4'> {comment.userComment.text} <span className='font-normal'></span></h2>
                                                    )
                                                }
                                            })
                                        }
                                        {
                                            Array.from(Array(item.comments[0].userComment.starRating), (_, i) => {
                                                return (<Star sx={{ color: "#efb73e", fontSize: "40px" }} />)
                                            })
                                        }
                                    </div>
                                    <div class="card-footer-snippets">
                                        <div class="card-meta-snippets card-meta--date">
                                            {item.authorName || 'Unknown User'}
                                        </div>
                                        <div class="card-meta-snippets card-meta--date">
                                            {item.comments[0].userComment.deviceMetadata && item.comments[0].userComment.deviceMetadata.productName || 'Unknown device'}
                                        </div>
                                        <div class="card-meta-snippets card-meta--date">
                                            {languageNames.of(item.comments[0].userComment.reviewerLanguage)}
                                        </div>
                                        <div class="card-meta-snippets card-meta--date">
                                            {showTimeInAgoFormat(Number(item.comments[0].userComment.lastModified.seconds + '000'))}
                                        </div>

                                        <div class="card-meta-snippets card-meta--date">
                                            {checkEditedReviews(item) && <EditNotificationsRounded sx={{ marginLeft: "15px", color: "coral", fontSize: "25px" }} />}
                                        </div>
                                        {
                                            item.comments.length === 1 && <div className="card-meta-snippets card-meta--date">
                                                <Button variant='contained' onClick={() => handleOpen(item.reviewId, item.comments[0].userComment.text, item.comments[0].userComment.starRating, item.authorName || 'Unknown user', item.comments[0].userComment.reviewerLanguage)}>Reply</Button>
                                            </div>
                                        }

                                        {
                                            item.comments.length > 1 && <div className="card-meta-snippets card-meta--date">
                                                <Button variant='contained' color='success' onClick={() => handleOpen(item.reviewId, item.comments[0].userComment.text, item.comments[0].userComment.starRating, item.authorName || 'Unknown user', item.comments[0].userComment.reviewerLanguage)}>Check Reply</Button>
                                            </div>
                                        }
                                    </div>
                                    {
                                        item.comments.length > 1 && <>
                                            <div class="card-footer-snippets">
                                                <div class="card-meta-snippets card-meta--date">
                                                    <h2 className='my-4'> {item.comments[1].developerComment.text}<span className='font-normal'></span></h2>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </article>
                            </div>
                        )
                    })
                }
                {
                    reviews.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
                        <CircularProgress size="7rem" color="grey" />
                    </Box>
                }
            </div>
            {/* Review Modal */}
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <h1 className='header-top pb-4'>Reply to User Review</h1>
                    <div class="card-list-snippets-2">
                        <article class="card-snippets">
                            <div>
                                <h2 className='text-2xl my-4'>{reviewTextInModal} </h2>
                                {
                                    Array.from(Array(starCountInModal), (_, i) => {
                                        return (<Star sx={{ color: "#efb73e", fontSize: "40px" }} />)
                                    })
                                }
                            </div>

                            <div class="card-footer-snippets">
                                <div class="card-meta-snippets card-meta--date">
                                    {authorNameInModal}
                                </div>
                            </div>
                        </article>
                    </div>
                    <h2 className='font-semibold text-2xl my-10 text-center'>Reply</h2>

                    < article class="card-snippets cursor-pointer">
                        <div className='sendreply__container'>
                            <div>
                                <h2 className=''>{aptReply}</h2>
                            </div>
                            <div>
                                <Button className='replySendButton' variant='contained' sx={{ marginLeft: 2 }} onClick={ReplyToCommentHandler}>Send</Button>
                            </div>
                        </div>
                        <div>
                            <TextField
                                type='text'
                                multiline
                                disablePortal
                                value={aptReply}
                                showlines={4}
                                id="combo-box-demo"
                                sx={{ width: "100%", marginTop: "20px" }}
                                onChange={(event, item) => {
                                    setAptReply(event.target.value);
                                }}
                            />
                        </div>
                    </article>

                    {
                        reviewLangInModal !== 'en' && <Button variant='contained' color='success' sx={{ marginLeft: '20px' }} onClick={GoToGoogleTranslate}> Get {languageNames.of(reviewLangInModal)} Reply</Button>
                    }

                    <div className='card-snippets-scores-list pt-10'>
                        {
                            scoresList.length > 0 && scoresList.map((data) => {
                                return (
                                    <article class="card-snippets-scores cursor-pointer">
                                        <div>
                                            <h2 className=''>{showIntents(data.intent)} = {(data.score * 100).toFixed(2)}%</h2>
                                        </div>
                                    </article>
                                )
                            })
                        }
                    </div>
                </Box>
            </Modal >
            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    )
}

export default ReplyToReviews

const filterList = [
    {
        id: 'latest',
        label: 'Latest'
    },
    {
        id: 'negative',
        label: 'Negative Reviews'
    },
    {
        id: 'reply_pending',
        label: 'Reply to Pending Reviews'
    },
    {
        id: 'edited_reviews',
        label: 'Edited Reviews'
    }
]