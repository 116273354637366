import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Autocomplete, Button, Grid, IconButton, Input, Snackbar, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ReactGA from 'react-ga'
import { useLocation, useNavigate } from 'react-router-dom'
import DeleteIcon from '@mui/icons-material/Delete'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

function convertMillisecondsToDate(ms) {
    return new Date(ms).toString()
}

const BlockerxProWhitelist = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [blockList, setBlockList] = useState([])
    const [inputUid, setInputUid] = useState('')
    const [blockedName, setBlockedName] = useState('website.com')
    const [newWhitelist, setNewWhitelist] = useState('')

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    const [blockedDomains, setBlockedDomains] = useState([])
    const [deleteDomainId, setDeleteDomainId] = useState('')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
        setBlockList([])
        setInputUid('')
    }

    const getBlockedListOfUser = (UID) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        }

        fetch(`https://dev.blockerx.net/api/user/whitelist/${UID}`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 'success') {
                    setApiCalled(true)
                    const array = data.data.whitelist
                    setBlockedDomains(array)
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    const addNewWhitelist = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: inputUid, site: newWhitelist, platform: 'ios' })
        }

        fetch('https://dev.blockerx.net/api/user/whitelist', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 'success') {
                    setOpenSuccessAlert(true)
                    getBlockedListOfUser(inputUid)
                    setNewWhitelist('')
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
    }

    const deleteDomainHandler = (UID, SITE) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: UID, site: SITE })
        }

        fetch('https://dev.blockerx.net/api/user/whitelist', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 'success') {
                    setOpenSuccessAlert(true)
                    getBlockedListOfUser(inputUid)
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    const location = useLocation()
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const uid = queryParams.get('uid')
        if (uid) {
            setInputUid(uid)
            getBlockedListOfUser(uid)
        }
    }, [location.search])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <h1 className='header-top mt-5'> Blockerx Pro Whitelist</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Enter User ID'
                        id='combo-box-demo'
                        value={inputUid}
                        sx={{ width: '35%' }}
                        onChange={(event, item) => {
                            setInputUid(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Button className='h-12' variant='contained' sx={{ width: '35%' }} color='info' onClick={() => getBlockedListOfUser(inputUid)}>
                        Get Whitelist
                    </Button>
                </Grid>

                {apiCalled === true ? (
                    <>
                        <Table sx={{ width: '90%', marginTop: 10, marginBottom: 10 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>S.No</StyledTableCell>
                                    <StyledTableCell>Name</StyledTableCell>
                                    <StyledTableCell align='left'>Type</StyledTableCell>
                                    <StyledTableCell align='left'>Added on</StyledTableCell>
                                    <StyledTableCell align='left'>Delete</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {blockedDomains.map((eachDomain, index) => {
                                return (
                                    <TableBody>
                                        <StyledTableRow key={eachDomain._id} sx={{ border: '1px solid gray' }}>
                                            <StyledTableCell align='left'>{index + 1}</StyledTableCell>
                                            <StyledTableCell align='left'>{eachDomain.site}</StyledTableCell>
                                            <StyledTableCell align='left'>Whitelist</StyledTableCell>
                                            <StyledTableCell align='left'>{convertMillisecondsToDate(eachDomain.time)}</StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <IconButton aria-label='delete' onClick={() => deleteDomainHandler(inputUid, eachDomain.site)}>
                                                    <DeleteIcon style={{ fontSize: 30, color: '#d11a2a' }} />
                                                </IconButton>
                                            </StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )
                            })}
                        </Table>
                        <Grid align='center' justify='center' spacing={2} marginBottom={30}>
                            <Grid item lg={4} md={6} sm={12} className=''>
                                <h1 className='header-top mt-5'> Add new site to whitelist</h1>
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Input
                                    disablePortal
                                    placeholder='Add New Whitelist'
                                    id='combo-box-demo'
                                    value={newWhitelist}
                                    sx={{ width: '35%' }}
                                    onChange={(event, item) => {
                                        setNewWhitelist(event.target.value)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Button className='h-12' variant='contained' sx={{ width: '35%' }} color='success' onClick={() => addNewWhitelist()}>
                                    Add Whitelist
                                </Button>
                            </Grid>
                        </Grid>
                    </>
                ) : (
                    ''
                )}
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default BlockerxProWhitelist
