import secureLocalStorage from 'react-secure-storage'
import React, { useState, useEffect } from 'react';
import { Button, Card, CardMedia, CircularProgress, Grid, Input, Popover, Snackbar, Stack, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import PolicePic from '../../assets/images/Not-Authorized.gif';
import InfoIcon from '@mui/icons-material/Info';
import ReactGA from "react-ga";
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Forbidden = () => {

    const [anchorEl, setAnchorEl] = useState(null);

    const [pageLoaded, setPageLoaded] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true);
        }, 700);
    }, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    let navigate = useNavigate();

    const [isAuth, setIsAuth] = useState(secureLocalStorage.getItem("isAuth"));

    const [inputEmail, setInputEmail] = useState("");
    const [inputPassword, setInputPassword] = useState("");
    const [email, setEmail] = useState(secureLocalStorage.getItem('email'))
    const [fullname, setFullname] = useState(secureLocalStorage.getItem('fullname'))
    const [createdAt, setCreatedAt] = useState(
        secureLocalStorage.getItem('createdAt')
    )
    const [lastActive, setLastActive] = useState(
        secureLocalStorage.getItem('lastActive')
    )

    const [error, setError] = useState("Error")

    const clearAllFields = () => {
        setInputEmail("")
        setInputPassword("")
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const signInCustom = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "email": inputEmail, "password": inputPassword })
        };

        fetch('https://dev.blockerx.net/api/dashboard/signIn', requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === 200) {
                    secureLocalStorage.setItem('uid', data.data._id)
                    secureLocalStorage.setItem('email', inputEmail)
                    secureLocalStorage.setItem('token', data.data.token)
                    secureLocalStorage.setItem('fullname', data.data.fullname)
                    secureLocalStorage.setItem('createdAt', data.data.createdOn)
                    secureLocalStorage.setItem('lastActive', data.data.lastActive)
                    setFullname(data.data.fullname)
                    setCreatedAt(data.data.createdAt)
                    setLastActive(data.data.lastActive)
                    secureLocalStorage.setItem('isAuth', true)
                    setIsAuth('true')
                    navigate(-1)
                } else if (data.status === 400) {
                    setError(data.data.info)
                    setOpenErrorAlert(true)
                    clearAllFields()
                    secureLocalStorage.setItem("isAuth", false);
                } else {
                    setOpenErrorAlert(true)
                    clearAllFields()
                    secureLocalStorage.setItem("isAuth", false);
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                secureLocalStorage.setItem("isAuth", false);
            });;
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        pageLoaded === true ?
            <Box sx={{ flexGrow: 2 }}>
                <Grid align="center" justify="center" spacing={2}>

                    <Card sx={{ maxWidth: 340, marginTop: "20px" }}>

                        <Grid item lg={4} md={6} sm={12} className="">
                            <p className="text-2xl mt-4">
                                Forbidden
                                <InfoIcon className="ml-2 mb-1 cursor-pointer" onClick={handleClick} />
                            </p>
                        </Grid>
                        <CardMedia
                            component="img"
                            image={PolicePic}
                            alt="User Info"
                        />
                    </Card>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <h1 className="header-top mt-5">Sign In</h1>
                        {/* <p className="text-2xl mt-5">Access all features of this website</p> */}
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            disablePortal
                            placeholder="Enter your email"
                            id="combo-box-demo"
                            sx={{ width: "60%" }}
                            onChange={(event, item) => {
                                setInputEmail(event.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            type='password'
                            disablePortal
                            placeholder="Enter your password"
                            id="combo-box-demo"
                            sx={{ width: "60%" }}
                            onChange={(event, item) => {
                                setInputPassword(event.target.value);
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Button
                            className="h-12"
                            variant="contained"
                            sx={{ width: "60%", backgroundColor: "#3C4048" }}
                            onClick={signInCustom}>
                            Sign In
                        </Button>
                    </Grid>
                </Grid>

                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                >
                    <Typography sx={{ p: 2 }}>
                        You are not authorized to use this dashboard. You method of accessing dashboard is illegal.
                    </Typography>
                </Popover>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            Success!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            {error}
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
            : <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '300px' }}>
                <CircularProgress size="7rem" color="grey" />
            </Box>
    )
}

export default Forbidden;