import * as React from 'react';
import secureLocalStorage from 'react-secure-storage';
import { useEffect, useState } from 'react';
import { Button, Grid, Input, Switch } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const APKRestriction = () => {
    const [inputUid, setInputUid] = useState('');
    const [userExists, setUserExists] = useState(false);
    const [userBlocked, setUserBlocked] = useState(false);

    const token = secureLocalStorage.getItem('token');

    const checkUserExists = async (UID) => {
        if (UID === '') {
            toast.error('User ID is required to know the APK Restriction status',  {
                position: 'bottom-left'
            });
            return
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        };

        try {
            const response = await fetch(`https://events.blockerx.net/api/apk/user/update?uid=${UID}`, requestOptions);
            const data = await response.json();
            console.log('Data : ', data)
            if (data.status === 200) {
                setUserExists(true);
                setUserBlocked(true);
            } else {
                setUserExists(true);
                setUserBlocked(false);
            }
        } catch (error) {
            console.log('Error : ', error);
            setUserExists(false);
            setUserBlocked(false);
            toast.error('An error occurred while checking user existence.',  {
                position: 'bottom-left'
            });
        }
    };

    const toggleUserStatus = async () => {
        const requestOptions = {
            method: 'PUT',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: inputUid, status: !userBlocked })
        };

        try {
            const response = await fetch('https://events.blockerx.net/api/apk/user/toggle', requestOptions);
            const data = await response.json();
            if (data.status === 200) {
                setUserBlocked(!userBlocked);
                setUserExists(true);
                toast.success('User status updated successfully!', {
                    position: 'bottom-left'
                });
            } else {
                toast.error('An error occurred while updating user status.', {
                    position: 'bottom-left'
                });
            }
        } catch (error) {
            console.log('Error : ', error);
            toast.error('An error occurred while updating user status.', {
                position: 'bottom-left'
            });
        }
    };

    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        if (uid) {
            setInputUid(uid);
            checkUserExists(uid);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <Grid container spacing={2} sx={{ textAlign: 'left', maxWidth: '600px' }}>
                <Grid item xs={12}>
                    <h1 className='header-top'>APK Restriction</h1>
                </Grid>

                <Grid item xs={12}>
                    <Input
                        disablePortal
                        placeholder='Enter UID'
                        id='combo-box-demo'
                        value={inputUid}
                        sx={{ width: '100%' }}
                        onChange={(event) => {
                            setInputUid(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant='contained' sx={{ width: '100%' }} color='info' onClick={() => checkUserExists(inputUid)}>
                        Check Status
                    </Button>
                </Grid>

                {userExists && (
                    <Grid item xs={12} sx={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <h2>{userBlocked ? 'APK Updates stopped' : 'Receives APK updates'}</h2>
                        <Switch checked={userBlocked} onChange={toggleUserStatus} />
                    </Grid>
                )}
            </Grid>

            <ToastContainer />
        </Box>
    );
};

export default APKRestriction;
