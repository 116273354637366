import {
    Card,
    CardContent,
    CardMedia,
    CircularProgress,
    Grid,
    Rating,
    Typography
} from '@mui/material'
import { Box } from '@mui/system'
import { useEffect } from 'react'
import { useState } from 'react'

const DashboardStats = () => {
    const [pageLoaded, setPageLoaded] = useState(false)
    const [stats, setStats] = useState({})
    const [apiCalled, setApiCalled] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setPageLoaded(true)
        }, 300)

        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://dev.blockerx.net/api/dashboard/stats', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 'success') {
                    setStats(data.data)
                    setApiCalled(true)
                } else {
                }
            })
    }, [])

    return apiCalled === true ? (
        <Box
            sx={{ flexGrow: 1 }}
            className='px-5 pb-10 dashboard-page'>
            <Grid
                item
                lg={4}
                md={6}
                sm={12}
                className='pt-5'>
                <h1 className='header-top mt-5'>Dashboard Stats</h1>
            </Grid>
            <Grid
                container
                spacing={2}>
                <Grid
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    align='center'
                    marginTop={5}>
                    <Card
                        sx={{ maxWidth: 500 }}
                        className='gridBox'>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='div'>
                                Views
                            </Typography>
                            <Typography
                                gutterBottom
                                variant='h3'
                                component='div'>
                                {Number(
                                    parseFloat(stats.viewsTotal).toFixed(2)
                                ).toLocaleString('en', {})}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    align='center'
                    marginTop={5}>
                    <Card
                        sx={{ maxWidth: 500 }}
                        className='gridBox'>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='div'>
                                Time Saved
                            </Typography>
                            <Typography
                                gutterBottom
                                variant='h3'
                                component='div'>
                                {(stats.ticketsResolvedTotal * 3) / 60} hours
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid
                    item
                    lg={4}
                    md={6}
                    sm={12}
                    align='center'
                    marginTop={5}>
                    <Card
                        sx={{ maxWidth: 500 }}
                        className='gridBox'>
                        <CardContent>
                            <Typography
                                gutterBottom
                                variant='h5'
                                component='div'>
                                Activity Count
                            </Typography>
                            <Typography
                                gutterBottom
                                variant='h3'
                                component='div'>
                                {stats.activityCount}
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* TODO:: Activity in last 24 hrs, 7 days, 30 days */}
                {/* <Grid item lg={4} md={6} sm={12} align="center" marginTop={5}>
                        <Card sx={{ maxWidth: 500 }} className="gridBox">
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Resolved in last 30 days
                                </Typography>
                                <Typography gutterBottom variant="h3" component="div">
                                    {stats.ticketsResolvedLastMonth}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} align="center" marginTop={5}>
                        <Card sx={{ maxWidth: 500 }} className="gridBox">
                            <CardContent>
                                <Typography gutterBottom variant="h5" component="div">
                                    Resolved in last 24 hours
                                </Typography>
                                <Typography gutterBottom variant="h3" component="div">
                                    {stats.ticketsResolvedLastDay}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid> */}
            </Grid>
        </Box>
    ) : (
        <Box
            sx={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: '300px'
            }}>
            <CircularProgress
                size='7rem'
                color='grey'
            />
        </Box>
    )
}

export default DashboardStats
