import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { BASE_URL, ZENZE_BASE_URL } from '../../../api/Constants';
import {
    Box,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography
} from '@mui/material';
import { showTimeInAgoFormat } from '../../../Utils/time';

function ZenzeRecentRooms() {
    const [users, setUsers] = useState([]);

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get(`${ZENZE_BASE_URL}/api/stats/v1/recent-rooms`);
            setUsers(response.data.data.rooms);
        } catch (error) {
            console.error('Error fetching users:', error);
        }
    };

    return (
        <Box sx={{ p: 8 }}>
            <Typography variant="h4" gutterBottom>
                Showing recent {users.length > 0 ? users.length : ''} zenze rooms
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Room ID</TableCell>
                            <TableCell>Room Creator</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Users</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map(user => (
                            <TableRow key={user.uid}>
                                <TableCell>{user.roomId}</TableCell>
                                <TableCell>{user.username || ''}</TableCell>
                                <TableCell>{user.email || 'anonymous'}</TableCell>
                                <TableCell>{user.userCount || 1}</TableCell>
                                <TableCell>{showTimeInAgoFormat(user.createdAt)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
}

export default ZenzeRecentRooms;