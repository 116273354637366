import React, { useEffect, useState } from 'react'
import './DeveloperProjects.css'
import Button from '@mui/material/Button'
import Slider from '@mui/material/Slider'
import Typography from '@mui/material/Typography'
import Backdrop from '@mui/material/Backdrop'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import Fade from '@mui/material/Fade'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { showTimeInAgoFormat } from '../../Utils/time'
import { Autocomplete, Fab, TextField } from '@mui/material'
import secureLocalStorage from 'react-secure-storage'

const adminUsers = ['63540427d5dc60c14bbacf68', '635c96b8a6b1f91711777e6f', '639c90e80aebe7e018ce2105']

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    bgcolor: 'background.paper',
    border: '1px solid #979797',
    boxShadow: 24,
    px: 8,
    py: 4
}

const NewProjectModal = ({ open, handleClose, developerList }) => {
    const [newProject, setNewProject] = useState({
        name: '',
        description: '',
        difficulty: 1,
        deadline_days: 1,
        assigned_to: 'All'
    })
    const [currentDeveloper, setCurrentDeveloper] = useState('All')

    const hanldeCurrentDeveloper = (developer) => {
        setCurrentDeveloper(developer)
        let newProj = newProject
        newProj.assigned_to = developer
        setNewProject(newProj)
    }

    const handleInputChange = (event, fieldName) => {
        const value = event.target.value
        setNewProject((prevProject) => ({
            ...prevProject,
            [fieldName]: value
        }))
    }

    const handleChange = (event) => {
        console.log('Event : ', event)
        if (event.name === 'difficulty') {
            let prevProjectData = newProject
            prevProjectData.difficulty = event.target.value
            setNewProject(prevProjectData)
        }
    }

    const addNewProjectHandler = () => {
        const { deadline_days, ...others } = newProject
        others.end_date = new Date().getTime() + deadline_days * 86400000
        fetch(`https://events.blockerx.net/api/developers/projects`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(others)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    console.log('Project data updated successfully:', data.data.info)
                    handleClose()
                } else {
                    console.error('Failed to update project data:', data.message)
                }
            })
            .catch((error) => {
                console.error('Error updating project data:', error)
            })
    }

    return (
        <Modal
            aria-labelledby='transition-modal-title'
            aria-describedby='transition-modal-description'
            open={open}
            onClose={handleClose}
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: {
                    timeout: 500
                }
            }}>
            <Fade in={open}>
                <Box sx={style}>
                    <div className='text-center text-4xl font-bold text-gray-600 mb-8'>Add New Project</div>
                    <div className='mt-4'>
                        <TextField sx={{ width: '100%' }} label='Project Name' defaultValue={newProject && newProject.name} onChange={(e) => handleInputChange(e, 'name')} />
                    </div>
                    <div className='mt-4'>
                        <Autocomplete
                            disablePortalnpm
                            s
                            id='combo-box-demo'
                            value={currentDeveloper}
                            options={developerList}
                            sx={{ width: '100%', marginTop: '2%' }}
                            onChange={(event, item) => {
                                hanldeCurrentDeveloper(item.label)
                            }}
                            renderInput={(params) => <TextField {...params} label='Filter Developer' />}
                        />
                    </div>

                    <div className='my-4'>
                        <TextField sx={{ width: '100%' }} label='Description' defaultValue={newProject && newProject.description} onChange={(e) => handleInputChange(e, 'description')} />
                    </div>

                    <div className='my-4'>
                        <TextField sx={{ width: '100%' }} label='Deadline days' defaultValue={newProject && newProject.deadline_days} onChange={(e) => handleInputChange(e, 'deadline_days')} />
                    </div>

                    <div className='mt-5'>
                        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                            Difficulty
                        </Typography>
                        <Slider aria-label='Temperature' defaultValue={newProject.difficulty} onChange={handleChange} name='difficulty' valueLabelDisplay='auto' shiftStep={30} step={1} marks min={1} max={5} />
                    </div>
                    <div className='mt-5'>
                        <Button
                            sx={{
                                bgcolor: '#197aa0',
                                width: '10%',
                                color: 'white',
                                '&:hover': {
                                    bgcolor: '#125772'
                                }
                            }}
                            size='small'
                            onClick={addNewProjectHandler}>
                            Add
                        </Button>
                    </div>
                </Box>
            </Fade>
        </Modal>
    )
}

function DeveloperProjects() {
    const [projectsList, setProjectsList] = useState([])
    const [currentProjectsList, setCurrentProjectsList] = useState([])
    const [apiCalled, setApiCalled] = useState(false)
    const [open, setOpen] = React.useState(false)
    const [developerList, setDeveloperList] = useState([
        {
            label: 'All'
        }
    ])
    const dashboardUid = secureLocalStorage.getItem('uid')
    const [currentDeveloper, setCurrentDeveloper] = useState('All')
    const [selectedProject, setSelectedProject] = useState({
        name: 'Default Project',
        assigned_to: 'Kiranpal Singh',
        created_at: new Date().getTime(),
        start_date: new Date().getTime(),
        end_date: new Date().getTime(),
        difficulty: 1,
        satisfaction: 1,
        work_quality: 1,
        follow_deadline: 1,
        bug_free: 1,
        completed: false,
        tested: false,
        reviewed: false,
        client_informed: false,
        score: 0
    })
    const [newProjectModalOpen, setNewProjectModalOpen] = useState(false)
    const handleNewProjectModalOpen = () => {
        setNewProjectModalOpen(true)
    }

    const handleNewProjectModalClose = () => {
        setNewProjectModalOpen(false)
    }

    const handleOpen = (project) => {
        setSelectedProject(project)
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
        // getProjectsList()
    }

    const handleCloseWithRefresh = () => {
        setOpen(false)
        getProjectsList()
    }

    const updateLocalList = () => {
        const updatedProjectList = []
        projectsList.forEach((proj) => {
            if (proj.id === selectedProject.id) {
                updatedProjectList.push(selectedProject)
            } else {
                updateLocalList.push(proj)
            }
        })
        setCurrentProjectsList(updatedProjectList)
    }

    const handleCloseNormally = () => {
        setOpen(false)
    }

    const [state, setState] = React.useState({
        completed: false,
        tested: false,
        reviewed: false,
        client_informed: false
    })

    const switchesList = ['completed', 'tested', 'reviewed', 'client_informed']

    const handleChange = (event) => {
        const { name, checked, value } = event.target
        setState((prevState) => ({
            ...prevState,
            [name]: checked
        }))
        if (selectedProject && !switchesList.includes(name)) {
            setSelectedProject((prevProject) => ({
                ...prevProject,
                [name]: value
            }))
        }

        if (selectedProject && switchesList.includes(name)) {
            setSelectedProject((prevProject) => ({
                ...prevProject,
                [name]: checked
            }))
        }
    }

    const handleInputChange = (event, fieldName) => {
        const value = event.target.value
        setSelectedProject((prevProject) => ({
            ...prevProject,
            [fieldName]: value
        }))
    }

    const getPeersList = () => {
        try {
            const data = secureLocalStorage.getItem('dashboard_peers')
            const developers_list = [{ label: 'All' }]
            data.forEach((each_data) => {
                developers_list.push({ label: each_data.fullname })
            })
            setDeveloperList(developers_list)
        } catch (err) {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            }
            fetch('https://dev.blockerx.net/api/dashboard/peers', requestOptions)
                .then((response) => response.json())
                .then((data) => {
                    console.log(data)
                    if (data.status === 200) {
                        secureLocalStorage.setItem('dashboard_peers', data.data.users)
                        const developers_list = [{ label: 'All' }]
                        data.data.users.forEach((each_data) => {
                            developers_list.push({ label: each_data.fullname })
                        })
                        setDeveloperList(developers_list)
                    } else {
                    }
                })
                .catch()
        }
    }

    const getProjectsList = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }
        fetch('https://events.blockerx.net/api/developers/projects', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Projects fetched : ', data)
                if (data.status === 200) {
                    setProjectsList(data.data.projects)
                    setCurrentProjectsList(data.data.projects)
                    setApiCalled(true)
                    getPeersList()
                } else {
                }
            })
            .catch()
    }

    const saveSelectedProjectData = () => {
        if (!selectedProject) {
            return
        }

        const { score, ...updatedData } = selectedProject

        fetch(`https://events.blockerx.net/api/developers/projects`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(updatedData)
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    handleCloseWithRefresh()
                } else {
                    console.error('Failed to update project data:', data.message)
                }
            })
            .catch((error) => {
                console.error('Error updating project data:', error)
            })
    }

    const isPastDeadline = (endDate) => {
        const currentTime = new Date().getTime()
        return Number(endDate) < currentTime
    }

    const showCurrentDeveloperList = (developer) => {
        if (developer === 'All') {
            setCurrentProjectsList(projectsList)
            return
        }
        let newProjects = []
        projectsList.forEach((eachProject) => {
            if (eachProject.assigned_to === developer) {
                newProjects.push(eachProject)
            }
        })
        setCurrentProjectsList(newProjects)
    }

    const handleClick = (developerName) => {
        if (!restrictedNames.includes(developerName)) {
            setCurrentDeveloper(developerName)
        }
        if (developerName === 'All') {
            setCurrentProjectsList(projectsList)
            return
        }
        let newProjects = []
        projectsList.forEach((eachProject) => {
            if (eachProject.assigned_to === developerName) {
                newProjects.push(eachProject)
            }
        })
        setCurrentProjectsList(newProjects)
    }

    useEffect(() => {
        if (!apiCalled) {
            getProjectsList()
        }
    })

    const restrictedNames = ['Nitya Kumari', 'Deepak Kumar', 'Tejas Bala']

    return (
        <>
            <div className='h-[calc(100vh_-_7vh)] lg:flex'>
                <div className='h-[calc(100vh_-_7vh)] lg:w-1/6 bg-my-gray-1 text-white overflow-y-auto'>
                    <div>
                        <ul>
                            {developerList.map(
                                (developer, index) =>
                                    !restrictedNames.includes(developer.label) && (
                                        <li key={index} className={`border-t border-gray-600 p-2 cursor-pointer ${currentDeveloper === developer.label ? 'font-bold' : ''}`} onClick={() => handleClick(developer.label)}>
                                            {developer.label}
                                        </li>
                                    )
                            )}
                        </ul>
                    </div>
                </div>

                <div className='w-5/6 h-[calc(100vh_-_7vh)] overflow-y-auto'>
                    <div className='developerprojects__first'>
                        {currentProjectsList.length > 0 &&
                            currentProjectsList.map((each_project) => {
                                return (
                                    <>
                                        <div className={`card-container bg-gray-700 cursor-pointer`} key={each_project.id} onClick={() => handleOpen(each_project)}>
                                            <main className='main-content'>
                                                <h1 className='text-left text-xl'>{each_project.name}</h1>
                                                <div className='flex-row'>
                                                    <div className='coin-base'>
                                                        <span className={`font-bold ${isPastDeadline(Number(each_project.end_date)) ? 'text-red-300' : 'text-yellow-200'}`}> {showTimeInAgoFormat(Number(each_project.end_date))}</span>
                                                    </div>
                                                    <div className='time-left'>
                                                        <p className='mr-2 font-bold'></p>
                                                    </div>
                                                </div>
                                            </main>
                                            <div className='card-attribute'>
                                                <p>
                                                    <span className='font-bold text-blue-300'>{each_project.assigned_to}</span>
                                                </p>
                                                <span className='mx-2'>•</span>
                                                <p>
                                                    Updated <span className='font-bold'>{showTimeInAgoFormat(Number(each_project.updated_at))}</span>
                                                </p>
                                            </div>
                                        </div>
                                    </>
                                )
                            })}
                    </div>
                    <div className='text-center mt-10'>
                        {currentProjectsList.length === 0 && (
                            <div className='text-center text-4xl font-bold text-gray-600 mb-8'>
                                No projects for <span className='text-blue-400'>{currentDeveloper}</span>
                            </div>
                        )}
                    </div>
                    <Fab
                        color='primary'
                        sx={{
                            position: 'fixed',
                            bottom: '1.5rem',
                            right: '1.5rem',
                            '&:hover': {
                                backgroundColor: '#1976D2'
                            }
                        }}
                        onClick={handleNewProjectModalOpen}>
                        <p className='text-4xl'>+</p>
                    </Fab>
                </div>
            </div>
            <Modal
                aria-labelledby='transition-modal-title'
                aria-describedby='transition-modal-description'
                open={open}
                onClose={handleClose}
                closeAfterTransition
                slots={{ backdrop: Backdrop }}
                slotProps={{
                    backdrop: {
                        timeout: 500
                    }
                }}>
                <Fade in={open}>
                    <Box sx={{ ...style, maxHeight: '80vh', overflowY: 'auto' }}>
                        <div className='h-100'>
                            <div className='text-center text-4xl font-bold text-gray-600 mb-8'>Update Project</div>
                            <div className='mt-4'>
                                <TextField disabled={!adminUsers.includes(dashboardUid)} sx={{ width: '100%' }} label='Project Name' defaultValue={selectedProject && selectedProject.name} onChange={(e) => handleInputChange(e, 'name')} />
                            </div>
                            <div className='mt-4'>
                                <TextField disabled={!adminUsers.includes(dashboardUid)} sx={{ width: '100%' }} label='Assigned To' defaultValue={selectedProject && selectedProject.assigned_to} onChange={(e) => handleInputChange(e, 'assigned_to')} />
                            </div>

                            <div className='my-4'>
                                <TextField disabled={!adminUsers.includes(dashboardUid)} sx={{ width: '100%' }} label='Description' defaultValue={selectedProject && selectedProject.description} onChange={(e) => handleInputChange(e, 'description')} />
                            </div>

                            {/* Add an input field for the 'description' property */}
                            <FormControl component='fieldset' variant='standard'>
                                <FormGroup>
                                    <div>
                                        <FormControlLabel control={<Switch checked={selectedProject.completed} onChange={handleChange} name='completed' />} label='Completed' />
                                        <FormControlLabel control={<Switch checked={selectedProject.tested} onChange={handleChange} name='tested' />} label='Tested' />
                                        <FormControlLabel control={<Switch checked={selectedProject.reviewed} onChange={handleChange} name='reviewed' />} label='Reviewed' />
                                        <FormControlLabel control={<Switch checked={selectedProject.client_informed} onChange={handleChange} name='client_informed' />} label='Informed Client' />
                                    </div>
                                </FormGroup>
                            </FormControl>

                            {adminUsers.includes(dashboardUid) && (
                                <>
                                    <div className='mt-5'>
                                        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                                            Difficulty
                                        </Typography>
                                        <Slider aria-label='Temperature' defaultValue={selectedProject.difficulty} onChange={handleChange} name='difficulty' valueLabelDisplay='auto' shiftStep={30} step={1} marks min={1} max={5} />
                                    </div>
                                    <div className='mt-5'>
                                        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                                            Satisfied
                                        </Typography>
                                        <Slider aria-label='Temperature' defaultValue={selectedProject.satisfaction} onChange={handleChange} name='satisfaction' valueLabelDisplay='auto' shiftStep={30} step={1} marks min={0} max={15} />
                                    </div>
                                    <div className='mt-5'>
                                        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                                            Work Quality
                                        </Typography>
                                        <Slider aria-label='Temperature' defaultValue={selectedProject.work_quality} onChange={handleChange} name='work_quality' valueLabelDisplay='auto' shiftStep={30} step={1} marks min={0} max={15} />
                                    </div>
                                    <div className='mt-5'>
                                        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                                            Followed Deadline
                                        </Typography>
                                        <Slider aria-label='Temperature' defaultValue={selectedProject.follow_deadline} onChange={handleChange} name='follow_deadline' valueLabelDisplay='auto' shiftStep={30} step={1} marks min={0} max={10} />
                                    </div>
                                    <div className='mt-5'>
                                        <Typography id='transition-modal-description' sx={{ mt: 2 }}>
                                            Bugs free
                                        </Typography>
                                        <Slider aria-label='Temperature' defaultValue={selectedProject.bug_free} onChange={handleChange} name='bug_free' valueLabelDisplay='auto' shiftStep={30} step={1} marks min={0} max={10} />
                                    </div>
                                </>
                            )}

                            <div className='mt-5'>
                                <Button
                                    sx={{
                                        bgcolor: '#4aa546',
                                        width: '10%',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: '#417c3f'
                                        }
                                    }}
                                    size='small'
                                    onClick={() => saveSelectedProjectData()}>
                                    Save
                                </Button>
                                <Button
                                    sx={{
                                        marginLeft: '5px',
                                        width: '10%',
                                        bgcolor: '#db5314',
                                        color: 'white',
                                        '&:hover': {
                                            bgcolor: '#af4413'
                                        }
                                    }}
                                    size='small'
                                    onClick={handleCloseNormally}>
                                    Close
                                </Button>
                            </div>
                        </div>
                    </Box>
                </Fade>
            </Modal>
            {adminUsers.includes(dashboardUid) && <NewProjectModal open={newProjectModalOpen} handleClose={handleNewProjectModalClose} developerList={developerList} />}
        </>
    )
}

export default DeveloperProjects
