import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Alert, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { BASE_URL } from '../../api/Constants';
import { useNavigate } from 'react-router-dom';
import { showTimeInAgoFormat } from '../../Utils/time';

const ZiaUsers = () => {
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [search, setSearch] = useState('');
    const [clickCount, setClickCount] = useState(0); // State to count clicks
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/api/ai-friend/v1/users`);
                setUsers(response.data.data.list);
                setFilteredUsers(response.data.data.list);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUsers();
    }, []);

    useEffect(() => {
        const filtered = users.filter((user) =>
            Object.values(user).some((value) =>
                value.toString().toLowerCase().includes(search.toLowerCase())
            )
        );
        setFilteredUsers(filtered);
    }, [search, users]);

    const handleViewClick = (user) => {
        // Increment click count
        const newClickCount = clickCount + 1;
        setClickCount(newClickCount);

        // Navigate only if the click count is exactly 5
        if (newClickCount === 5) {
            navigate(`/zia-conversation?uid=${user.uid}`);
        }
    };

    return (
        <Container>
            <Typography variant="h4" component="h1" gutterBottom sx={{ my: 4, fontWeight: 'bolder', color: '#111111' }}>
                Zia Users
            </Typography>
            <TextField
                label="Search"
                variant="outlined"
                fullWidth
                margin="normal"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
            />
            {loading && <CircularProgress />}
            {error && <Alert severity="error">{error}</Alert>}
            {!loading && !error && (
                <TableContainer component={Paper} sx={{ mt: 3 }}>
                    <Table>
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                            <TableRow>
                                <TableCell>Email</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell>Gender</TableCell>
                                <TableCell>Age</TableCell>
                                <TableCell>Purpose</TableCell>
                                <TableCell>Bot Gender</TableCell>
                                <TableCell>Chats</TableCell>
                                <TableCell>Last Chat</TableCell>
                                <TableCell>View Chat</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredUsers.map((user) => (
                                <TableRow key={user._id}>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{user.username}</TableCell>
                                    <TableCell>{user.gender}</TableCell>
                                    <TableCell>{user.age}</TableCell>
                                    <TableCell>{user.purpose}</TableCell>
                                    <TableCell>{user.botGender}</TableCell>
                                    <TableCell>{user.conversations}</TableCell>
                                    <TableCell>{showTimeInAgoFormat(user.lastMessageTime)}</TableCell>
                                    <TableCell className='underline cursor-pointer' onClick={() => handleViewClick(user)}>
                                        View
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            )}
        </Container>
    );
};

export default ZiaUsers;
