exports.propertyList = [
    {
        label: 'click'
    },
    {
        label: 'open'
    }
]

exports.timeFilterAutocomplete = [
    {
        label: 'Last 12 hours',
        days: 0.5
    },
    {
        label: 'Last 24 hours',
        days: 1
    },
    {
        label: 'Last 3 days',
        days: 3
    },
    {
        label: 'Last 7 days',
        days: 7
    },
    {
        label: 'Last 14 days',
        days: 14
    }
]

exports.currentCampaignTypeAutocomplete = [
    {
        label: 'Email',
        id: 'email'
    },
    {
        label: 'Notification',
        id: 'notification'
    }
]

exports.propertyValueList = [
    {
        label: 'AccessibilityPermissionActivity_IntroAccessibiltyScreenSkip'
    },
    { label: 'MainActivity_last_screen_visited_SwitchPageFragment' },
    { label: 'SupportPageFreeUser_chat_support' },
    { label: 'SwitchPageFragment_support_from_switch_navigation_drawer' },
    { label: 'BlockerAppsDialog' },
    { label: 'NofapLifetimePurchesDialog' },
    { label: 'OneToOneChatHistoryFragment_Name' },
    { label: 'MainActivity' },
    { label: 'UserProfileFragment_tab_selected_1' },
    { label: 'CallMessageLaunchFragment' },
    { label: 'SplashScreenFragment' },
    { label: 'OneToOneChatFragment' },
    { label: 'FeedDisplayFragment_Flotingimage' },
    { label: 'MainActivity_click_HOME' },
    { label: 'NewPurchasePremiumFragment' },
    { label: 'MainActivity_click_BLOCK_ITEM' },
    { label: 'SwitchPageFragment' },
    { label: 'MainActivity_click_BLOCKING' },
    { label: 'SupportPageFreeUser_Support' },
    { label: 'LoadAllWebViewFragment_support_back' },
    { label: 'FeedPostTypeSelectionFragment_text' },
    { label: 'FeedPostingFragment' },
    { label: 'MainActivity_click_COMMUNITY' },
    { label: 'MainActivity_hamburger_open' },
    {
        label: 'NotificationAction_notification_daily_feed_motivation_action'
    },
    { label: 'FeedPostingFragment_Submit' },
    { label: 'UserAchievementFragment' },
    { label: 'NotificationAction_show_request_to_purchase_premium' },
    { label: 'SwitchAutoOnInfoDialog_SwitchAutoOnInfoDialog' },
    { label: 'SwitchPageFragment_free_user_try_block_yt_shorts_on' },
    { label: 'NewKeywordWebsiteAppListMainFragment' },
    {
        label: 'SwitchPageFragment_info_button_switch_prevent_uninstall_card'
    },
    { label: 'FeedPostTypeSelectionFragment_image' },
    { label: 'SwitchPageInstantAPApprovalDialog_LongSentenceDialog' },
    { label: 'OneToOneChatHistoryFragment' },
    { label: 'SwitchAutoOnInfoDialog_turn_off_for_3_hours' },
    { label: 'MainActivity_last_screen_visited_FeedImagePostFragment' },
    { label: 'MainActivity_last_screen_visited_FeedDisplayActivity' },
    { label: 'OnlineUsersFragment' },
    { label: 'GuideActivity' },
    { label: 'FeedDisplayFragment_FeedLike' },
    { label: 'SwitchPageFragment_search_result_filter_false' },
    { label: 'FeedDetailsFragment' },
    { label: 'ClickRebootButton' },
    { label: 'FeedDisplayFragment_SwipeRefresh' },
    { label: 'SwitchPageFragment_porn_blocker_switch_strict' },
    { label: 'NewKeywordWebsiteAppListMainFragment_apps_dialog_shown' },
    { label: 'FeedPostingFragment_Back' },
    { label: 'UserProfileFragment_tab_selected_2' },
    { label: 'MainActivity_support_clicked' },
    { label: 'FeedImagePostFragment_select_image' },
    { label: 'BlockerXLandingPageFragment_selected_swipe_card_0' },
    { label: 'PostTagsViewModel_imagePost' },
    { label: 'BlockerXLandingPageFragment' },
    { label: 'FeedDetailsFragment_Image' },
    { label: 'PremiumFlotingActivity' },
    { label: 'SwitchPageFragment_social_media_blocking_info_close}' },
    { label: 'SwitchPageFragment_long_sentence_dialog_cancel' },
    {
        label: 'MainActivity_last_screen_visited_NofapLifetimePurchesDialog'
    },
    { label: 'SelfUpvotedCommnetedPostsFragment' },
    { label: 'FeedDisplayFragment' },
    { label: 'MainActivity_click_PREMIUM' },
    { label: 'SignInSignUpWhileAppStartActivity' },
    { label: 'FeedDisplayFragment_Flotingtext' },
    { label: 'SwitchPageFragment_long_sentence_dialog_continue_fail' },
    { label: 'FeedDisplayFragment_FeedCommentButton' },
    { label: 'FeedDisplayFragment_CallMessage' },
    { label: 'AccessibilityPermissionActivity' },
    { label: 'SwitchPageFragment_app_update_remind_me_later' },
    { label: 'FeedDetailsFragment_UserName' },
    { label: 'UserProfileFragment' },
    { label: 'MainActivity_last_screen_visited_LoadAllWebViewFragment' },
    { label: 'SwitchPageFragment_yt_short_true' },
    {
        label: 'NotificationAction_notification_blockerx_user_feed_post_liked'
    },
    { label: 'SwitchPageFragment_accountability_partner_longsentences' },
    { label: 'LoadAllWebViewFragment' },
    { label: 'FeedPostingFragment_TagItemSelected' },
    { label: 'UserProfileFragment_tab_selected_0' },
    {
        label: 'MainActivity_last_screen_visited_FeedLaunchConditionFragment'
    },
    { label: 'OneToOneChatFragment_MessageSubmit' },
    { label: 'UserProfileFragment_tab_selected_3' },
    { label: 'FeedPostingFragment_title_entered_on_back' }
]

exports.countryList = [
    {
        label: 'All Countries',
        id: 'all'
    },
    {
        label: 'USA',
        id: 'US'
    },
    {
        label: 'United Kingdom',
        id: 'UK'
    }
]

exports.eventList = [
    { label: 'ArticleVideoCourse' },
    { label: 'BlockWhiteListPage' },
    { label: 'CommunityPage' },
    { label: 'CustomWallpaper' },
    { label: 'Feed' },
    { label: 'MainPage' },
    { label: 'NotificationAction' },
    { label: 'Other' },
    { label: 'PurchasePremium' },
    { label: 'Rating' },
    { label: 'ReferEarn' },
    { label: 'SettingPage' },
    { label: 'SignInSignUp' },
    { label: 'UserProfile' },
    { label: 'cohortProgram' },
    { label: 'video_watch_id' },
    { label: 'AppSetup' },
    { label: 'BlockWindowPage' },
    { label: 'Communication' },
    { label: 'DealingWithUrges' },
    { label: 'Help' },
    { label: 'HomePage' },
    { label: 'Journal' },
    { label: 'MainActivity' },
    { label: 'NpsNumber' },
    { label: 'PornMasturbation' },
    { label: 'Streak' },
    { label: 'SwitchPage' },
    { label: 'Widget' },
    { label: 'courseFeedback' }
]

exports.ArticleVideoCourseAutocomplete = [
    { label: 'ArticleVideoContentFragment_article_item_open' },
    { label: 'ArticleVideoContentFragment_article_page_open' },
    { label: 'ArticleVideoContentFragment_subscribe_button_click' },
    { label: 'ArticleVideoContentFragment_tab_selected_0' },
    { label: 'ArticleVideoContentFragment_tab_selected_1' },
    { label: 'ArticleVideoContentFragment_tab_selected_2' },
    { label: 'ArticleVideoContentFragment_video_page_open' },
    { label: 'CourseCompleteShareDialog' },
    { label: 'CourseCompleteShareDialog_OK' },
    { label: 'CourseDetailFragment' },
    { label: 'CourseDetailFragment_certificateBtn' },
    {
        label: 'CourseDetailFragment_courseVideoId_3229ec11-3d81-4c81-a08c-5dca77d0a559'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_331e51f8-e7d3-4c28-8d69-081cb73b2cdd'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_3be77280-5f2e-4357-9d70-99b7d2162e46'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_4ba983ce-c4cb-4970-a25c-e3b59c09684f'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_6ad83a53-9a61-4287-a97a-8c29b8fae057'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_6cd45359-324e-4ea1-b32c-c88b496693bc'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_806f42ea-7539-4842-958d-b5fb1f7f6ed9'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_83811b18-5293-4bb5-b51a-d2f61d7f952d'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_917fddf8-5964-496b-843d-e2bc7ac5ae18'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_932fb2ea-17dd-4b43-9a76-394c8aeb0295'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_b5358e18-15a3-4dc1-859d-d7f4f956b85b'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_b87e6be3-bd23-4a81-9c55-7bc2360d6d5a'
    },
    {
        label: 'CourseDetailFragment_courseVideoId_f37f73e2-b3cf-4ae2-8e7e-8d13034a5286'
    },
    { label: 'CourseDetailFragment_full_screen' },
    { label: 'CourseDetailFragment_startCourseBtn' },
    {
        label: 'VideoCourseListPageFragment_courseId_609129ef0461260db3589c70a475770c-6934-48ed-9e11-e206124d59a2'
    },
    {
        label: 'VideoCourseListPageFragment_courseId_60c2fee39fb2bda9e89a558764c405c4-b411-4da6-b905-ffc292dd03a5'
    },
    {
        label: 'VideoCourseListPageFragment_courseId_60efcdc2567bde3a65a91bafe9025246-a219-4cbc-b69a-2a72fd722960'
    },
    {
        label: 'VideoCourseListPageFragment_courseId_60efd5d02c07573ae730a520594a80fe-11f2-4390-83d5-6c70ae917b38'
    },
    {
        label: 'VideoCourseListPageFragment_courseId_6130a00fe3c4c6205f65ee5e22d89ad7-fd30-448a-9400-aecc9c9d6957'
    },
    {
        label: 'VideoCourseListPageFragment_courseId_61e033cf66c26f5e4bc1ae6177ee2748-0544-4ddc-a46c-e769387d3693'
    },
    {
        label: 'VideoCourseListPageFragment_courseId_6221c0f1c6d5f4d278cadf438dbcbefb-aa21-4156-9dc5-ee92d533180d'
    }
]

exports.BlockWhiteListPageAutoComplete = [
    { label: 'BlockerAppsDialog' },
    { label: 'BlockerAppsDialog_block_app_add' },
    { label: 'BlockerWebsiteDialog' },
    { label: 'BlockerWebsiteDialog_block_web_add' },
    { label: 'CustomKeywordDialog' },
    { label: 'CustomKeywordDialog_block_keyword_add' },
    { label: 'NewKeywordWebsiteAppListMainFragment' },
    {
        label: 'NewKeywordWebsiteAppListMainFragment_apps_dialog_not_shown'
    },
    { label: 'NewKeywordWebsiteAppListMainFragment_apps_dialog_shown' },
    {
        label: 'NewKeywordWebsiteAppListMainFragment_keyword_dialog_not_shown'
    },
    {
        label: 'NewKeywordWebsiteAppListMainFragment_keyword_dialog_shown'
    },
    { label: 'NewKeywordWebsiteAppListMainFragment_sortby_popup_show' },
    {
        label: 'NewKeywordWebsiteAppListMainFragment_web_dialog_not_shown'
    },
    { label: 'NewKeywordWebsiteAppListMainFragment_web_dialog_shown' }
]

exports.CommunityPageAutocomplete = [{ label: 'ClickRebootButton' }]
exports.CustomWallpaperAutocomplete = [
    { label: 'CustomWallpaperActivity' },
    { label: 'GoalSettingPageFragment_go_back_from_image_selection' },
    { label: 'GoalSettingPageFragment_image10' },
    { label: 'GoalSettingPageFragment_image11' },
    { label: 'GoalSettingPageFragment_image4' },
    { label: 'GoalSettingPageFragment_image6' },
    { label: 'GoalSettingPageFragment_image7' },
    { label: 'GoalSettingPageFragment_set_wallpaper_click' }
]
exports.FeedAutocomplete = [
    { label: 'CourseDetailFragment_FeedImagePost' },
    { label: 'FeedDetailsFragment' },
    { label: 'FeedDetailsFragment_ChatMessage' },
    { label: 'FeedDetailsFragment_Comment' },
    { label: 'FeedDetailsFragment_CommentName' },
    { label: 'FeedDetailsFragment_CommentOnSubmit' },
    { label: 'FeedDetailsFragment_FeedInfo' },
    { label: 'FeedDetailsFragment_FeedLike' },
    { label: 'FeedDetailsFragment_Hide' },
    { label: 'FeedDetailsFragment_Image' },
    { label: 'FeedDetailsFragment_ReportInfo' },
    { label: 'FeedDetailsFragment_UserName' },
    { label: 'FeedDisplayFragment' },
    { label: 'FeedDisplayFragment_BackClick' },
    { label: 'FeedDisplayFragment_CallMessage' },
    { label: 'FeedDisplayFragment_FeedCommentButton' },
    { label: 'FeedDisplayFragment_FeedDisLike' },
    { label: 'FeedDisplayFragment_FeedImage' },
    { label: 'FeedDisplayFragment_FeedInfo' },
    { label: 'FeedDisplayFragment_FeedItem' },
    { label: 'FeedDisplayFragment_FeedLike' },
    { label: 'FeedDisplayFragment_FilterClick' },
    { label: 'FeedDisplayFragment_Flotingimage' },
    { label: 'FeedDisplayFragment_Flotingpolling' },
    { label: 'FeedDisplayFragment_Flotingtext' },
    { label: 'FeedDisplayFragment_SwipeRefresh' },
    { label: 'FeedDisplayFragment_UserName' },
    { label: 'FeedImagePostFragment_select_image' },
    { label: 'FeedPollPostFragment_duration_card' },
    { label: 'FeedPollPostFragment_duration_selected_ok' },
    { label: 'FeedPostTypeSelectionFragment_image' },
    { label: 'FeedPostTypeSelectionFragment_polling' },
    { label: 'FeedPostTypeSelectionFragment_text' },
    { label: 'FeedPostingFragment' },
    { label: 'FeedPostingFragment_Back' },
    { label: 'FeedPostingFragment_Submit' },
    { label: 'FeedPostingFragment_TagItemSelected' },
    { label: 'FeedPostingFragment_description_entered_on_back' },
    { label: 'FeedPostingFragment_title_entered_on_back' },
    { label: 'FeedTagListFragment' },
    { label: 'FeedTagListFragment_Close' },
    { label: 'FeedTagListFragment_sort_filter_about_to_relapse' },
    { label: 'FeedTagListFragment_sort_filter_all' },
    { label: 'FeedTagListFragment_sort_filter_christian' },
    { label: 'FeedTagListFragment_sort_filter_comment' },
    { label: 'FeedTagListFragment_sort_filter_hindu' },
    { label: 'FeedTagListFragment_sort_filter_i_relapsed' },
    { label: 'FeedTagListFragment_sort_filter_muslim' },
    {
        label: 'FeedTagListFragment_sort_filter_need_an_accountability_partner'
    },
    { label: 'FeedTagListFragment_sort_filter_random' },
    { label: 'FeedTagListFragment_sort_filter_upvote' },
    { label: 'FeedTagListFragment_tab_selected_filter_by_tag' },
    { label: 'FeedTagListFragment_tab_selected_sort_by' },
    { label: 'PostTagsViewModel_imagePost' },
    { label: 'PostTagsViewModel_pollingPost' },
    { label: 'PostTagsViewModel_textPost' }
]
exports.MainPageAutocomplete = [{ label: 'ClickRebootButton' }]
exports.NotificationActionAutocomplete = [
    { label: 'NotificationAction_notification_accessiblity_malfunction' },
    {
        label: 'NotificationAction_notification_blockerx_accesscode_request'
    },
    {
        label: 'NotificationAction_notification_blockerx_user_feed_post_liked'
    },
    {
        label: 'NotificationAction_notification_daily_feed_motivation_action'
    },
    {
        label: 'NotificationAction_notification_id_blockerx_user_feed_follow_you'
    },
    {
        label: 'NotificationAction_notification_user_oto_chat_message_receive'
    },
    { label: 'NotificationAction_open_support_ticket_replay' },
    { label: 'NotificationAction_show_request_to_purchase_premium' },
    {
        label: 'NotificationAction_show_request_to_purchase_premium_action'
    },
    { label: 'NotificationAction_time_delay_partner_request_action' }
]
exports.OtherAutocomplete = [
    { label: 'BlockerxTransparentActivity' },
    { label: 'SuccessFailDialog' },
    { label: 'SuccessFailDialog_Dismiss' },
    { label: 'WebActivity' },
    { label: 'YoutubePlayerAttachFragment' }
]
exports.PurchasePremiumAutocomplete = [
    { label: 'BroughtPremiumFragment_update_premium_show' },
    { label: 'BuyCoinsDialog' },
    { label: 'IntroPopupLeastPriceDialog' },
    { label: 'IntroPopupLeastPriceDialog_skip' },
    { label: 'ManageSubscriptionFloatingActivity' },
    {
        label: 'ManageSubscriptionFloatingActivity_Cancel_Subscription_Button'
    },
    { label: 'ManageSubscriptionFloatingActivity_Close_Dialog_Button' },
    { label: 'NewMyAccountSettingActivity_manage_subscription' },
    { label: 'NewPurchasePremiumFragment' },
    { label: 'NewPurchasePremiumFragment_access_premium_update_premium' },
    { label: 'NewPurchasePremiumFragment_annual_purchase_success' },
    { label: 'NewPurchasePremiumFragment_i_have_question' },
    {
        label: 'NewPurchasePremiumFragment_intro_annual_plan_purchase_success'
    },
    { label: 'NewPurchasePremiumFragment_lifetime_purchase_success' },
    { label: 'NewPurchasePremiumFragment_monthly_purchase_success' },
    { label: 'NewPurchasePremiumFragment_purchase_button_annual' },
    { label: 'NewPurchasePremiumFragment_purchase_button_lifetime' },
    { label: 'NewPurchasePremiumFragment_purchase_button_monthly' },
    { label: 'NewPurchasePremiumFragment_purchase_button_null' },
    { label: 'NewPurchasePremiumFragment_purchase_init_cancel_annual' },
    {
        label: 'NewPurchasePremiumFragment_purchase_init_cancel_intro_annual_plan'
    },
    { label: 'NewPurchasePremiumFragment_purchase_init_cancel_lifetime' },
    { label: 'NewPurchasePremiumFragment_purchase_init_cancel_monthly' },
    { label: 'NewPurchasePremiumFragment_purchase_success' },
    { label: 'NewPurchasePremiumFragment_selected_plan_annual' },
    { label: 'NewPurchasePremiumFragment_selected_plan_lifetime' },
    { label: 'NewPurchasePremiumFragment_selected_plan_monthly' },
    { label: 'NewPurchasePremiumFragment_tryforfree_anonymous' },
    { label: 'NofapLifetimePurchesDialog' },
    { label: 'NofapLifetimePurchesDialog_skip' },
    { label: 'NofapLifetimePurchesDialog_try_for_free_button' },
    { label: 'NofapLifetimePurchesDialog_tryforfree' },
    { label: 'NofapLifetimePurchesDialog_tryforfree_anonymous' },
    { label: 'NotFreeDisclaimerDialog' },
    { label: 'NotFreeDisclaimerDialog_got_it_button' },
    { label: 'NotFreeDisclaimerVideosDialog' },
    { label: 'OnBoardingFragment_try_for_free_button' },
    { label: 'OnBoardingFragment_tryforfree_anonymous' },
    { label: 'PremiumFlotingActivity' },
    { label: 'SubscribeViewModel_GooglePurchasePrice_premium_six_70' },
    { label: 'SubscribeViewModel_anonymous_purchase_success' }
]
exports.RatingAutocomplete = [
    { label: 'WeeklyAndHome_rating_value_1' },
    { label: 'WeeklyAndHome_rating_value_3' },
    { label: 'WeeklyAndHome_rating_value_5' }
]
exports.ReferEarnAutocomplete = [
    { label: 'ReferEarnActivity' },
    { label: 'ReferEarnActivity_BackButton' },
    { label: 'ReferEarnActivity_BuyCoinButton' },
    { label: 'ReferEarnActivity_OfferWallButton' },
    { label: 'ReferEarnActivity_OfferWallInfo' },
    { label: 'ReferEarnActivity_RedeemCoinButton' },
    { label: 'ReferEarnActivity_pollFishOfferWallButton' }
]
exports.SettingPageAutocomplete = [
    { label: 'NewBlockerXSettingActivity_change_app_language' },
    { label: 'NewBlockerXSettingActivity_change_app_language_card_ar' },
    { label: 'NewBlockerXSettingActivity_change_app_language_card_en' },
    { label: 'NewBlockerXSettingActivity_change_app_language_card_pt' },
    {
        label: 'NewBlockerXSettingActivity_change_app_language_confirm_continue'
    },
    { label: 'NewBlockerXSettingActivity_credit' },
    { label: 'NewBlockerXSettingActivity_facebookfollow' },
    { label: 'NewBlockerXSettingActivity_instafollow' },
    { label: 'NewBlockerXSettingActivity_my_account' },
    { label: 'NewBlockerXSettingActivity_share' },
    { label: 'NewBlockerXSettingActivity_signout' }
]
exports.SignInSignUpAutocomplete = [
    { label: 'OnBoardingFragment_bad_news' },
    { label: 'OnBoardingFragment_community_description_next' },
    { label: 'OnBoardingFragment_customer_support_codi_next' },
    { label: 'OnBoardingFragment_empower_yourself_next' },
    { label: 'OnBoardingFragment_get_started' },
    { label: 'OnBoardingFragment_get_started_now' },
    { label: 'OnBoardingFragment_get_started_redo' },
    { label: 'OnBoardingFragment_good_news' },
    { label: 'OnBoardingFragment_login_click' },
    { label: 'OnBoardingFragment_next' },
    { label: 'OnBoardingFragment_porn_is_drug_continue' },
    { label: 'OnBoardingFragment_porn_is_drug_next' },
    { label: 'OnBoardingFragment_premium_page1_continue' },
    { label: 'OnBoardingFragment_premium_page1_skip' },
    { label: 'OnBoardingFragment_premium_page2_continue' },
    { label: 'OnBoardingFragment_premium_page2_skip' },
    { label: 'OnBoardingFragment_quiz_flow_question1_0' },
    { label: 'OnBoardingFragment_quiz_flow_question1_1' },
    { label: 'OnBoardingFragment_quiz_flow_question1_2' },
    { label: 'OnBoardingFragment_quiz_flow_question1_3' },
    { label: 'OnBoardingFragment_quiz_flow_question2_0' },
    { label: 'OnBoardingFragment_quiz_flow_question2_1' },
    { label: 'OnBoardingFragment_quiz_flow_question2_2' },
    { label: 'OnBoardingFragment_quiz_flow_question2_3' },
    { label: 'OnBoardingFragment_quiz_flow_question3_0' },
    { label: 'OnBoardingFragment_quiz_flow_question3_1' },
    { label: 'OnBoardingFragment_quiz_flow_question3_2' },
    { label: 'OnBoardingFragment_quiz_flow_question3_3' },
    { label: 'OnBoardingFragment_quiz_flow_question4_0' },
    { label: 'OnBoardingFragment_quiz_flow_question4_1' },
    { label: 'OnBoardingFragment_quiz_flow_question4_2' },
    { label: 'OnBoardingFragment_quiz_flow_question5_0' },
    { label: 'OnBoardingFragment_quiz_flow_question5_1' },
    { label: 'OnBoardingFragment_quiz_flow_question5_2' },
    { label: 'OnBoardingFragment_quiz_flow_question6_0' },
    { label: 'OnBoardingFragment_quiz_flow_question6_1' },
    { label: 'OnBoardingFragment_quiz_flow_question6_2' },
    { label: 'OnBoardingFragment_quiz_flow_question7_0' },
    { label: 'OnBoardingFragment_quiz_flow_question7_1' },
    { label: 'OnBoardingFragment_quiz_flow_question7_2' },
    { label: 'OnBoardingFragment_rating_given_from_bottom' },
    { label: 'OnBoardingFragment_resource_description_next' },
    { label: 'OnBoardingFragment_session_eliminated_next' },
    { label: 'OnBoardingFragment_university_research' },
    { label: 'SignInSignUpGlobal_back_login_page' },
    { label: 'SignInSignUpGlobal_back_reset_password' },
    { label: 'SignInSignUpGlobal_back_signup_email' },
    { label: 'SignInSignUpGlobal_back_to_login' },
    { label: 'SignInSignUpGlobal_email_signin_global' },
    { label: 'SignInSignUpGlobal_email_signup_dialog_global_show_false' },
    { label: 'SignInSignUpGlobal_email_signup_dialog_global_show_true' },
    { label: 'SignInSignUpGlobal_email_signup_global' },
    { label: 'SignInSignUpGlobal_email_subscribe_newsletter_false' },
    { label: 'SignInSignUpGlobal_email_subscribe_newsletter_true' },
    { label: 'SignInSignUpGlobal_forgot_password' },
    { label: 'SignInSignUpGlobal_google_sign_in_from_login_page' },
    { label: 'SignInSignUpGlobal_google_sign_in_from_signup_page' },
    { label: 'SignInSignUpGlobal_google_signin_signup_global' },
    { label: 'SignInSignUpGlobal_login_click' },
    { label: 'SignInSignUpGlobal_login_click_email' },
    { label: 'SignInSignUpGlobal_login_from_email_page' },
    { label: 'SignInSignUpGlobal_selected_tab_signin' },
    { label: 'SignInSignUpGlobal_selected_tab_signup' },
    { label: 'SignInSignUpGlobal_send_from_reset_password' },
    { label: 'SignInSignUpGlobal_signin_signup_global_close' },
    { label: 'SignInSignUpGlobal_signup_email' },
    { label: 'SignInSignUpGlobal_signup_from_email_button' },
    { label: 'SignInSignUpGlobal_signup_google' },
    { label: 'SignInSignUpGlobal_skip' },
    { label: 'SignInSignUpWhileAppStart_email_signin_while_app_start' },
    { label: 'SignInSignUpWhileAppStart_email_signup_while_app_start' },
    {
        label: 'SignInSignUpWhileAppStart_google_signin_signup_while_app_start'
    },
    { label: 'SignInSignUpWhileAppStart_on_boarding_page_open' },
    {
        label: 'SignInSignUpWhileAppStart_open_policy_page_while_app_start'
    },
    {
        label: 'SignInSignUpWhileAppStart_open_terms_and_conditions_page_while_app_start'
    },
    { label: 'SignInSignUpWhileAppStart_skip_while_app_start' }
]
exports.UserProfileAutocomplete = [
    { label: 'SelfUpvotedCommnetedPostsFragment' },
    { label: 'SelfUpvotedCommnetedPostsFragment_FeedComment' },
    { label: 'SelfUpvotedCommnetedPostsFragment_FeedImage' },
    { label: 'SelfUpvotedCommnetedPostsFragment_FeedItem' },
    { label: 'SelfUpvotedCommnetedPostsFragment_FeedLike' },
    { label: 'SelfUpvotedCommnetedPostsFragment_UserName' },
    { label: 'UserAchievementFragment' },
    { label: 'UserFollowerFollowingFragment' },
    { label: 'UserFollowerFollowingFragment_BackPressedFromToolBar' },
    { label: 'UserFollowerFollowingFragment_Name' },
    { label: 'UserFollowerFollowingFragment_SwipeToRefresh' },
    { label: 'UserProfileFragment' },
    { label: 'UserProfileFragment_BackPressedFromToolBar' },
    { label: 'UserProfileFragment_ChatMessage' },
    { label: 'UserProfileFragment_FollowFolliwng' },
    { label: 'UserProfileFragment_Follower' },
    { label: 'UserProfileFragment_Following' },
    { label: 'UserProfileFragment_editUsername' },
    { label: 'UserProfileFragment_tab_selected_0' },
    { label: 'UserProfileFragment_tab_selected_1' },
    { label: 'UserProfileFragment_tab_selected_2' },
    { label: 'UserProfileFragment_tab_selected_3' }
]
exports.cohortProgramAutocomplete = [
    { label: 'CohortProgramFragment_zenze_click_feeds' }
]
exports.video_watch_idAutocomplete = [
    { label: 'ENJUwvnk9AE' },
    { label: 'ON1zaIyayKY' },
    { label: 'Z4TNzp5FIpI' },
    { label: 'reWIURrZw3Q' }
]
exports.AppSetupAutocomplete = [
    { label: 'AccessibilityPermissionActivity' },
    {
        label: 'AccessibilityPermissionActivity_IntroAccessibiltyScreenSkip'
    },
    { label: 'AccessibilityPermissionActivity_close' },
    { label: 'AccessibilityPermissionActivity_go_to_setting_button' },
    { label: 'AlertFlotingActivity_TurnOnInterNetOK' },
    { label: 'BlockerApplication_is_app_crash_true' },
    { label: 'CommonUtils_AccessiblityNotWorkingNotification101' },
    { label: 'DealingWithUrgesActivity' },
    { label: 'DialogFeedSetUserNameFragment_submit_button' },
    { label: 'EmailLinkHandleFlotingActivity' },
    { label: 'EmailLinkHandleFlotingActivity_open_custom_url' },
    { label: 'GlobalActivityToOpenFromAnywhere' },
    { label: 'GuideActivity' },
    { label: 'MainActivity' },
    { label: 'OnBoardingFragment_SignInSuccess' },
    { label: 'OnBoardingFragment_policy' },
    { label: 'RatingActivity' },
    { label: 'SetDefaultBrowseFragment' },
    { label: 'SetDefaultBrowseFragment_Skip' },
    { label: 'SetDefaultBrowseFragment_SkipNext' },
    { label: 'SetDefaultBrowseFragment_SkipNo' },
    { label: 'SetDefaultBrowseFragment_SkipYes' },
    { label: 'SetDefaultBrowseFragment_btnBrowserStatus' },
    { label: 'SignInSigUpGlobalActivity' },
    { label: 'SignInSignUpGlobalFragment_SignInSuccess' },
    { label: 'SignInSignUpGlobalFragment_policy' },
    { label: 'SignInSignUpGlobalFragment_txtSkip' },
    { label: 'SignInSignUpWhileAppStartActivity' },
    { label: 'SplashScreenFragment' }
]
exports.BlockWindowPageAutocomplete = [
    { label: 'AdultBlockWindowUtils_block_window_go_back_click' },
    { label: 'AdultBlockWindowUtils_block_window_show' },
    { label: 'AdultBlockWindowUtils_block_window_strict_mode_activate' },
    { label: 'AdultBlockWindowUtils_bw_block_me_emergency_active' },
    { label: 'AdultBlockWindowUtils_bw_block_me_emergency_premium_show' },
    { label: 'AdultBlockWindowUtils_bw_block_me_emergency_show' },
    { label: 'AdultBlockWindowUtils_premium_page_on_go_back_click' },
    { label: 'AdultBlockWindowUtils_purchase_nnn_click' },
    { label: 'AdultBlockWindowUtils_rrm_card_clicked' },
    { label: 'AdultBlockWindowUtils_text_why_clicked' },
    { label: 'AdultBlockWindowUtils_text_wrongly_blocked_click' },
    { label: 'AdultBlockWindowUtils_znze_promotion' },
    { label: 'blockWindowsUnsupportedBrowserReminder' },
    {
        label: 'blockWindowsUnsupportedBrowserReminder_btnUnsupportedBrowserReminderBWNo'
    },
    {
        label: 'blockWindowsUnsupportedBrowserReminder_btnUnsupportedBrowserReminderBWYes'
    },
    {
        label: 'blockWindowsUnsupportedBrowserReminder_txtUnsupportedBrowserReminderBWNoBother'
    }
]
exports.CommunicationAutocomplete = [
    { label: 'CallMessageLaunchFragment' },
    { label: 'CallMessageLaunchFragment_BackPressedFromToolBar' },
    { label: 'EnterChatUserNameDialog' },
    { label: 'EnterChatUserNameDialog_submit_button' },
    { label: 'OneToOneChatFragment' },
    { label: 'OneToOneChatFragment_BackPressedFromToolBar' },
    { label: 'OneToOneChatFragment_Emoji' },
    { label: 'OneToOneChatFragment_MessageSubmit' },
    { label: 'OneToOneChatFragment_UserProfile' },
    { label: 'OneToOneChatHistoryFragment' },
    { label: 'OneToOneChatHistoryFragment_Name' },
    { label: 'OnlineUsersFragment' },
    { label: 'OnlineUsersFragment_Chat' },
    { label: 'OnlineUsersFragment_Name' },
    { label: 'OnlineUsersFragment_SwipeToRefresh' }
]
exports.DealingWithUrgesAutocomplete = [
    { label: 'DealingWithUrgesFragment' },
    { label: 'GoalSettingPageFragment_breathing_clicked_0' },
    { label: 'GoalSettingPageFragment_breathing_clicked_1' },
    { label: 'GoalSettingPageFragment_breathing_clicked_2' },
    { label: 'GoalSettingPageFragment_breathing_clicked_3' },
    { label: 'GoalSettingPageFragment_breathing_clicked_4' },
    { label: 'GoalSettingPageFragment_close_conquered_urges' },
    { label: 'GoalSettingPageFragment_close_dealing_webview' },
    { label: 'GoalSettingPageFragment_close_do_you_watched_porn' },
    { label: 'GoalSettingPageFragment_close_still_time_left' },
    { label: 'GoalSettingPageFragment_close_streak_reset' },
    { label: 'GoalSettingPageFragment_close_timer_screen' },
    { label: 'GoalSettingPageFragment_close_urges_popup' },
    { label: 'GoalSettingPageFragment_close_urges_premium_dialog' },
    { label: 'GoalSettingPageFragment_games_clicked_0' },
    { label: 'GoalSettingPageFragment_games_clicked_1' },
    { label: 'GoalSettingPageFragment_games_clicked_2' },
    { label: 'GoalSettingPageFragment_games_clicked_3' },
    { label: 'GoalSettingPageFragment_i_am_having_urges' },
    { label: 'GoalSettingPageFragment_i_watched_porn' },
    { label: 'GoalSettingPageFragment_keep_moving_forward' },
    { label: 'GoalSettingPageFragment_no_do_you_watched_porn' },
    { label: 'GoalSettingPageFragment_no_still_time_left' },
    { label: 'GoalSettingPageFragment_purchase_premium_urges_click' },
    { label: 'GoalSettingPageFragment_try_again_button' },
    { label: 'GoalSettingPageFragment_video_clicked_0' },
    { label: 'GoalSettingPageFragment_video_clicked_1' },
    { label: 'GoalSettingPageFragment_video_clicked_4' },
    { label: 'GoalSettingPageFragment_yes_do_you_watched_porn' },
    { label: 'GoalSettingPageFragment_yes_still_time_left' }
]
exports.HelpAutocomplete = [{ label: 'ClickRebootButton' }]
exports.HomePageAutocomplete = [
    { label: 'BlockerXInAppBrowserBlockingPageFragment' },
    { label: 'BlockerXLandingPageFeatureDetailsActivity' },
    {
        label: 'BlockerXLandingPageFeatureDetailsActivity_back_in_app_browser_blocking'
    },
    { label: 'BlockerXLandingPageFeatureDetailsActivity_back_learning' },
    { label: 'BlockerXLandingPageFragment' },
    { label: 'BlockerXLandingPageFragment_Aperçudumodedevie' },
    {
        label: 'BlockerXLandingPageFragment_Approfondimentisullostiledivita'
    },
    { label: 'BlockerXLandingPageFragment_Comentários' },
    { label: 'BlockerXLandingPageFragment_Feedback' },
    { label: 'BlockerXLandingPageFragment_Ilmiodiario' },
    { label: 'BlockerXLandingPageFragment_Informacjazwrotna' },
    { label: 'BlockerXLandingPageFragment_Informacjeostylużycia' },
    { label: 'BlockerXLandingPageFragment_Insightsdeestilodevida' },
    { label: 'BlockerXLandingPageFragment_Inzichteninlevensstijl' },
    { label: 'BlockerXLandingPageFragment_Jurnalsaya' },
    { label: 'BlockerXLandingPageFragment_Lifestyle-Einblicke' },
    { label: 'BlockerXLandingPageFragment_LifestyleInsights' },
    { label: 'BlockerXLandingPageFragment_MeinTagebuch' },
    { label: 'BlockerXLandingPageFragment_Meujornal' },
    { label: 'BlockerXLandingPageFragment_Midiario' },
    { label: 'BlockerXLandingPageFragment_Mindagbok' },
    { label: 'BlockerXLandingPageFragment_Mojdnevnik' },
    { label: 'BlockerXLandingPageFragment_Monjournal' },
    { label: 'BlockerXLandingPageFragment_MyJournal' },
    { label: 'BlockerXLandingPageFragment_Mójpamiętnik' },
    { label: 'BlockerXLandingPageFragment_Perspectivasdeestilodevida' },
    { label: 'BlockerXLandingPageFragment_Pornaddictiontest' },
    { label: 'BlockerXLandingPageFragment_Porno-riippuvuustesti' },
    { label: 'BlockerXLandingPageFragment_Pornosuchttest' },
    { label: 'BlockerXLandingPageFragment_Povratneinformacije' },
    {
        label: 'BlockerXLandingPageFragment_Pruebadeadicciónalapornografía'
    },
    { label: 'BlockerXLandingPageFragment_Recursos' },
    { label: 'BlockerXLandingPageFragment_Resources' },
    { label: 'BlockerXLandingPageFragment_Ressourcen' },
    { label: 'BlockerXLandingPageFragment_Ressources' },
    { label: 'BlockerXLandingPageFragment_Retourdinformation' },
    { label: 'BlockerXLandingPageFragment_Retroalimentación' },
    { label: 'BlockerXLandingPageFragment_Risorse' },
    { label: 'BlockerXLandingPageFragment_Teskecanduanporno' },
    { label: 'BlockerXLandingPageFragment_Testdedépendanceauporno' },
    { label: 'BlockerXLandingPageFragment_Testdidipendenzadaporno' },
    { label: 'BlockerXLandingPageFragment_Testedevícioempornografia' },
    { label: 'BlockerXLandingPageFragment_Testförporrberoende' },
    { label: 'BlockerXLandingPageFragment_Testovisnostiopornografiji' },
    {
        label: 'BlockerXLandingPageFragment_Testuzależnieniaodpornografii'
    },
    {
        label: 'BlockerXLandingPageFragment_ThôngtinchitiếtvềPhongcáchsống'
    },
    { label: 'BlockerXLandingPageFragment_Thửnghiệmnghiệnkhiêudâm' },
    { label: 'BlockerXLandingPageFragment_Tàinguyên' },
    { label: 'BlockerXLandingPageFragment_Tạpchícủatôi' },
    { label: 'BlockerXLandingPageFragment_Uvidiuživotnistil' },
    { label: 'BlockerXLandingPageFragment_Zasoby' },
    { label: 'BlockerXLandingPageFragment_affiliate_nine_day' },
    { label: 'BlockerXLandingPageFragment_affiliate_twitter' },
    { label: 'BlockerXLandingPageFragment_chatbot_click' },
    { label: 'BlockerXLandingPageFragment_dealing_with_urges' },
    { label: 'BlockerXLandingPageFragment_goal_more' },
    { label: 'BlockerXLandingPageFragment_page1_next' },
    { label: 'BlockerXLandingPageFragment_page2_next' },
    { label: 'BlockerXLandingPageFragment_page3_next' },
    { label: 'BlockerXLandingPageFragment_page4_next' },
    { label: 'BlockerXLandingPageFragment_reboot_now_card' },
    { label: 'BlockerXLandingPageFragment_selected_swipe_card_0' },
    { label: 'BlockerXLandingPageFragment_selected_swipe_card_1' },
    { label: 'BlockerXLandingPageFragment_selected_swipe_card_2' },
    { label: 'BlockerXLandingPageFragment_usge_access_permission' },
    {
        label: 'BlockerXLandingPageFragment_usge_access_permission_success'
    },
    { label: 'BlockerXLandingPageFragment_zenze_promotion' },
    { label: 'BlockerXLandingPageFragment_Életmód-betekintések' },
    { label: 'BlockerXLandingPageFragment_Зворотнійзвязок' },
    { label: 'BlockerXLandingPageFragment_Мойжурнал' },
    { label: 'BlockerXLandingPageFragment_Мійжурнал' },
    { label: 'BlockerXLandingPageFragment_Образжизни' },
    { label: 'BlockerXLandingPageFragment_Ресурсы' },
    { label: 'BlockerXLandingPageFragment_Тестназависимостьотпорно' },
    { label: 'BlockerXLandingPageFragment_Тестнапорнозалежність' },
    { label: 'BlockerXLandingPageFragment_اختبارإدمانالموادالإباحية' },
    { label: 'BlockerXLandingPageFragment_استجابة' },
    { label: 'BlockerXLandingPageFragment_رؤىأسلوبالحياة' },
    { label: 'BlockerXLandingPageFragment_صحيفتي' },
    { label: 'BlockerXLandingPageFragment_موارد' },
    { label: 'FaqFragment' },
    { label: 'FaqFragment_support' },
    { label: 'LandingUserFeedbackFragment' },
    { label: 'LandingUserFeedbackFragment_back' },
    { label: 'LoadAllWebViewFragment' },
    { label: 'LoadAllWebViewFragment_add_widget_codi' },
    { label: 'LoadAllWebViewFragment_back' },
    { label: 'LoadAllWebViewFragment_support_back' },
    { label: 'RebootNowFragment' },
    { label: 'RebootNowFragment_back' },
    { label: 'RebootNowFragment_back_to_selection_page' },
    { label: 'RebootNowFragment_guide_rebootNow' },
    { label: 'RebootNowFragment_preview_ed_handbook' },
    { label: 'RebootNowFragment_preview_rebootNow' },
    { label: 'RebootNowFragment_preview_release_alpha' },
    { label: 'RebootNowFragment_purchase_init_cancel' },
    { label: 'RebootNowFragment_recovery' },
    { label: 'RebootNowFragment_selected_ed_handbook' },
    { label: 'RebootNowFragment_selected_get_all_e_book' },
    { label: 'RebootNowFragment_selected_recovery_roadmap' },
    { label: 'RebootNowFragment_selected_release_your_alpha' },
    { label: 'StreakHistoryFragment' },
    { label: 'SupportPageFreeUser_Support' },
    { label: 'SupportPageFreeUser_chat_support' }
]
exports.JournalAutocomplete = [
    { label: 'NewPersonalJournalAddEditFragment' },
    { label: 'NewPersonalJournalAddEditFragment_DescriptionInfo' },
    { label: 'NewPersonalJournalAddEditFragment_back' },
    { label: 'NewPersonalJournalAddEditFragment_dropDownInfo' },
    { label: 'NewPersonalJournalAddEditFragment_emojiItemClick1' },
    { label: 'NewPersonalJournalAddEditFragment_emojiItemClick2' },
    { label: 'NewPersonalJournalAddEditFragment_emojiItemClick3' },
    { label: 'NewPersonalJournalAddEditFragment_emojiItemClick4' },
    { label: 'NewPersonalJournalAddEditFragment_infoPopUpClose' },
    { label: 'NewPersonalJournalAddEditFragment_onBackClick' },
    { label: 'NewPersonalJournalAddEditFragment_onSubmitClick' },
    { label: 'NewPersonalJournalAddEditFragment_submitClick' },
    {
        label: 'NewPersonalJournalAddEditFragment_thoughtDropdownItemClick0'
    },
    {
        label: 'NewPersonalJournalAddEditFragment_thoughtDropdownItemClick1'
    },
    {
        label: 'NewPersonalJournalAddEditFragment_thoughtDropdownItemClick2'
    },
    {
        label: 'NewPersonalJournalAddEditFragment_thoughtDropdownItemClick4'
    },
    { label: 'NewPersonalJournalAddEditFragment_thoughtDropdownOpen' },
    { label: 'NewPersonalJournalDetailFragment' },
    { label: 'NewPersonalJournalMainFragment' },
    { label: 'NewPersonalJournalMainFragment_back' },
    { label: 'NewPersonalJournalMainFragment_calender' },
    { label: 'NewPersonalJournalMainFragment_card' },
    { label: 'NewPersonalJournalMainFragment_onAddJournal' },
    { label: 'NewPersonalJournalMainFragment_onBack' }
]
exports.MainActivityAutocomplete = [
    { label: 'MainActivity_click_BLOCKING' },
    { label: 'MainActivity_click_BLOCK_ITEM' },
    { label: 'MainActivity_click_COMMUNITY' },
    { label: 'MainActivity_click_HOME' },
    { label: 'MainActivity_click_PREMIUM' },
    { label: 'MainActivity_coin_dashboard_clicked' },
    { label: 'MainActivity_enter_username_navigation_drawer' },
    { label: 'MainActivity_faq_clicked' },
    { label: 'MainActivity_hamburger_open' },
    { label: 'MainActivity_last_screen_visited_ArticleListPageFragment' },
    {
        label: 'MainActivity_last_screen_visited_BlockMeScheduleTimeFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_BlockerXInAppBrowserBlockingPageFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_BlockerXLandingPageFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_BlockerxTransparentActivity'
    },
    { label: 'MainActivity_last_screen_visited_BuyCoinsDialog' },
    { label: 'MainActivity_last_screen_visited_CourseDetailFragment' },
    {
        label: 'MainActivity_last_screen_visited_DealingWithUrgesFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_DialogFeedSetUserNameFragment'
    },
    { label: 'MainActivity_last_screen_visited_FaqFragment' },
    { label: 'MainActivity_last_screen_visited_FeedDetailsFragment' },
    { label: 'MainActivity_last_screen_visited_FeedDisplayActivity' },
    { label: 'MainActivity_last_screen_visited_FeedDisplayFragment' },
    {
        label: 'MainActivity_last_screen_visited_FeedDisplayFullImageFragment'
    },
    { label: 'MainActivity_last_screen_visited_FeedImagePostFragment' },
    {
        label: 'MainActivity_last_screen_visited_FeedLaunchConditionFragment'
    },
    { label: 'MainActivity_last_screen_visited_FeedPollPostFragment' },
    { label: 'MainActivity_last_screen_visited_FeedPostingFragment' },
    { label: 'MainActivity_last_screen_visited_FeedTagListFragment' },
    {
        label: 'MainActivity_last_screen_visited_FeedVideoPostFullScreenFragment'
    },
    { label: 'MainActivity_last_screen_visited_GoalSettingPageFragment' },
    {
        label: 'MainActivity_last_screen_visited_IntroPopupLeastPriceDialog'
    },
    { label: 'MainActivity_last_screen_visited_LoadAllWebViewFragment' },
    {
        label: 'MainActivity_last_screen_visited_NewBlockerXSettingFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_NewKeywordWebsiteAppListMainFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_NewMyAccountSettingFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_NewPersonalJournalMainFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_NewPurchasePremiumFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_NofapLifetimePurchesDialog'
    },
    { label: 'MainActivity_last_screen_visited_NotificationFragment' },
    { label: 'MainActivity_last_screen_visited_OtherBlockingFragment' },
    {
        label: 'MainActivity_last_screen_visited_PornAddictionTestFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_PornMasturbationInsightsFragment'
    },
    { label: 'MainActivity_last_screen_visited_RebootNowFragment' },
    { label: 'MainActivity_last_screen_visited_ReferEarnActivity' },
    {
        label: 'MainActivity_last_screen_visited_SelfUpvotedCommnetedPostsFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_SetDefaultBrowseFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_SignInSignUpGlobalFragment'
    },
    {
        label: 'MainActivity_last_screen_visited_SignInSignUpWhileAppStartActivity'
    },
    { label: 'MainActivity_last_screen_visited_StreakHistoryFragment' },
    {
        label: 'MainActivity_last_screen_visited_StreakLeaderBoardFragment'
    },
    { label: 'MainActivity_last_screen_visited_SwitchPageFragment' },
    { label: 'MainActivity_last_screen_visited_UserProfileFragment' },
    {
        label: 'MainActivity_last_screen_visited_VideoCourseListPageFragment'
    },
    { label: 'MainActivity_login_clicked' },
    { label: 'MainActivity_notification_clicked' },
    { label: 'MainActivity_null_clicked' },
    { label: 'MainActivity_open_setting_navigation_drawer' },
    { label: 'MainActivity_other_blocking_clicked' },
    { label: 'MainActivity_premium_clicked' },
    { label: 'MainActivity_settings_clicked' },
    { label: 'MainActivity_sign_in_navigation_drawer' },
    { label: 'MainActivity_support_clicked' },
    { label: 'MainActivity_user_feedback_clicked' }
]
exports.NpsNumberAutocomplete = [
    { label: 'AdultBlockWindowUtils_Nps_1' },
    { label: 'AdultBlockWindowUtils_Nps_10' },
    { label: 'AdultBlockWindowUtils_Nps_2' },
    { label: 'AdultBlockWindowUtils_Nps_3' },
    { label: 'AdultBlockWindowUtils_Nps_6' },
    { label: 'AdultBlockWindowUtils_Nps_7' },
    { label: 'AdultBlockWindowUtils_Nps_8' },
    { label: 'AdultBlockWindowUtils_Nps_9' },
    { label: 'AdultBlockWindowUtils_Nps_View' },
    { label: 'PornAddictionTestFragment_close' },
    { label: 'PornAddictionTestFragment_expand' },
    {
        label: 'PornAddictionTestFragment_open_article_video_pat_addicted_to_pornography.json'
    },
    {
        label: 'PornAddictionTestFragment_open_article_video_pat_emerging_addiction.json'
    },
    {
        label: 'PornAddictionTestFragment_open_article_video_pat_normal_sexual_development.json'
    },
    {
        label: 'PornAddictionTestFragment_open_article_video_pat_red_flag.json'
    },
    { label: 'PornAddictionTestFragment_open_reboot_now_page' },
    {
        label: 'PornAddictionTestFragment_open_reboot_now_pat_addicted_to_pornography.json'
    },
    {
        label: 'PornAddictionTestFragment_open_reboot_now_pat_emerging_addiction.json'
    },
    { label: 'PornAddictionTestFragment_previous' },
    { label: 'PornAddictionTestFragment_result_complete' },
    { label: 'PornAddictionTestFragment_skip_test' },
    { label: 'PornAddictionTestFragment_start_test' },
    { label: 'PornAddictionTestFragment_submit' }
]
exports.PornMasturbationAutocomplete = [
    {
        label: 'PornMasturbationInsightsFragment_back_button_click_page_4'
    },
    {
        label: 'PornMasturbationInsightsFragment_back_button_click_page_5'
    },
    {
        label: 'PornMasturbationInsightsFragment_back_button_click_page_6'
    },
    { label: 'PornMasturbationInsightsFragment_close_page' },
    { label: 'PornMasturbationInsightsFragment_next_button_click' },
    {
        label: 'PornMasturbationInsightsFragment_next_button_click_page_2'
    },
    {
        label: 'PornMasturbationInsightsFragment_next_button_click_page_3'
    },
    {
        label: 'PornMasturbationInsightsFragment_next_button_click_page_4'
    },
    {
        label: 'PornMasturbationInsightsFragment_next_button_click_page_5'
    },
    {
        label: 'PornMasturbationInsightsFragment_next_button_click_page_6'
    },
    {
        label: 'PornMasturbationInsightsFragment_next_button_click_page_7'
    }
]
exports.StreakAutocomplete = [
    { label: 'GoalSettingPageFragment' },
    { label: 'GoalSettingPageFragment_relapsed' },
    { label: 'SelectCountryDialog' },
    { label: 'SelectCountryDialog_close' },
    { label: 'StreakInfoActivity' },
    { label: 'StreakInfoFragment' },
    { label: 'StreakInfoFragment_back' },
    { label: 'StreakLeaderBoardFragment' },
    { label: 'StreakLeaderBoardFragment_userName' }
]
exports.SwitchPageAutocomplete = [
    { label: 'GoogleMeetCalenderGuideDialog_Always_On_VPN_OK' },
    { label: 'GuideActivity_battery_optimization' },
    { label: 'GuideActivity_battery_optimization_success' },
    { label: 'GuideActivity_push_notification' },
    { label: 'GuideActivity_push_notification_success' },
    { label: 'NotificationFragment' },
    { label: 'NotificationFragment_notification' },
    { label: 'OtherBlockingFragment' },
    { label: 'OtherBlockingFragment_' },
    { label: 'PatternLockActivity' },
    { label: 'SensorBuddyDialog_dismiss_sensor_buddy_dialog' },
    {
        label: 'SensorBuddyDialog_sensor_page_steps_complete_confirmation'
    },
    { label: 'SensorBuddyDialog_sensor_page_steps_counter_page' },
    { label: 'SensorBuddyDialog_sensor_page_task_success' },
    { label: 'SwitchAutoOnInfoDialog_SwitchAutoOnInfoDialog' },
    { label: 'SwitchAutoOnInfoDialog_close' },
    { label: 'SwitchAutoOnInfoDialog_turn_off_all_time' },
    { label: 'SwitchAutoOnInfoDialog_turn_off_for_3_hours' },
    { label: 'SwitchPageFragment' },
    { label: 'SwitchPageFragment_' },
    { label: 'SwitchPageFragment_access_code_switch_on' },
    { label: 'SwitchPageFragment_accessibility_on_card' },
    { label: 'SwitchPageFragment_accountability_false' },
    { label: 'SwitchPageFragment_accountability_partner_longsentences' },
    { label: 'SwitchPageFragment_accountability_true' },
    { label: 'SwitchPageFragment_add_a_buddy_back' },
    { label: 'SwitchPageFragment_add_blockme_widget' },
    { label: 'SwitchPageFragment_add_buddy_selected_tab_0' },
    { label: 'SwitchPageFragment_add_buddy_selected_tab_1' },
    { label: 'SwitchPageFragment_all_block_browser_false' },
    { label: 'SwitchPageFragment_all_block_browser_true' },
    { label: 'SwitchPageFragment_all_browser_block_switch_on' },
    { label: 'SwitchPageFragment_all_premium_card_click' },
    { label: 'SwitchPageFragment_app_lock_false' },
    { label: 'SwitchPageFragment_app_lock_true' },
    { label: 'SwitchPageFragment_app_update_remind_me_later' },
    { label: 'SwitchPageFragment_auto_on_porn_blocker' },
    { label: 'SwitchPageFragment_auto_on_safe_search' },
    { label: 'SwitchPageFragment_auto_on_unsupported_browser' },
    { label: 'SwitchPageFragment_be_my_own_buddy_long_sentence' },
    { label: 'SwitchPageFragment_be_my_own_buddy_sensor' },
    { label: 'SwitchPageFragment_be_my_own_buddy_time_delay' },
    { label: 'SwitchPageFragment_block_in_app_browser_card' },
    { label: 'SwitchPageFragment_block_insta_reels_on' },
    { label: 'SwitchPageFragment_block_insta_search_on' },
    { label: 'SwitchPageFragment_block_me_button' },
    { label: 'SwitchPageFragment_block_snapchat_stories_on' },
    { label: 'SwitchPageFragment_block_yt_shorts_on' },
    { label: 'SwitchPageFragment_blockerSwitch_on' },
    { label: 'SwitchPageFragment_bw_custom_message_back' },
    { label: 'SwitchPageFragment_bw_custom_message_card' },
    { label: 'SwitchPageFragment_bw_custom_message_entered_back' },
    { label: 'SwitchPageFragment_bw_custom_message_entered_submit' },
    { label: 'SwitchPageFragment_bw_custom_message_submit' },
    { label: 'SwitchPageFragment_change_duration_cancel' },
    { label: 'SwitchPageFragment_click_close_dialog_custom_timer' },
    { label: 'SwitchPageFragment_close_from_switch_navigation_drawer' },
    { label: 'SwitchPageFragment_close_zenze_promotion' },
    {
        label: 'SwitchPageFragment_coin_dashboard_from_switch_navigation_drawer'
    },
    { label: 'SwitchPageFragment_custom_block_message_card' },
    { label: 'SwitchPageFragment_custom_time' },
    { label: 'SwitchPageFragment_custom_timer_set_120' },
    { label: 'SwitchPageFragment_custom_timer_set_15' },
    { label: 'SwitchPageFragment_custom_timer_set_30' },
    { label: 'SwitchPageFragment_custom_timer_set_60' },
    { label: 'SwitchPageFragment_custom_timing_go_back_page' },
    { label: 'SwitchPageFragment_custom_timing_on_block_windowfalse' },
    { label: 'SwitchPageFragment_custom_timing_on_block_windowtrue' },
    { label: 'SwitchPageFragment_custom_timing_submit_button_click' },
    { label: 'SwitchPageFragment_custom_url_card' },
    { label: 'SwitchPageFragment_custom_wallpaper_activity_open_click' },
    { label: 'SwitchPageFragment_custom_wallpaper_back' },
    { label: 'SwitchPageFragment_custom_wallpaper_on_block_windowfalse' },
    { label: 'SwitchPageFragment_custom_wallpaper_on_block_windowtrue' },
    { label: 'SwitchPageFragment_custom_wallpaper_switch_true' },
    { label: 'SwitchPageFragment_faq_from_switch_navigation_drawer' },
    { label: 'SwitchPageFragment_free_user_try_' },
    {
        label: 'SwitchPageFragment_free_user_try_all_browser_block_switch_on'
    },
    { label: 'SwitchPageFragment_free_user_try_block_insta_reels_on' },
    { label: 'SwitchPageFragment_free_user_try_block_insta_search_on' },
    {
        label: 'SwitchPageFragment_free_user_try_block_snapchat_stories_on'
    },
    { label: 'SwitchPageFragment_free_user_try_block_yt_shorts_on' },
    { label: 'SwitchPageFragment_free_user_try_blockerSwitch_on' },
    {
        label: 'SwitchPageFragment_free_user_try_custom_block_message_card'
    },
    {
        label: 'SwitchPageFragment_free_user_try_in_app_browser_card_click'
    },
    {
        label: 'SwitchPageFragment_free_user_try_no_vpn_safe_search_switch_on'
    },
    { label: 'SwitchPageFragment_free_user_try_pattern_lock_switch_on' },
    { label: 'SwitchPageFragment_free_user_try_prevent_image_video_on' },
    {
        label: 'SwitchPageFragment_free_user_try_unsupported_browser_switch_on'
    },
    { label: 'SwitchPageFragment_free_user_try_vpn_switch_on' },
    { label: 'SwitchPageFragment_gambling_card_true' },
    { label: 'SwitchPageFragment_help_me_widget_guide_card' },
    { label: 'SwitchPageFragment_i_am_my_buddy' },
    { label: 'SwitchPageFragment_image_video_false' },
    { label: 'SwitchPageFragment_image_video_true' },
    { label: 'SwitchPageFragment_in_app_browser_add_button' },
    { label: 'SwitchPageFragment_in_app_browser_card_click' },
    { label: 'SwitchPageFragment_in_app_browser_delete_button' },
    { label: 'SwitchPageFragment_in_app_browser_select_app_close' },
    { label: 'SwitchPageFragment_in_app_browser_select_app_tab_0' },
    { label: 'SwitchPageFragment_in_app_browser_select_app_tab_1' },
    { label: 'SwitchPageFragment_info_button_block_insta_reels' },
    { label: 'SwitchPageFragment_info_button_block_insta_search' },
    { label: 'SwitchPageFragment_info_button_block_snapchat_stories' },
    { label: 'SwitchPageFragment_info_button_block_yt_shorts' },
    { label: 'SwitchPageFragment_info_button_custom_message_on_bw_card' },
    {
        label: 'SwitchPageFragment_info_button_custom_timing_on_block_window'
    },
    { label: 'SwitchPageFragment_redirect_url_entered_back' },
    { label: 'SwitchPageFragment_redirect_url_entered_submit' },
    { label: 'SwitchPageFragment_redirect_url_submit' },
    { label: 'SwitchPageFragment_removeButton' },
    { label: 'SwitchPageFragment_remove_buddy_email' },
    { label: 'SwitchPageFragment_remove_buddy_long_sentence' },
    { label: 'SwitchPageFragment_remove_buddy_long_sentencechange_flow' },
    { label: 'SwitchPageFragment_remove_buddy_time_delay' },
    { label: 'SwitchPageFragment_remove_buddy_time_delaychange_flow' },
    { label: 'SwitchPageFragment_remove_long_sentence' },
    { label: 'SwitchPageFragment_remove_request_sent' },
    { label: 'SwitchPageFragment_schedule_time' },
    { label: 'SwitchPageFragment_screen_time_change_no_click' },
    { label: 'SwitchPageFragment_screen_time_change_yes_click' },
    { label: 'SwitchPageFragment_search_result_filter_false' },
    { label: 'SwitchPageFragment_search_result_filter_true' },
    { label: 'SwitchPageFragment_selected_time_24' },
    { label: 'SwitchPageFragment_selected_time_72' },
    { label: 'SwitchPageFragment_set_username_from_navigation_drawer' },
    {
        label: 'SwitchPageFragment_settings_from_switch_navigation_drawer'
    },
    {
        label: 'SwitchPageFragment_show_buddy_change_confirm_action_from_longsentences'
    },
    {
        label: 'SwitchPageFragment_show_buddy_change_confirm_action_from_timedelay'
    },
    {
        label: 'SwitchPageFragment_show_buddy_change_confirm_page_longsentences'
    },
    {
        label: 'SwitchPageFragment_show_buddy_change_confirm_page_timedelay'
    },
    { label: 'SwitchPageFragment_show_change_buddy_page_longsentences' },
    { label: 'SwitchPageFragment_show_change_buddy_page_sensor' },
    { label: 'SwitchPageFragment_show_change_buddy_page_timedelay' },
    { label: 'SwitchPageFragment_skip' },
    { label: 'SwitchPageFragment_snapchat_stories_false' },
    { label: 'SwitchPageFragment_snapchat_stories_true' },
    { label: 'SwitchPageFragment_social_media_blocking_all' },
    { label: 'SwitchPageFragment_social_media_blocking_info_close}' },
    { label: 'SwitchPageFragment_social_media_blocking_off}' },
    { label: 'SwitchPageFragment_social_media_blocking_reels' },
    { label: 'SwitchPageFragment_social_media_card_false' },
    { label: 'SwitchPageFragment_social_media_card_true' },
    { label: 'SwitchPageFragment_support_from_switch_navigation_drawer' },
    { label: 'SwitchPageFragment_ten_minute_time' },
    { label: 'SwitchPageFragment_time_delay_remove' },
    { label: 'SwitchPageFragment_unsupported_browser_false' },
    { label: 'SwitchPageFragment_unsupported_browser_switch_on' },
    { label: 'SwitchPageFragment_unsupported_browser_true' },
    { label: 'SwitchPageFragment_info_button_custom_wallpaper' },
    { label: 'SwitchPageFragment_info_button_gambling_card' },
    { label: 'SwitchPageFragment_info_button_redirect_url_card' },
    { label: 'SwitchPageFragment_info_button_social_media_card' },
    {
        label: 'SwitchPageFragment_info_button_switch_accountability_partner_card'
    },
    {
        label: 'SwitchPageFragment_info_button_switch_all_browser_block_card'
    },
    { label: 'SwitchPageFragment_info_button_switch_app_lock_card' },
    {
        label: 'SwitchPageFragment_info_button_switch_block_image_video_card'
    },
    { label: 'SwitchPageFragment_info_button_switch_heart_card' },
    {
        label: 'SwitchPageFragment_info_button_switch_in_app_browser_blocking'
    },
    {
        label: 'SwitchPageFragment_info_button_switch_prevent_uninstall_card'
    },
    {
        label: 'SwitchPageFragment_info_button_switch_search_result_filter_card'
    },
    {
        label: 'SwitchPageFragment_info_button_switch_unsupported_browser_card'
    },
    { label: 'SwitchPageFragment_info_button_switch_vpn_card' },
    { label: 'SwitchPageFragment_insta_reels_false' },
    { label: 'SwitchPageFragment_insta_reels_true' },
    { label: 'SwitchPageFragment_insta_search_false' },
    { label: 'SwitchPageFragment_insta_search_true' },
    { label: 'SwitchPageFragment_login_from_navigation_drawer' },
    { label: 'SwitchPageFragment_long_sentence_dialog_cancel' },
    { label: 'SwitchPageFragment_long_sentence_dialog_continue_fail' },
    { label: 'SwitchPageFragment_long_sentence_dialog_continue_success' },
    { label: 'SwitchPageFragment_next_1' },
    { label: 'SwitchPageFragment_next_2' },
    { label: 'SwitchPageFragment_next_3' },
    { label: 'SwitchPageFragment_next_4' },
    { label: 'SwitchPageFragment_next_5' },
    { label: 'SwitchPageFragment_next_6' },
    { label: 'SwitchPageFragment_next_7' },
    { label: 'SwitchPageFragment_no_vpn_safe_search_switch_on' },
    {
        label: 'SwitchPageFragment_notification_from_switch_navigation_drawer'
    },
    { label: 'SwitchPageFragment_null_clicked' },
    { label: 'SwitchPageFragment_open_premium_without_signin' },
    { label: 'SwitchPageFragment_open_zenze_link' },
    {
        label: 'SwitchPageFragment_other_blocking_from_switch_navigation_drawer'
    },
    { label: 'SwitchPageFragment_panicTimeActive' },
    { label: 'SwitchPageFragment_pattern_lock_switch_on' },
    { label: 'SwitchPageFragment_pending_request_cancel_dialog_no' },
    { label: 'SwitchPageFragment_pending_request_cancel_dialog_yes' },
    {
        label: 'SwitchPageFragment_pending_request_cancel_disable : add a buddy'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_disable : ***** blocker'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_disable : prevent uninstall'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_disable : search results filtering'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_disattivare : blocco del porno'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_inhabilitar : bloqueador de porno'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_lista blanca : facebook'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_remove blocking for : samsung internet'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_remove in-app browser blocking for : instagram'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_uitschakelen : blokkeren voor alle browsers (vpn)'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_uitschakelen : strikte modus'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_uitschakelen : voeg een vriend toe'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_verwijder in-app browserblokkering voor : basic-fit'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_отключить : блокировщик порно'
    },
    {
        label: 'SwitchPageFragment_pending_request_cancel_تعطيل : أضف صديقًا'
    },
    { label: 'SwitchPageFragment_pending_request_edit_click' },
    { label: 'SwitchPageFragment_pending_request_info_click' },
    { label: 'SwitchPageFragment_pending_request_info_close' },
    {
        label: 'SwitchPageFragment_pending_request_switch_prevent_uninstall_card'
    },
    {
        label: 'SwitchPageFragment_pending_request_switch_search_result_filter_card'
    },
    {
        label: 'SwitchPageFragment_pending_request_switch_unsupported_browser_card'
    },
    { label: 'SwitchPageFragment_porn_blocker_false' },
    { label: 'SwitchPageFragment_porn_blocker_info_close}' },
    { label: 'SwitchPageFragment_porn_blocker_switch_off}' },
    { label: 'SwitchPageFragment_porn_blocker_switch_on' },
    { label: 'SwitchPageFragment_porn_blocker_switch_strict' },
    { label: 'SwitchPageFragment_porn_blocker_true' },
    { label: 'SwitchPageFragment_premium_button_click' },
    { label: 'SwitchPageFragment_prevent_image_video_on' },
    { label: 'SwitchPageFragment_prevent_uninstall_false' },
    { label: 'SwitchPageFragment_prevent_uninstall_true' },
    { label: 'SwitchPageFragment_prevent_unistall_on' },
    { label: 'SwitchPageFragment_redirect_app_entered_submit' },
    { label: 'SwitchPageFragment_redirect_app_submit' },
    { label: 'SwitchPageFragment_redirect_url_back' },
    { label: 'SwitchPageFragment_redirect_url_card' },
    {
        label: 'SwitchPageFragment_upgrade_premium_from_switch_navigation_drawer'
    },
    {
        label: 'SwitchPageFragment_user_feedback_from_switch_navigation_drawer'
    },
    { label: 'SwitchPageFragment_vpn_card' },
    { label: 'SwitchPageFragment_vpn_message_entered_back' },
    { label: 'SwitchPageFragment_vpn_message_entered_submit' },
    { label: 'SwitchPageFragment_vpn_message_submit' },
    { label: 'SwitchPageFragment_vpn_normal_on' },
    { label: 'SwitchPageFragment_vpn_page_back' },
    { label: 'SwitchPageFragment_vpn_strict_on' },
    { label: 'SwitchPageFragment_vpn_switch_off' },
    { label: 'SwitchPageFragment_vpn_switch_on' },
    { label: 'SwitchPageFragment_website_strict_mode_false' },
    { label: 'SwitchPageFragment_website_strict_mode_true' },
    { label: 'SwitchPageFragment_yt_short_false' },
    { label: 'SwitchPageFragment_yt_short_true' },
    { label: 'SwitchPageInstantAPApprovalDialog_LongSentenceDialog' },
    { label: 'SwitchPageInstantAPApprovalDialog_SensorBuddyDialog' },
    { label: 'ZenzePromotionFragment' }
]
exports.WidgetAutocomplete = [
    { label: 'AddWidgetGuideDialog' },
    { label: 'BlockMeScheduleTimeFragment' },
    { label: 'ChatBotWidget_chatbot_widget_add' },
    { label: 'ChatBotWidget_chatbot_widget_click' },
    { label: 'ChatBotWidget_chatbot_widget_remove' },
    { label: 'CommonUtils_widget_guide_add_automatic_show' },
    { label: 'CommonUtils_widget_guide_add_dialog_show' },
    { label: 'HelpMeAppWidget_StartTimer' },
    { label: 'HelpMeAppWidget_widget_add' },
    { label: 'HelpMeAppWidget_widget_click' },
    { label: 'HelpMeAppWidget_widget_remove' },
    { label: 'HelpMeFlotingWidgetActivity' },
    { label: 'HelpMeFlotingWidgetActivity_help_me_custom_time_submit' },
    { label: 'HelpMeFlotingWidgetActivity_help_me_time_1' },
    { label: 'HelpMeFlotingWidgetActivity_help_me_time_custom_time' },
    { label: 'HelpMeTimeSelectFragment_help_me_schedule' },
    {
        label: 'StreakInfoFragment_switch_on_days_widget_guide_add_button'
    },
    { label: 'SwitchOnDaysAppWidget_widget_add' },
    { label: 'SwitchOnDaysAppWidget_widget_click' },
    { label: 'SwitchOnDaysAppWidget_widget_remove' },
    { label: 'SwitchPageFragment_help_me_time_1' },
    { label: 'SwitchPageFragment_help_me_time_custom_time' }
]
exports.courseFeedbackAutocomplete = [{ label: 'true' }]
