import secureLocalStorage from 'react-secure-storage'
import './UnsubscriberMail.css'
import * as React from 'react'
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import { Autocomplete, Avatar, Button, Card, CardContent, CardHeader, Grid, Input, Snackbar, TextField, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import DeleteIcon from '@mui/icons-material/Delete'

import MuiAlert from '@mui/material/Alert'

import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const datasetType = [
    {
        label: 50
    },
    {
        label: 350
    },
    {
        label: 1500
    },
    {
        label: 1550
    },
    {
        label: 18250
    }
]

const UnsubscriberMail = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [coins, setCoins] = useState(50)
    const [inputUid, setInputUid] = useState('')
    const [email, setEmail] = useState('')
    const [template, setTemplate] = useState('cs_dashboard')

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setEmail('')
    }

    const handleUnsubscribeUser = () => {

        if (email === '') {
            setOpenErrorAlert(true)
            return
        }
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        }

        fetch(`https://dev.blockerx.net/api/unsubscribe/mails?email=${email}&template=${template}`, requestOptions)
            .then((data) => {
                setApiCalled(true)
                setOpenSuccessAlert(true)
                resetAllFields()
            })
            .catch(() => {
                setOpenErrorAlert(true)
            })
    }

    return (
        <>
            <h1 className='text-5xl text-center my-10'>Unsubscribe Promotional mails</h1>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Enter email'
                        value={email}
                        sx={{ width: '400px' }}
                        onChange={(event, item) => {
                            setEmail(event.target.value)
                        }}
                    />
                </Grid>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Enter template'
                        value={template}
                        sx={{ width: '400px' }}
                        onChange={(event, item) => {
                            setTemplate(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Button className='h-12' sx={{ width: '400px' }} onClick={handleUnsubscribeUser} variant='contained'>
                        Unsubscribe
                    </Button>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                            Success!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                            Error!!!
                        </Alert>
                    </Snackbar>
                </Stack>
            </Grid>
        </>
    )
}

export default UnsubscriberMail
