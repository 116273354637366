import React, { useState, useEffect } from 'react';
import secureLocalStorage from 'react-secure-storage';
import { Button, Grid, Input, Switch } from '@mui/material';
import { Box } from '@mui/system';
import { useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EVENTS_BASE_URL } from '../../api/Constants';

const GET_ENDPOINTS = {
    userBlocked: `${EVENTS_BASE_URL}/api/restictions/v1/apk-update`,
    liveBlocking: `${EVENTS_BASE_URL}/api/restictions/v1/live-blocking`,
    dailyReport: `${EVENTS_BASE_URL}/api/restictions/v1/daily-report`
};

const EDIT_ENDPOINTS = {
    userBlocked: `${EVENTS_BASE_URL}/api/restictions/v1/apk-update-toggle`,
    liveBlocking: `${EVENTS_BASE_URL}/api/restictions/v1/live-blocking-toggle`,
    dailyReport: `${EVENTS_BASE_URL}/api/restictions/v1/daily-report-toggle`
};

const CommonRestriction = () => {
    const [inputUid, setInputUid] = useState('');
    const [userBlocked, setUserBlocked] = useState(false);
    const [liveBlocking, setLiveBlocking] = useState(false);
    const [dailyReport, setDailyReport] = useState(false);
    const [apiCalled, setApiCalled] = useState(false)

    const token = secureLocalStorage.getItem('token');

    const fetchStatus = async (setting, uid) => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` }
        };
        try {
            const response = await fetch(`${GET_ENDPOINTS[setting]}?uid=${uid}`, requestOptions);
            const data = await response.json();
            if (response.ok) {
                switch (setting) {
                    case 'userBlocked':
                        setUserBlocked(data.status);
                        break;
                    case 'liveBlocking':
                        setLiveBlocking(data.status);
                        break;
                    case 'dailyReport':
                        setDailyReport(data.status);
                        break;
                    default:
                        break;
                }
            }
        } catch (error) {
            console.error('Fetch Status Error:', error);
            toast.error(`An error occurred while checking the status for ${setting}.`, {
                position: 'bottom-left'
            });
        } finally {
            setApiCalled(true)
        }
    };

    const updateStatus = async (setting, status) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: inputUid, status })
        };

        try {
            const response = await fetch(`${EDIT_ENDPOINTS[setting]}`, requestOptions);
            const data = await response.json();
            console.log('Data : ', data)
            if (response.ok) {
                toast.success(`${data.data.info}`, {
                    position: 'bottom-left'
                });
                switch (setting) {
                    case 'userBlocked':
                        setUserBlocked(status);
                        break;
                    case 'liveBlocking':
                        setLiveBlocking(status);
                        break;
                    case 'dailyReport':
                        setDailyReport(status);
                        break;
                    default:
                        break;
                }
            } else {
                toast.error(`An error occurred while updating ${setting}.`, {
                    position: 'bottom-left'
                });
            }
        } catch (error) {
            console.error('Update Status Error:', error);
            toast.error(`An error occurred while updating ${setting}.`, {
                position: 'bottom-left'
            });
        }
    };

    const location = useLocation();
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const uid = queryParams.get('uid');
        if (uid) {
            setInputUid(uid);
            Object.keys(GET_ENDPOINTS).forEach(setting => fetchStatus(setting, uid));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);

    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
            <Grid container spacing={2} sx={{ textAlign: 'left', maxWidth: '600px' }}>
                <Grid item xs={12}>
                    <h1 className='text-3xl font-bold uppercase text-gray-700 my-4'>User Restrictions</h1>
                </Grid>

                <Grid item xs={12}>
                    <Input
                        disablePortal
                        placeholder='Enter UID'
                        id='combo-box-demo'
                        value={inputUid}
                        sx={{ width: '100%' }}
                        onChange={(event) => setInputUid(event.target.value)}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Button variant='contained' sx={{ width: '100%' }} color='info' onClick={() => {
                        Object.keys(GET_ENDPOINTS).forEach(setting => fetchStatus(setting, inputUid));
                    }}>
                        Check Status
                    </Button>
                </Grid>

                {
                    apiCalled && <>
                        <Grid item xs={12}>
                            <hr className='my-4' />
                            <h1 className='text-2xl text-left font-bold uppercase text-gray-700 my-4'>APK Update</h1>
                            <h2>{userBlocked ? 'APK Updates STOPPED for user' : 'User receives APK updates'}</h2>
                            <Switch checked={userBlocked} onChange={() => updateStatus('userBlocked', !userBlocked)} />
                            <hr className='my-4' />
                        </Grid>
                        <Grid item xs={12}>
                            <h1 className='text-2xl text-left font-bold uppercase text-gray-700 my-4'>Live Blocking</h1>
                            <h2>{liveBlocking ? 'Friend will NOT receive live blocking mails' : 'Friends receives live blocking mails'}</h2>
                            <Switch checked={liveBlocking} onChange={() => updateStatus('liveBlocking', !liveBlocking)} />
                            <hr className='my-4' />
                        </Grid>
                        <Grid item xs={12}>
                            <h1 className='text-2xl text-left font-bold uppercase text-gray-700 my-4'>Daily Report</h1>
                            <h2>{dailyReport ? 'Friend will NOT receive daily report' : 'Friend receives daily report'}</h2>
                            <Switch checked={dailyReport} onChange={() => updateStatus('dailyReport', !dailyReport)} />
                            <hr className='my-4' />
                        </Grid>
                    </>
                }

            </Grid>

            <ToastContainer />
        </Box>
    );
};

export default CommonRestriction;
