import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider } from 'firebase/auth'
const firebaseConfig = {
    apiKey: "AIzaSyDWtgAfwthYlm40nGpyxR12f4MN6PrzZ9w",
    authDomain: "blockerx-ce69e.firebaseapp.com",
    databaseURL: "https://blockerx-ce69e.firebaseio.com",
    projectId: "blockerx-ce69e",
    storageBucket: "blockerx-ce69e.appspot.com",
    messagingSenderId: "871516463090",
    appId: "1:871516463090:web:7492dae1d1d0f62648d56b",
    measurementId: "G-21NMHQWLGS"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();

export default auth;

export { provider };