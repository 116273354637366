import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import { useState } from 'react';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import Tab from '@mui/material/Tab';
import MuiAlert from '@mui/material/Alert';
import { Autocomplete, Avatar, Button, Card, CardContent, CardHeader, Grid, Input, Snackbar, Stack, TextField, Typography } from '@mui/material';

import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { useEffect } from 'react';
import ReactGA from "react-ga";
import { useNavigate } from 'react-router-dom';
import { red } from '@mui/material/colors';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`vertical-tabpanel-${index}`}
            aria-labelledby={`vertical-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Grid align="center" justify="center" spacing={2}>
                        <Typography>{children}</Typography>
                    </Grid>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function CenteredTabs() {

    const token = secureLocalStorage.getItem("token");
    let navigate = useNavigate();

    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const [userInfoEmail, setUserInfoEmail] = useState("");
    const [userInfoUid, setUserInfoUid] = useState("");
    const [createUserEmail, setCreateUserEmail] = useState("");
    const [createUserPassword, setCreateUserPassword] = useState("");

    const [deleteUserEmail, setDeleteUserEmail] = useState("");
    const [deleteUserUid, setDeleteUserUid] = useState("");
    const [apTypePreferenceUid, setApTypePreferenceUid] = useState("");
    const [apTypePreferenceType, setApTypePreferenceType] = useState("");
    const [removePartnerUid, setRemovePartnerUid] = useState("");

    const [clearDataUid, setClearDataUid] = useState("");
    const [clearDataReason, setClearDataReason] = useState("");

    const [inputPasswordType, setInputPasswordType] = useState("password");
    const [showPasswordButtonText, setShowPasswordButtonText] = useState("Show");

    // Disabling button
    const [disabled, setDisabled] = useState(false);
    const [deleteButtonDisabled, setDeleteButtonDisabled] = useState(true);
    const [userValiditiyButtonDisabled, setUserValidityButtonDisabled] = useState(false);

    // Alert

    // Valid User Alert
    const [openValidUserAlert, setOpenValidUserAlert] = useState(false);
    const closeValidUserAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenValidUserAlert(false);
    };

    // User already present in firebase
    const [openUserAlreadyPresentAlert, setOpenUserAlreadyPresentAlert] = useState(false);
    const closeUserAlreadyPresentAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenUserAlreadyPresentAlert(false);
    }

    // User has been created
    const [openUserCreatedAlert, setOpenUserCreatedAlert] = useState(false);
    const closeUserCreatedAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenUserCreatedAlert(false);
    }

    // Invalid User
    const [openInvalidUserAlert, setOpenInvalidUserAlert] = useState(false);
    const closeInvalidUserAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenInvalidUserAlert(false);
    }

    // User Deleted
    const [openDeletedUserAlert, setOpenDeletedUserAlert] = useState(false);
    const closeDeletedUserAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenDeletedUserAlert(false);
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    // Uid copied to clipboard
    const [openCopiedToClipboard, setOpenCopiedToClipboard] = useState(false);
    const closeCopiedToClipboard = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenCopiedToClipboard(false);
    }


    const showPasswordHandler = () => {
        if (inputPasswordType === "password") {
            setInputPasswordType("text");
            setShowPasswordButtonText("Hide");
        } else {
            setInputPasswordType("password");
            setShowPasswordButtonText("Show");
        }
    }

    const createFirebaseUserHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "email": createUserEmail, "password": createUserPassword, "dashboardUid": secureLocalStorage.getItem("uid") })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/createUser', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setOpenUserCreatedAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenUserAlreadyPresentAlert(true);
                }
            });
    }

    const checkUserValidityHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "email": deleteUserEmail, "dashboardUid": secureLocalStorage.getItem("uid") })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/userInfo', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setDeleteUserUid(data.data.uid);
                    setDeleteButtonDisabled(false);
                    setUserValidityButtonDisabled(true);
                    setOpenValidUserAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenInvalidUserAlert(true);
                }
            });
    }

    const deleteFirebaseUserHandler = () => {

        if (userValiditiyButtonDisabled === false) {
            setOpenInvalidUserAlert(true);
            return;
        }

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": deleteUserUid, "email": deleteUserEmail, "dashboardUid": secureLocalStorage.getItem("uid")})
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/deleteUser', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setOpenDeletedUserAlert(true);
                    setDeleteUserEmail("");
                    setDeleteButtonDisabled(true);
                    setUserValidityButtonDisabled(false);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenInvalidUserAlert(true);
                    setDeleteUserEmail("");
                    setDeleteButtonDisabled(true);
                    setUserValidityButtonDisabled(false);
                }
            });
    }

    const getFirebaseUserInfoHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "email": userInfoEmail, "dashboardUid": secureLocalStorage.getItem("uid") })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/userInfo', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    // Show user info - uid
                    setUserInfoUid(data.data.uid);
                    setOpenValidUserAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenInvalidUserAlert(true);
                }
            });
    }

    const changeApTypePreferenceHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": apTypePreferenceUid, "apTypePreference": apTypePreferenceType, "dashboardUid": secureLocalStorage.getItem("uid") })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/db/setApTypePreference', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setApTypePreferenceType("");
                    setApTypePreferenceUid("");
                    setOpenSuccessAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenErrorAlert(true);
                    setApTypePreferenceType("");
                    setApTypePreferenceUid("");
                }
            });
    }

    const removePartnerFirebaseHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": removePartnerUid, "dashboardUid": secureLocalStorage.getItem("uid")  })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/db/removePartner', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setRemovePartnerUid("");
                    setOpenSuccessAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenErrorAlert(true);
                    setRemovePartnerUid("");
                }
            });
    }

    const clearDataHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "uid": clearDataUid, "reason": clearDataReason, "dashboardUid": secureLocalStorage.getItem("uid") })
        };

        fetch('https://healify.blockerx.net/api/dashboard/firebase/db/clearUserData', requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === "success") {
                    setClearDataReason("");
                    setClearDataUid("");
                    setOpenSuccessAlert(true);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else {
                    setOpenErrorAlert(true);
                    setApTypePreferenceType("");
                    setApTypePreferenceUid("");
                }
            });
    }

    const copyUidHandler = () => {
        navigator.clipboard.writeText(userInfoUid);
        setOpenCopiedToClipboard(true);
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);


    return (
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
            <Tabs value={value} onChange={handleChange} centered>
                <Tab label="User Info" />
                <Tab label="Create User" />
                <Tab label="Delete User" />
                <Tab label="AP Type Preference" />
                <Tab label="Remove Partner" />
                <Tab label="Clear Data" />
            </Tabs>

            <TabPanel value={value} index={0}>
                <Box sx={{ flexGrow: 2 }} >
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <h1 className="header-top mt-5">Get Firebase User Info</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter email"
                                id="combo-box-demo"
                                sx={{ width: 400 }}
                                onChange={(event, item) => {
                                    setUserInfoEmail(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                sx={{ width: 400 }}
                                // disabled = {disabled}
                                onClick={getFirebaseUserInfoHandler}>
                                Get Info
                            </Button>
                        </Grid>

                        {userInfoUid === "" ? <></> :
                            <Grid item lg={4} md={6} sm={12} className="pt-5">
                                <Grid item lg={4} md={6} sm={12} className="pt-10">
                                    <Card sx={{ width: 400 }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                    {userInfoEmail ? userInfoEmail[0] : ""}
                                                </Avatar>
                                            }
                                            title={"Email : " + userInfoEmail ? userInfoEmail : ""}
                                            align="left"
                                        // subheader="Last Signed In : "
                                        />
                                        <CardContent>
                                            <Typography variant="body2" color="text.secondary">
                                                {userInfoUid} <ContentCopyIcon className='copy--icon cursor-pointer' onClick={copyUidHandler}/>
                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            </Grid>}
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={value} index={1}>
                <Box sx={{ flexGrow: 2 }} >
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <h1 className="header-top mt-5">Create Firebase User</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter email"
                                id="combo-box-demo"
                                autoComplete='off'
                                sx={{ width: 400 }}
                                onChange={(event, item) => {
                                    setCreateUserEmail(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                type={inputPasswordType}
                                autoComplete='off'
                                placeholder="Enter password"
                                id="combo-box-demo"
                                sx={{ width: 300 }}
                                onChange={(event, item) => {
                                    setCreateUserPassword(event.target.value);
                                }}
                            />
                            <Button
                                className="h-8"
                                variant="contained"
                                sx={{ width: 80, marginX: "10px", backgroundColor: "gray" }}
                                onClick={showPasswordHandler}>
                                {showPasswordButtonText}
                            </Button>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                sx={{ width: 400 }}
                                onClick={createFirebaseUserHandler}>
                                Create User
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={value} index={2}>
                <Box sx={{ flexGrow: 2 }} >
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <h1 className="header-top mt-5">Delete Firebase User</h1>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter email"
                                id="combo-box-demo"
                                sx={{ width: 400 }}
                                value={deleteUserEmail}
                                onChange={(event, item) => {
                                    setDeleteUserEmail(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                sx={{ width: 400 }}
                                disabled={userValiditiyButtonDisabled}
                                onClick={checkUserValidityHandler}>
                                Check User Validity
                            </Button>
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                color='error'
                                disabled={deleteButtonDisabled}
                                sx={{ width: 400 }}
                                onClick={deleteFirebaseUserHandler}>
                                Delete User
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={value} index={3}>
                <Box sx={{ flexGrow: 2 }}>
                    <h1 className="header-top mt-5">Set ApTypePreference</h1>
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter uid"
                                id="combo-box-demo"
                                value={apTypePreferenceUid}
                                sx={{ width: 300 }}
                                onChange={(event, item) => {
                                    setApTypePreferenceUid(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={apTypeOptions}
                                value={apTypePreferenceType}
                                sx={{ width: 300 }}
                                onChange={(event, item) => {
                                    setApTypePreferenceType(item.label)
                                }}
                                renderInput={(params) => <TextField {...params} label="Choose Type" />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            {/* <p> {planName} </p> */}
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                disabled={disabled}
                                sx={{ width: 300 }}
                                onClick={changeApTypePreferenceHandler}>
                                Change AP Type Reference
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={value} index={4}>
            <Box sx={{ flexGrow: 2 }}>
                    <h1 className="header-top mt-5">Remove Firebase Partner</h1>
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter uid"
                                id="combo-box-demo"
                                value={removePartnerUid}
                                sx={{ width: 300 }}
                                onChange={(event, item) => {
                                    setRemovePartnerUid(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                color='error'
                                disabled={disabled}
                                sx={{ width: 300 }}
                                onClick={removePartnerFirebaseHandler}>
                                Remove Partner
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            <TabPanel value={value} index={5}>
                <Box sx={{ flexGrow: 2 }}>
                    <h1 className="header-top mt-5">Clear Data</h1>
                    <Grid align="center" justify="center" spacing={2}>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Input
                                disablePortal
                                placeholder="Enter uid"
                                id="combo-box-demo"
                                value={clearDataUid}
                                sx={{ width: 300 }}
                                onChange={(event, item) => {
                                    setClearDataUid(event.target.value);
                                }}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Autocomplete
                                disablePortal
                                id="combo-box-demo"
                                options={reasonList}
                                value={clearDataReason}
                                sx={{ width: 300 }}
                                onChange={(event, item) => {
                                    setClearDataReason(item.label);
                                }}
                                renderInput={(params) => <TextField {...params} label="Choose Reason" />}
                            />
                        </Grid>

                        <Grid item lg={4} md={6} sm={12} className="pt-5">
                            <Button
                                className="h-12"
                                variant="contained"
                                disabled={disabled}
                                color='error'
                                sx={{ width: 300 }}
                                onClick={clearDataHandler}>
                                Clear Data
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </TabPanel>

            {/* Alerts  */}

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openValidUserAlert} autoHideDuration={2000} onClose={closeValidUserAlert}>
                    <Alert onClose={closeValidUserAlert} severity="success" sx={{ width: '100%' }}>
                        User is valid!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openUserCreatedAlert} autoHideDuration={2000} onClose={closeUserCreatedAlert}>
                    <Alert onClose={closeUserCreatedAlert} severity="success" sx={{ width: '100%' }}>
                        User has been created!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openUserAlreadyPresentAlert} autoHideDuration={2000} onClose={closeUserAlreadyPresentAlert}>
                    <Alert onClose={closeUserAlreadyPresentAlert} severity="warning" sx={{ width: '100%' }}>
                        User already present!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openInvalidUserAlert} autoHideDuration={2000} onClose={closeInvalidUserAlert}>
                    <Alert onClose={closeInvalidUserAlert} severity="error" sx={{ width: '100%' }}>
                        User is invalid!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openDeletedUserAlert} autoHideDuration={2000} onClose={closeDeletedUserAlert}>
                    <Alert onClose={closeDeletedUserAlert} severity="success" sx={{ width: '100%' }}>
                        User has been deleted!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openCopiedToClipboard} autoHideDuration={2000} onClose={closeCopiedToClipboard}>
                    <Alert onClose={closeCopiedToClipboard} severity="success" sx={{ width: '100%' }}>
                        The uid has been copied to clipboard.
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    );
}

const apTypeOptions = [
    {
        label: 'false'
    },
    {
        label: 'true'
    },
    {
        label: 'other'
    }
];

const reasonList = [
    {
        label: 'Requested by user'
    },
    {
        label: 'Other'
    }
];