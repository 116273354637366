import * as React from 'react'
import { useEffect } from 'react'
import { Grid, Snackbar, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import { showTimeInAgoFormat } from '../../../Utils/time'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const ZenzeApiLogs = () => {
    const [apiCalled, setApiCalled] = useState(false)
    const [blockedDomains, setBlockedDomains] = useState([])

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetAllFields = () => {
        setApiCalled(false)
    }

    const getRequestLogs = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://zenze.blockerx.net/api/request/logs', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setApiCalled(true)
                    const array = data.data.logs
                    const sortedLogs = array.sort((a, b) => b.lastCall - a.lastCall)
                    setBlockedDomains(sortedLogs)
                } else {
                    setOpenErrorAlert(true)
                    resetAllFields()
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
                setOpenErrorAlert(true)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getRequestLogs()
        }
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='py-5'>
                    <h2 class='text-4xl font-extrabold py-2 text-gray-600'>API Requests Logs</h2>
                </Grid>

                {apiCalled === true ? (
                    <>
                        <Table sx={{ width: '95%', marginBottom: 30 }} aria-label='customized table'>
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Request URL</StyledTableCell>
                                    <StyledTableCell>Method</StyledTableCell>
                                    <StyledTableCell width={'8%'}>Count</StyledTableCell>
                                    <StyledTableCell width={'8%'}>Successes</StyledTableCell>
                                    <StyledTableCell width={'8%'}>Redirects</StyledTableCell>
                                    <StyledTableCell width={'8%'}>Errors</StyledTableCell>
                                    <StyledTableCell>Last Call</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            {blockedDomains.map((eachURL) => {
                                return (
                                    <TableBody sx={{border: "1px solid #dbdbdb"}}>
                                        <StyledTableRow key={eachURL._id}>
                                            <StyledTableCell component='th' scope='row'>
                                                {eachURL.url || '/'}
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>
                                                <p className={`font-bold ${eachURL.method === 'GET' ? 'text-green-600' : eachURL.method === 'POST' ? 'text-orange-500' : eachURL.method === 'DELETE' ? 'text-red-500' : 'text-blue-500'}`}>{eachURL.method}</p>
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>{eachURL.count}</StyledTableCell>
                                            <StyledTableCell align='left'>{eachURL.successCount}</StyledTableCell>
                                            <StyledTableCell align='left'>{eachURL.redirectCount}</StyledTableCell>
                                            <StyledTableCell align='left'>{eachURL.errorCount}</StyledTableCell>
                                            <StyledTableCell align='left'>{showTimeInAgoFormat(eachURL.lastCall)}</StyledTableCell>
                                        </StyledTableRow>
                                    </TableBody>
                                )
                            })}
                        </Table>
                    </>
                ) : (
                    ''
                )}
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        {successMessage}
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default ZenzeApiLogs
