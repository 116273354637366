import React from 'react'
import Chip from '@mui/material/Chip'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import Typography from '@mui/material/Typography'
import { CardActionArea } from '@mui/material'
import './Feedback.css'

function Feedback() {
    return (
        <div className='feedback__container'>
            <Card sx={{ width: '80%', backgroundColor: 'red' }}>
                <CardActionArea>
                    <CardContent>
                        <Typography gutterBottom variant='h6' component='div'>
                            Analysis of Zenze Cron Job
                            <Chip label='Analysis' sx={{ marginLeft: '15px', marginBottom: '4px', fontSize: '12px' }} color='info' />
                        </Typography>
                        <Typography variant='body2' color='text.secondary'>
                            Analysis of Zenze Cron Job for automatic post uploading
                        </Typography>
                        <hr className='my-2' />
                        <Typography variant='body2' color='text.secondary'>
                            Kiranpal Singh <span className='mx-2'>•</span> <span className='font-bold'>{ '3 days left'}</span> <span className='mx-2'>•</span> {'27 Jan, 2024'}
                        </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        </div>
    )
}

export default Feedback
