import secureLocalStorage from 'react-secure-storage'
import React, { useEffect, useState } from 'react'
import './ViewClosedTickets.css'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Box, CircularProgress, Grid, Pagination, Stack } from '@mui/material'
import { showTimeInAgoFormat } from '../../../Utils/time'
import appleIcon from '../../../assets/images/apple.png'
import androidIcon from '../../../assets/images/android-seeklogo.com.svg'
import windowsIcon from '../../../assets/images/windows.png'

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const showTicketStatus = (text) => {
  const words = text.split("_")
  let result = ''
  words.forEach((word) => {
    result += capitalizeFirstLetter(word) + ' '
  })
  return result
}

function ViewClosedTickets() {

  const [ticketsList, setTicketsList] = useState([])
  const [filterTicketsList, setFilterTicketsList] = useState([])
  const [apiCalled, setApiCalled] = useState(false)
  const [totalTicketsCount, setTotalTicketsCount] = useState(0)
  const [currentPageNo, setCurrentPageNo] = useState(1)
  const [searchParams, setSearchParams] = useSearchParams()
  const dashboardUid = secureLocalStorage.getItem("uid");

  const navigate = useNavigate()

  const openEachTicketContainer = (ticketId) => {
    navigate(`/customer-tickets/${ticketId}`)
  }

  const getClosedTickets = (PAGE) => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        "dashboardUid": dashboardUid,
        "page": PAGE
      })
    };

    fetch(`https://dev.blockerx.net/api/tickets/closed`, requestOptions)
      .then(response => response.json())
      .then((data) => {
        if (data.status === 200) {
          const ticket_list = data.data.list
          setTotalTicketsCount(data.data.count)
          setTicketsList(ticket_list)
          setFilterTicketsList(ticket_list)
          setApiCalled(true)
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
          });
        } else {
        }
      })
      .catch((error) => {
      })
  }


  const handlePagination = (event, value) => {
    setCurrentPageNo(value);
    setSearchParams({ 'page': value })
    getClosedTickets(value)
  }

  useEffect(() => {
    if (!apiCalled) {
      getClosedTickets(1)
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className='text-6xl font-bold text-center mt-10'> Closed Tickets - {totalTicketsCount}</div>
      <div className='text-center text-2xl mt-5'>Showing page {currentPageNo} of {Math.ceil(totalTicketsCount / 20)}</div>
      <div className='all__tickets__container'>
        {
          filterTicketsList.length > 0 && filterTicketsList.map((ticket) => {
            return (
              <div class="tickets__card__container" onClick={() => openEachTicketContainer(ticket._id)}>
                <article class="each__ticket__container">
                  <div>
                    <h2 className='font-bold mb-1'> {'Ticket - ' + ticket._id} </h2>
                    {
                      ticket.platform === 'apple' && <img className='device__icon mb-4' src={appleIcon} alt="" />
                    }
                    {
                      ticket.platform === 'android' && <img className='device__icon mb-4' src={androidIcon} alt="" />
                    }
                    {
                      ticket.platform === 'windows' && <img className='device__icon mb-4' src={windowsIcon} alt="" />
                    }
                    <h2> {ticket.description}</h2>
                  </div>
                  <div class="card-footer-snippets">
                    <div class="card-meta-snippets">
                      Created: {showTimeInAgoFormat(ticket.time)}
                    </div>
                    <div class="card-meta-snippets">
                      User Response: {showTimeInAgoFormat(ticket.updatedAt)}
                    </div>
                    <div className="card-meta-snippets text-red-700 font-bold">
                      Closed: {showTimeInAgoFormat(ticket.respondedAt)}
                    </div>
                    <div class="card-meta-snippets">
                      Status: {showTicketStatus(ticket.status)}
                    </div>
                    <div className="card-meta-snippets font-bold">
                      Agent: {capitalizeFirstLetter(ticket.assigned)}
                    </div>
                  </div>
                </article>
              </div>
            )
          })
        }
        {
          ticketsList.length === 0 && <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '100px' }}>
            <CircularProgress size="7rem" color="grey" />
          </Box>
        }
        {
          filterTicketsList.length > 0 && <Stack spacing={2} align="center">
            <Grid container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ marginY: "50px" }} >
              <Pagination className='' count={Math.ceil(totalTicketsCount / 20)} color="primary" onChange={handlePagination} />
            </Grid>
          </Stack>
        }
      </div>
    </>
  )
}

export default ViewClosedTickets

