import secureLocalStorage from 'react-secure-storage'
import React, { useState } from 'react'

import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'
import Button from '@mui/material/Button'
import Snackbar from '@mui/material/Snackbar'
import Stack from '@mui/material/Stack'
import MuiAlert from '@mui/material/Alert'
import { Avatar, Card, CardContent, CardHeader, Input, Typography } from '@mui/material'
import { red } from '@mui/material/colors'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'
import FormLabel from '@mui/material/FormLabel'
import FormControl from '@mui/material/FormControl'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import FormHelperText from '@mui/material/FormHelperText'
import Checkbox from '@mui/material/Checkbox'

import { useEffect } from 'react'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const Migrate = () => {
    const [disabled, setDisabled] = useState(false)
    const [premiumDisabled, setPremiumDisabled] = useState(false)
    let navigate = useNavigate()

    const [searchType, setSearchType] = useState('null')
    const [userValue, setUserValue] = useState('null')
    const [apiCalled, setApiCalled] = useState(false)
    const [newUid, setNewUid] = useState('')
    const [oldUid, setOldUid] = useState('')

    const [uid, setUid] = useState('null')
    const [userPresent, setUserPresent] = useState(false)

    const [state, setState] = React.useState({
        premium: false,
        blocklist: false
    })
    const { premium, blocklist } = state

    const handleChange = (event) => {
        setState({
            ...state,
            [event.target.name]: event.target.checked
        })
    }
    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    // Uid copied to clipboard
    const [openCopiedToClipboard, setOpenCopiedToClipboard] = useState(false)
    const closeCopiedToClipboard = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenCopiedToClipboard(false)
    }

    const token = secureLocalStorage.getItem('token')

    const resetInputFields = () => {
        setOldUid('')
        setNewUid('')
        setState({
            premium: false,
            blocklist: false
        })
    }

    const migrateUserData = () => {
        const selectedTypes = Object.keys(state).filter((type) => state[type])
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ uid: newUid, oldUid: oldUid, types: selectedTypes, dashboardUid: secureLocalStorage.getItem('uid') })
        }
        console.log('Request options : ', requestOptions)
        fetch('http://dev.blockerx.net/api/dashboard/user/migrate', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    setApiCalled(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                    setApiCalled(false)
                }
            })
            .catch((error) => {
                setOpenErrorAlert(true)
                setApiCalled(false)
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    const copyUidHandler = () => {
        navigator.clipboard.writeText(uid)
        setOpenCopiedToClipboard(true)
    }

    return (
        <>
            <Box sx={{ flexGrow: 2 }}>
                <h1 className='header-top mt-8'>
                    <Typography variant='h3' gutterBottom>
                        Migrate User Data
                    </Typography>
                </h1>
                <Grid align='center' justify='center' spacing={2}>
                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <TextField
                            disablePortal
                            label='Old User ID'
                            value={oldUid}
                            id='combo-box-demo'
                            sx={{ width: 450 }}
                            onChange={(event, item) => {
                                setOldUid(event.target.value)
                                setApiCalled(false)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <TextField
                            disablePortal
                            label='New User ID'
                            value={newUid}
                            id='combo-box-demo'
                            sx={{ width: 450 }}
                            onChange={(event, item) => {
                                setNewUid(event.target.value)
                                setApiCalled(false)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <FormControl sx={{ width: 450 }} component='fieldset' variant='standard'>
                            <FormLabel component='legend'>What do you want to migrate?</FormLabel>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox checked={premium} onChange={handleChange} name='premium' />} label='Premium Data' />
                                <FormControlLabel control={<Checkbox checked={blocklist} onChange={handleChange} name='blocklist' />} label='Block List' />
                            </FormGroup>
                        </FormControl>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Button className='h-12' variant='contained' sx={{ width: 450 }} disabled={premiumDisabled} onClick={() => migrateUserData()}>
                            Migrate
                        </Button>
                    </Grid>
                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                            Success!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                            Error!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openCopiedToClipboard} autoHideDuration={2000} onClose={closeCopiedToClipboard}>
                        <Alert onClose={closeCopiedToClipboard} severity='success' sx={{ width: '100%' }}>
                            The uid has been copied to clipboard.
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </>
    )
}

const methodList = [
    {
        label: 'Premium',
        type: 'premium'
    },
    {
        label: 'Blocklist',
        type: 'blocklist'
    },
    {
        label: 'Feed Posts',
        type: 'feedPosts'
    }
]

export default Migrate
