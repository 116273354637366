exports.capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1)
}

exports.isValidEmail = (email) => {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
    return emailPattern.test(email)
}

exports.getBooleanString = (value) => {
    if (value) {
        return 'Yes'
    }
    return 'No'
}

exports.serializeUnderscoreString = (str) => {
    const formattedString = str.replace(/_/g, ' ').replace(/\b\w/g, (match) => match.toUpperCase())
    return formattedString
}

exports.transformSwitchName = (str) => {
    const transformations = {
        swUnSupportedBrowsers: 'Unsupported Browser',
        swPreventUninstall: 'Prevent Uninstall',
        swShoppingAppWebFb: 'Shopping App Web FB',
        swHeart: 'Heart Switch',
        swWebsiteStrictMode: 'Website Strict Mode',
        swSnapchatStories: 'Snapchat Stories',
        swVpn: 'VPN Switch',
        swBlockNotificationArea: 'Block Notification Area',
        SwBlockAllBrowser: 'Block All Browsers'
    }

    if (str in transformations) {
        return transformations[str]
    } else {
        return this.serializeUnderscoreString(str)
    }
}
