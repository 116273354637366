import secureLocalStorage from 'react-secure-storage'
import './TicketLogs.css'
import React, { useEffect, useState } from 'react'
import { BarChart } from '@mui/x-charts/BarChart'
import { getDayMonthFromMs } from '../../../Utils/time'

function TicketLogs() {
    const [apiCalled, setApiCalled] = useState(false)
    const [labelArray, setLabelArray] = useState([])
    const [countArray, setCountArray] = useState([])
    const [ticketsCountLog, setTicketsCountLog] = useState([])
    const [currentAgentId, setCurrentAgentId] = useState('all')
    const [startDate, setStartDate] = useState(1695651200000)
    const [endDate, setEndDate] = useState(new Date().getTime())
    const dashboardUid = secureLocalStorage.getItem('uid')


    const handleTicketStats = (ticketStatsList) => {
        setTicketsCountLog(ticketStatsList)
        ticketStatsList.forEach((ticket) => {
            labelArray.push(getDayMonthFromMs(ticket.time))
            countArray.push(ticket.count)
        })
    }

    const getFeedbackRatingStats = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                startDate: startDate,
                endDate: endDate,
                agent: currentAgentId,
                dashboardUid
            })
        }

        fetch(`https://dev.blockerx.net/api/dashboard/ticket/stats`, requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setApiCalled(true)
                    handleTicketStats(data.data.ticketsCount)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useEffect(() => {
        if (!apiCalled) {
            getFeedbackRatingStats()
        }
    })

    return (
        <>
            <div>
                <p className='text-center text-4xl font-bold mt-12'>Tickets Per day</p>
            </div>
            {labelArray.length > 0 && (
                <div className='ticketfeedback__container'>
                    <BarChart
                        xAxis={[
                            {
                                id: 'barCategories',
                                data: labelArray,
                                scaleType: 'band'
                            }
                        ]}
                        series={[
                            {
                                data: countArray,
                                label: 'Tickets',
                                color: 'green'
                            }
                        ]}
                        height={500}
                    />
                </div>
            )}
        </>
    )
}

export default TicketLogs
