import * as React from 'react'
import secureLocalStorage from 'react-secure-storage'
import { useEffect } from 'react'
import { Button, Grid, Input, Snackbar, Stack } from '@mui/material'
import { Box } from '@mui/system'
import { useState } from 'react'
import MuiAlert from '@mui/material/Alert'
import ReactGA from 'react-ga'
import { useNavigate } from 'react-router-dom'

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />
})

const CustomMail = () => {
    const [recipientMail, setRecipientMail] = useState('')
    const [messageHeader, setMessageHeader] = useState('')
    const [messageBody, setMessageBody] = useState('')

    let navigate = useNavigate()
    const token = secureLocalStorage.getItem('token')
    const dashboardUid = secureLocalStorage.getItem('uid')

    const [blockedDomains, setBlockedDomains] = useState([])

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const resetInputFields = () => {
        setRecipientMail('')
        setMessageHeader('')
        setMessageBody('')
    }

    const sendMailToUser = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
            body: JSON.stringify({ recipient: recipientMail, header: messageHeader, body: messageBody })
        }

        fetch('https://zenze.blockerx.net/api/dashboard/mail', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    resetInputFields()
                } else {
                    setOpenErrorAlert(true)
                }
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname)
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align='center' justify='center' spacing={2}>
                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <h1 className='header-top mt-5'> Send Custom Mail</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Enter recipient mail'
                        id='combo-box-demo'
                        value={recipientMail}
                        sx={{ width: '35%' }}
                        onChange={(event, item) => {
                            setRecipientMail(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Header'
                        id='combo-box-demo'
                        value={messageHeader}
                        sx={{ width: '35%' }}
                        onChange={(event, item) => {
                            setMessageHeader(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Input
                        disablePortal
                        placeholder='Body'
                        id='combo-box-demo'
                        value={messageBody}
                        sx={{ width: '35%' }}
                        onChange={(event, item) => {
                            setMessageBody(event.target.value)
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className='pt-5'>
                    <Button className='h-12' variant='contained' sx={{ width: '35%' }} color='info' onClick={sendMailToUser}>
                        Send Mail
                    </Button>
                </Grid>
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity='success' sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity='error' sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box>
    )
}

export default CustomMail
