import './stripeRevenue.css'
import 'react-datepicker/dist/react-datepicker.css';
import { Autocomplete, Box, Button, Grid, Input, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/joy/Typography';
import DatePicker from 'react-datepicker';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import secureLocalStorage from 'react-secure-storage';
import { BASE_URL } from '../../api/Constants';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { BarChart } from '@mui/x-charts/BarChart';
import { LineChart } from '@mui/x-charts/LineChart';

function StripeRevenuePage() {

    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [revenueList, setRevenueList] = useState([])
    const [dailyWiseRevenue, setDailyWiseRevenue] = useState({})
    const [totalStripeRevenue, setTotalStripeRevenue] = useState(0)
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(null);
    const [minDate, setMinDate] = useState(new Date("2024-05-01"))
    const [maxDate, setMaxDate] = useState(new Date("2034-05-22"))
    const [product, setProduct] = useState('All')
    const [category, setCategory] = useState('All')

    const categoryChange = (event) => {
        setCategory(event.target.value);
    };
    const productChange = (event) => {
        setProduct(event.target.value);
    };

    const onDateRangeChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        console.log(' Checking...', { start, end })
    };

    const filtersApplied = () => {
        setLoading(true)
        console.log('This is end date', endDate)
        const nextDay = new Date(endDate)
        nextDay.setDate(nextDay.getDate() + 1);
        console.log({ startDate, nextDay, product, category })
        getRevenueInfo(formatDate(startDate), formatDate(nextDay), product, category)
    }

    const token = secureLocalStorage.getItem('token')
    const getRevenueInfo = async (startDate, endDate, product, category) => {
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', authorization: `Bearer ${token}` },
                body: JSON.stringify({
                    startDate,
                    endDate,
                    secretKey: 'monkeyDLuffy',
                    product,
                    category
                })
            };
            const res = await fetch(`${BASE_URL}/api/stripe/revenue`, requestOptions)
            console.log('Res : ', res)
            if (res.status === 403) {
                console.log('Unauthorized : ', 'hehe')
                toast.error('You are unauthorized to view Stripe analysis', {
                    position: 'bottom-left'
                })
                secureLocalStorage.clear()
                navigate('/forbidden')
            } else if (res.status === 401) {
                secureLocalStorage.clear()
                navigate('/forbidden')
            }
            const REVENUE_DATA = await res.json()
            if (REVENUE_DATA && REVENUE_DATA.message === "success") {
                const REVENUE_LIST = REVENUE_DATA.data
                const REVENUE_LIST_PRODUCT_WISE = Object.groupBy(REVENUE_LIST, ({ product }) => product)
                // console.log({ REVENUE_LIST_PRODUCT_WISE })
                let REVENUE_TABLE = {
                    'Cohort': {
                        'Course': 0,
                        'Books': 0,
                        'Coins': 0,
                        'Category Blocking': 0,
                        'Instant Approval': 0,
                        'Weekly': 0,
                        'Monthly': 0,
                        'Quarterly': 0,
                        'BiAnnual': 0,
                        'Annual': 0,
                        'Lifetime': 0,
                        'total': 0,
                        'Refund': 0,
                        'Net': 0
                    },
                    'Acccounts Website': {
                        'Course': 0,
                        'Books': 0,
                        'Coins': 0,
                        'Category Blocking': 0,
                        'Instant Approval': 0,
                        'Weekly': 0,
                        'Monthly': 0,
                        'Quarterly': 0,
                        'BiAnnual': 0,
                        'Annual': 0,
                        'Lifetime': 0,
                        'total': 0,
                        'Refund': 0,
                        'Net': 0
                    },
                    'BlockerX Website': {
                        'Course': 0,
                        'Books': 0,
                        'Coins': 0,
                        'Category Blocking': 0,
                        'Instant Approval': 0,
                        'Weekly': 0,
                        'Monthly': 0,
                        'Quarterly': 0,
                        'BiAnnual': 0,
                        'Annual': 0,
                        'Lifetime': 0,
                        'total': 0,
                        'Refund': 0,
                        'Net': 0
                    },
                    'BlockerX Desktop': {
                        'Course': 0,
                        'Books': 0,
                        'Coins': 0,
                        'Category Blocking': 0,
                        'Instant Approval': 0,
                        'Weekly': 0,
                        'Monthly': 0,
                        'Quarterly': 0,
                        'BiAnnual': 0,
                        'Annual': 0,
                        'Lifetime': 0,
                        'total': 0,
                        'Refund': 0,
                        'Net': 0
                    },
                    'BlockerX Chrome Extension': {
                        'Course': 0,
                        'Books': 0,
                        'Coins': 0,
                        'Category Blocking': 0,
                        'Instant Approval': 0,
                        'Weekly': 0,
                        'Monthly': 0,
                        'Quarterly': 0,
                        'BiAnnual': 0,
                        'Annual': 0,
                        'Lifetime': 0,
                        'total': 0,
                        'Refund': 0,
                        'Net': 0
                    },
                    'BlockerX APK': {
                        'Course': 0,
                        'Books': 0,
                        'Coins': 0,
                        'Category Blocking': 0,
                        'Instant Approval': 0,
                        'Weekly': 0,
                        'Monthly': 0,
                        'Quarterly': 0,
                        'BiAnnual': 0,
                        'Annual': 0,
                        'Lifetime': 0,
                        'total': 0,
                        'Refund': 0,
                        'Net': 0
                    }
                }
                let totalRevenue = 0
                Object.keys(REVENUE_LIST_PRODUCT_WISE).forEach(key => {
                    // REVENUE_TABLE[key] = {}
                    let totalProductRevenue = 0
                    let totalRefunds = 0
                    const value = REVENUE_LIST_PRODUCT_WISE[key]
                    const REVENUE_LIST_CATEGORY_WISE = Object.groupBy(value, ({ category }) => category)
                    Object.keys(REVENUE_LIST_CATEGORY_WISE).forEach(category => {
                        const purchases = REVENUE_LIST_CATEGORY_WISE[category]
                        let purchasesTotal = 0
                        for (let i = 0; i < purchases.length; i++) {
                            if (purchases[i].amount > 0) {
                                purchasesTotal = purchasesTotal + purchases[i].amount
                            } else {
                                totalRefunds = totalRefunds + purchases[i].amount
                            }
                        }
                        totalProductRevenue = totalProductRevenue + purchasesTotal
                        REVENUE_TABLE[key][category] = purchasesTotal
                    })
                    REVENUE_TABLE[key]['total'] = totalProductRevenue
                    REVENUE_TABLE[key]['Refund'] = totalRefunds
                    REVENUE_TABLE[key]['Net'] = totalProductRevenue + totalRefunds
                    totalRevenue = totalRevenue + (totalProductRevenue + totalRefunds)
                })
                console.log({ REVENUE_TABLE, totalRevenue })
                setRevenueList(REVENUE_TABLE)
                setTotalStripeRevenue(totalRevenue / 100)
                // console.log('Grouped by day', groupByDay(REVENUE_LIST))
                const REVENUE_GROUPED_BY_DATE = groupByDay(REVENUE_LIST)
                const DATE_LIST = getDatesBetween(startDate, endDate)
                const DAILY_REVENUE_LIST = {
                    'dates': DATE_LIST,
                    'Cohort': [],
                    'Acccounts Website': [],
                    'BlockerX Website': [],
                    'BlockerX Desktop': [],
                    'BlockerX Chrome Extension': [],
                    'BlockerX APK': []
                }
                for (let i = 0; i < DATE_LIST.length; i++) {
                    let cohort_daily_revenue = 0
                    let accounts_daily_revenue = 0
                    let website_daily_revenue = 0
                    let desktop_daily_revenue = 0
                    let chrome_daily_revenue = 0
                    let apk_daily_revenue = 0

                    const REVENUE_OF_THE_DAY = REVENUE_GROUPED_BY_DATE[DATE_LIST[i]]

                    if (REVENUE_OF_THE_DAY && REVENUE_OF_THE_DAY !== undefined && REVENUE_OF_THE_DAY.length) {
                        for (let i = 0; i < REVENUE_OF_THE_DAY.length; i++) {
                            if (REVENUE_OF_THE_DAY[i].product === 'Cohort') {
                                cohort_daily_revenue = cohort_daily_revenue + REVENUE_OF_THE_DAY[i].amount
                            } else if (REVENUE_OF_THE_DAY[i].product === 'Acccounts Website') {
                                accounts_daily_revenue = accounts_daily_revenue + REVENUE_OF_THE_DAY[i].amount
                            } else if (REVENUE_OF_THE_DAY[i].product === 'BlockerX Website') {
                                website_daily_revenue = website_daily_revenue + REVENUE_OF_THE_DAY[i].amount
                            } else if (REVENUE_OF_THE_DAY[i].product === 'BlockerX Desktop') {
                                desktop_daily_revenue = desktop_daily_revenue + REVENUE_OF_THE_DAY[i].amount
                            } else if (REVENUE_OF_THE_DAY[i].product === 'BlockerX Chrome Extension') {
                                chrome_daily_revenue = chrome_daily_revenue + REVENUE_OF_THE_DAY[i].amount
                            } else if (REVENUE_OF_THE_DAY[i].product === 'BlockerX APK') {
                                apk_daily_revenue = apk_daily_revenue + REVENUE_OF_THE_DAY[i].amount
                            }
                        }
                    }
                    DAILY_REVENUE_LIST['Cohort'].push(cohort_daily_revenue / 100)
                    DAILY_REVENUE_LIST['Acccounts Website'].push(accounts_daily_revenue / 100)
                    DAILY_REVENUE_LIST['BlockerX Website'].push(website_daily_revenue / 100)
                    DAILY_REVENUE_LIST['BlockerX Desktop'].push(desktop_daily_revenue / 100)
                    DAILY_REVENUE_LIST['BlockerX Chrome Extension'].push(chrome_daily_revenue / 100)
                    DAILY_REVENUE_LIST['BlockerX APK'].push(apk_daily_revenue / 100)


                }
                setDailyWiseRevenue(DAILY_REVENUE_LIST)
                setLoading(false)
            }
        } catch (err) {
            console.log('Err : ', err)
        }

    }

    function formatDate(date) {
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Add 1 because months are 0-indexed, and pad with zero if needed
        const day = date.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const groupByDay = (arr) => {
        return arr.reduce((acc, obj) => {
            const date = new Date(obj.time);
            const day = date.toISOString().split('T')[0]; // Get the date in YYYY-MM-DD format

            if (!acc[day]) {
                acc[day] = [];
            }

            acc[day].push(obj);
            return acc;
        }, {});
    };

    function getDatesBetween(startDate, endDate) {
        const dateArray = [];
        let currentDate = new Date(startDate);
        let end = new Date(endDate);

        while (currentDate < end) {
            dateArray.push(new Date(currentDate).toISOString().slice(0, 10)); // Formats the date to "YYYY-MM-DD"
            currentDate.setDate(currentDate.getDate() + 1);
        }

        return dateArray;
    }


    useEffect(() => {
        const currentDate = new Date();

        const firstDay = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const lastDay = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);

        console.log("First Day of the Month:", formatDate(firstDay))
        console.log("Last Day of the Month:", formatDate(lastDay))
        setMaxDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
        setStartDate(firstDay)
        setEndDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0))
        getRevenueInfo(formatDate(firstDay), formatDate(lastDay), "All", "All")
    }, [])

    return (
        <Box sx={{ flexGrow: 2 }}>
            <h1 className='header-top mt-5'>Stripe UAE Revenue</h1>
            <div className='revenue_filters'>
                <DatePicker
                    selected={startDate}
                    onChange={onDateRangeChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    inline
                    minDate={minDate}
                    maxDate={maxDate}
                />
                <div className='filter_dropdown_option'>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Products</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={product}
                            label="Products"
                            onChange={productChange}
                        >
                            <MenuItem value={'All'}>All</MenuItem>
                            <MenuItem value={'Cohort'}>Cohort</MenuItem>
                            <MenuItem value={'Acccounts Website'}>Acccounts Website</MenuItem>
                            <MenuItem value={'BlockerX Website'}>BlockerX Website</MenuItem>
                            <MenuItem value={'BlockerX Desktop'}>BlockerX Desktop</MenuItem>
                            <MenuItem value={'BlockerX Chrome Extension'}>BlockerX Chrome Extension</MenuItem>
                            <MenuItem value={'BlockerX APK'}>BlockerX APK</MenuItem>
                        </Select>
                    </FormControl>
                </div>
                <div className='filter_dropdown_option'>
                    <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">Category</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={category}
                            label="Category"
                            onChange={categoryChange}
                        >
                            <MenuItem value={'All'}>All</MenuItem>
                            <MenuItem value={'Course'}>Course</MenuItem>
                            <MenuItem value={'Books'}>Books</MenuItem>
                            <MenuItem value={'Coins'}>Coins</MenuItem>
                            <MenuItem value={'Category Blocking'}>Category Blocking</MenuItem>
                            <MenuItem value={'Instant Approval'}>Instant Approval</MenuItem>
                            <MenuItem value={'Weekly'}>Weekly</MenuItem>
                            <MenuItem value={'Monthly'}>Monthly</MenuItem>
                            <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                            <MenuItem value={'BiAnnual'}>BiAnnual</MenuItem>
                            <MenuItem value={'Annual'}>Annual</MenuItem>
                            <MenuItem value={'Lifetime'}>Lifetime</MenuItem>

                        </Select>
                    </FormControl>
                </div>
                <Button onClick={filtersApplied} variant="contained">Apply Filters</Button>
            </div>
            <h4 className='m-5'>(All values are in USD)</h4>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <TableContainer component={Paper} sx={{ padding: '5px' }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Products</TableCell>
                                <TableCell align="right">Course</TableCell>
                                <TableCell align="right">Books</TableCell>
                                <TableCell align="right">Coins</TableCell>
                                <TableCell align="right">Category Blocking</TableCell>
                                <TableCell align="right">Instant Approval</TableCell>
                                <TableCell align="right">Weekly</TableCell>
                                <TableCell align="right">Monthly</TableCell>
                                <TableCell align="right">Quarterly</TableCell>
                                <TableCell align="right">BiAnnual</TableCell>
                                <TableCell align="right">Annual</TableCell>
                                <TableCell align="right">Lifetime</TableCell>
                                <TableCell align="right">Total</TableCell>
                                <TableCell style={{ color: 'red' }} align="right">Refund</TableCell>
                                <TableCell style={{ color: 'green' }} align="right">Net Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                Object.keys(revenueList).map((product, i) => {
                                    return (
                                        <TableRow
                                            key={product}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {product}
                                            </TableCell>
                                            <TableCell align="right">{revenueList[product]['Courses'] ? (revenueList[product]['Courses'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Books'] ? (revenueList[product]['Books'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Coins'] ? (revenueList[product]['Coins'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Category Blocking'] ? (revenueList[product]['Category Blocking'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Instant Approval'] ? (revenueList[product]['Instant Approval'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Weekly'] ? (revenueList[product]['Weekly'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Monthly'] ? (revenueList[product]['Monthly'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Quarterly'] ? (revenueList[product]['Quarterly'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['BiAnnual'] ? (revenueList[product]['BiAnnual'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Annual'] ? (revenueList[product]['Annual'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['Lifetime'] ? (revenueList[product]['Lifetime'] / 100) : 0}</TableCell>
                                            <TableCell align="right">{revenueList[product]['total'] ? (revenueList[product]['total'] / 100) : 0}</TableCell>
                                            <TableCell style={{ color: 'red', fontWeight: 600 }} align="right">{revenueList[product]['Refund'] ? (revenueList[product]['Refund'] / 100) : 0}</TableCell>
                                            <TableCell style={{ color: 'green', fontWeight: 600 }} align="right">{revenueList[product]['Net'] ? (revenueList[product]['Net'] / 100) : 0}</TableCell>
                                        </TableRow>
                                    )
                                })
                            }
                            {/* {revenueList.map((product) => (
                                <TableRow
                                    key={product}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell component="th" scope="row">
                                        {product}
                                    </TableCell>
                                    <TableCell align="right">{row.calories}</TableCell>
                                    <TableCell align="right">{row.fat}</TableCell>
                                    <TableCell align="right">{row.carbs}</TableCell>
                                    <TableCell align="right">{row.protein}</TableCell>
                                </TableRow>
                            ))} */}
                        </TableBody>
                    </Table>
                    <Typography level="h2">Total Revenue: {totalStripeRevenue}</Typography>
                </TableContainer >
            )}
            <h1 className='header-top mt-5'>Revenue Bar Graph</h1>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className='stripe_revenue_graph_container'>
                    <BarChart
                        height={600}
                        series={[
                            { data: dailyWiseRevenue['Cohort'], label: 'Cohort', id: 'CohortId', stack: 'total' },
                            { data: dailyWiseRevenue['Acccounts Website'], label: 'Acccounts Website', id: 'Acccounts WebsiteId', stack: 'total' },
                            { data: dailyWiseRevenue['BlockerX Website'], label: 'BlockerX Website', id: 'BlockerX WebsiteId', stack: 'total' },
                            { data: dailyWiseRevenue['BlockerX Desktop'], label: 'BlockerX Desktop', id: 'BlockerX DesktopId', stack: 'total' },
                            { data: dailyWiseRevenue['BlockerX Chrome Extension'], label: 'BlockerX Chrome Extension', id: 'BlockerX Chrome ExtensionId', stack: 'total' },
                            { data: dailyWiseRevenue['BlockerX APK'], label: 'BlockerX APK', id: 'BlockerX APKId', stack: 'total' }
                        ]}
                        xAxis={[{ data: dailyWiseRevenue.dates, scaleType: 'band' }]}
                    />
                </div>
            )}
            <h1 className='header-top mt-5'>Revenue Line Graph</h1>
            {loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress />
                </Box>
            ) : (
                <div className='stripe_revenue_graph_container'>
                    <LineChart
                        xAxis={[{ data: dailyWiseRevenue.dates, scaleType: 'point' }]}
                        series={[
                            { data: dailyWiseRevenue['Cohort'], label: 'Cohort', id: 'CohortId' },
                            { data: dailyWiseRevenue['Acccounts Website'], label: 'Acccounts Website', id: 'Acccounts WebsiteId' },
                            { data: dailyWiseRevenue['BlockerX Website'], label: 'BlockerX Website', id: 'BlockerX WebsiteId' },
                            { data: dailyWiseRevenue['BlockerX Desktop'], label: 'BlockerX Desktop', id: 'BlockerX DesktopId' },
                            { data: dailyWiseRevenue['BlockerX Chrome Extension'], label: 'BlockerX Chrome Extension', id: 'BlockerX Chrome ExtensionId', color: '#e64a19' },
                            { data: dailyWiseRevenue['BlockerX APK'], label: 'BlockerX APK', id: 'BlockerX APKId' }
                        ]}
                        height={600}
                    />
                </div>
            )}
        </Box >
    )
}

export default StripeRevenuePage