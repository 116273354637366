import { LoadingButton } from '@mui/lab'
import { Autocomplete, Box, Button, Grid, Input, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import GoogleIcon from '../../assets/images/google.png'
import StripeIcon from '../../assets/images/stripe.png'
import { useLocation } from 'react-router-dom'
import CustomAlert from '../Default/CustomAlert/CustomAlert'

function PremiumPush() {
    const location = useLocation()
    const [uid, setUid] = useState('')
    const [planName, setPlanName] = useState('')
    const [gateway, setGateway] = useState('Google')
    const [orderId, setOrderId] = useState('')
    const [purchaseToken, setPurchaseToken] = useState('')
    const [stripeType, setStripeType] = useState('')
    const [customerId, setCustomerId] = useState('')
    const [subscriptionId, setSubscriptionId] = useState('')
    const [stripeAmount, setStripeAmount] = useState(0)

    const [loading, setLoading] = useState(false)

    const [successMessage, setSuccessMessage] = useState('Success')
    const [errorMessage, setErrorMessage] = useState('Error')
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false)
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenSuccessAlert(false)
    }
    const [openErrorAlert, setOpenErrorAlert] = useState(false)
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }
        setOpenErrorAlert(false)
    }

    const addPremiumHandler = async () => {
        try {
            setLoading(true)
            if (gateway === 'Google') {
                const response = await fetch('https://accounts.blockerx.net/api/v1/payments', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify({
                        uid,
                        planName,
                        orderId,
                        purchaseToken,
                        paymentMethod: 'google'
                    })
                })
                const data = await response.json()
                console.log(data)
                if (data.status === 'error') {
                    setErrorMessage('Something went wrong')
                    setOpenErrorAlert(true)
                } else {
                    setSuccessMessage('Premium data has been added')
                    setOpenSuccessAlert(true)
                }
            } else if (gateway === 'Stripe') {
                let url = ''
                let body = {}
                if (stripeType === 'Charge') {
                    url = 'https://dev.blockerx.net/api/stripePurchaseManual'
                    body = {
                        type: 'charge',
                        planName,
                        customer: customerId,
                        subscriptionId,
                        amount: stripeAmount
                    }
                } else if (stripeType === 'Subscription') {
                    url = 'https://dev.blockerx.net/api/stripePurchaseManual'
                    body = {
                        type: 'subscription',
                        planName,
                        customer: customerId,
                        subscriptionId,
                        amount: stripeAmount
                    }
                }

                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                })
                const data = await response.json()
                console.log(data)
                if (data.status === 'error') {
                    setErrorMessage('Something went wrong')
                    setOpenErrorAlert(true)
                } else {
                    setSuccessMessage('Premium data has been added')
                    setOpenSuccessAlert(true)
                }
            }
        } catch (err) {
            setErrorMessage('Something went wrong')
            setOpenErrorAlert(true)
        } finally {
            setLoading(false)
            setPlanName('')
            setOrderId('')
            setPurchaseToken('')
            setStripeType('')
            setStripeAmount(0)
            setCustomerId('')
            setSubscriptionId('')
        }
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search)
        const uid = queryParams.get('uid')
        if (uid) {
            setUid(uid)
        }
    }, [location.search])

    return (
        <>
            <Box sx={{ flexGrow: 2 }}>
                <h1 className='header-top mt-5'>Add Premium Data</h1>
                <Grid align='center' justify='center' spacing={2} className='pb-32'>
                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <TextField
                            disablePortal
                            label='Enter uid'
                            value={uid}
                            id='combo-box-demo'
                            sx={{ width: '50%' }}
                            onChange={(event, item) => {
                                setUid(event.target.value)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-8'>
                        <Grid container spacing={2} sx={{ width: '48%' }}>
                            <Grid item xs={6} onClick={() => setGateway('Google')} style={{ cursor: 'pointer', backgroundColor: gateway === 'Google' ? '#eeeeee' : 'transparent' }}>
                                <img src={GoogleIcon} alt='Google' style={{ width: '30%', height: 'auto' }} />
                            </Grid>
                            <Grid item xs={6} onClick={() => setGateway('Stripe')} style={{ cursor: 'pointer', backgroundColor: gateway === 'Stripe' ? '#eeeeee' : 'transparent' }}>
                                <img src={StripeIcon} alt='Stripe' style={{ width: '30%', height: 'auto' }} />
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <Autocomplete
                            disablePortal
                            id='combo-box-demo'
                            value={gateway}
                            options={['Google', 'Stripe']}
                            sx={{ width: '50%' }}
                            onChange={(event, item) => {
                                setGateway(item)
                            }}
                            renderInput={(params) => <TextField {...params} label='Choose Gateway' />}
                        />
                    </Grid>

                    {gateway === 'Stripe' && (
                        <>
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <Autocomplete
                                    disablePortal
                                    id='combo-box-demo'
                                    value={stripeType}
                                    options={['Charge', 'Subsciption']}
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setStripeType(item)
                                    }}
                                    renderInput={(params) => <TextField {...params} label='Choose Stripe Type' />}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Customer ID'
                                    value={customerId}
                                    id='combo-box-demo'
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setCustomerId(event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Subscription ID'
                                    value={subscriptionId}
                                    id='combo-box-demo'
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setSubscriptionId(event.target.value)
                                    }}
                                />
                            </Grid>
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Amount'
                                    value={stripeAmount}
                                    id='combo-box-demo'
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setStripeAmount(event.target.value)
                                    }}
                                />
                            </Grid>
                        </>
                    )}

                    {gateway === 'Google' && (
                        <>
                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Plan Name'
                                    value={planName}
                                    id='combo-box-demo'
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setPlanName(event.target.value)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Order ID'
                                    id='combo-box-demo'
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setOrderId(event.target.value)
                                    }}
                                />
                            </Grid>

                            <Grid item lg={4} md={6} sm={12} className='pt-5'>
                                <TextField
                                    disablePortal
                                    label='Purchase Token'
                                    id='combo-box-demo'
                                    sx={{ width: '50%' }}
                                    onChange={(event, item) => {
                                        setPurchaseToken(event.target.value)
                                    }}
                                />
                            </Grid>
                        </>
                    )}

                    <Grid item lg={4} md={6} sm={12} className='pt-5'>
                        <LoadingButton className='h-12' variant='contained' sx={{ width: '50%' }} onClick={addPremiumHandler} loading={loading}>
                            Add Premium
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Box>
            <CustomAlert successMessage={successMessage} errorMessage={errorMessage} openSuccessAlert={openSuccessAlert} closeSuccessAlert={closeSuccessAlert} openErrorAlert={openErrorAlert} closeErrorAlert={closeErrorAlert} />
        </>
    )
}

export default PremiumPush
