import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { Autocomplete, Button, Card, CardActions, CardHeader, CardMedia, Grid, IconButton, Input, Snackbar, TextField } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { useState, useEffect } from 'react';
import ReactGA from "react-ga";
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import './BlockWindow.css'
import { useNavigate } from 'react-router-dom';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function BlockWindow() {
    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid");

    let navigate = useNavigate();

    const [title, setTitle] = useState("");
    const [articleLink, setArticleLink] = useState("");
    const [thumbnailLink, setThumbnailLink] = useState("");
    const [newRank, setNewRank] = useState();
    const [language, setLanguage] = useState("");
    const [apiCalled, setApiCalled] = useState(false)

    const [blockList, setBlockList] = useState([])

    const resetAllFields = () => {
        setTitle("");
        setArticleLink("");
        setThumbnailLink("");
        setNewRank("")
    }

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const AddCustomBlocklistHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "title": title, "articleLink": articleLink, "thumbnailLink": thumbnailLink, "language": language, "mediaType": "article", "dashboardUid": dashboardUid })
        };
        fetch('https://dev.blockerx.net/api/dashboard/blockScreen', requestOptions)
            .then(response => response.json())
            .then((data) => {
                resetAllFields()
                setOpenSuccessAlert(true)
            })
            .catch((error) => {
                console.log(error);
            });
    }

    const GetCustomBlockListHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "dashboardUid": dashboardUid })
        };

        fetch('https://dev.blockerx.net/api/dashboard/blockScreen/list', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    console.log('Data ;', data)
                    let blockListRes = data.data;
                    setBlockList(blockListRes)
                    setApiCalled(true)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else if (data.status === 403) {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    setOpenErrorAlert(true)
                    setApiCalled(false)
                }
            })
            .catch((error) => {
                setOpenErrorAlert(true)
                secureLocalStorage.clear();
                navigate("/tokenExpired");
            });
    }

    const UpdateRankOfPostHandler = (articleID, rank) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "newRank": parseInt(newRank), "articleId": articleID, "dashboardUid": dashboardUid })
        };
        fetch('https://dev.blockerx.net/api/dashboard/blockScreen/rank', requestOptions)
            .then(response => response.json())
            .then((data) => {
                setOpenSuccessAlert(true)
                resetAllFields()
                setBlockList(data.data)
            })
            .catch((error) => {
                setOpenErrorAlert(true)
            });
    }

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    const deleteArticleHandler = (articleID) => {
        const requestOptions = {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "articleId": articleID, "dashboardUid": dashboardUid })
        };
        fetch('https://dev.blockerx.net/api/dashboard/blockScreen', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                setOpenSuccessAlert(true)
                resetAllFields()
                GetCustomBlockListHandler()
            })
            .catch((error) => {
                console.log(error);
                setOpenErrorAlert(true)
            });
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <>
            <h1 className='text-5xl text-center my-7'> Custom Block Window </h1>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter Title"
                        value={title}
                        id="combo-box-demo"
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setTitle(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter Article Link"
                        value={articleLink}
                        id="combo-box-demo"
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setArticleLink(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter Thumbnail Link"
                        id="combo-box-demo"
                        value={thumbnailLink}
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setThumbnailLink(event.target.value);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={languageOptions}
                        value={language}
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setLanguage(item.name)
                        }}
                        renderInput={(params) => <TextField {...params} label="Choose Language" />}
                    />
                </Grid>

                {/* <Grid item lg={4} md={6} sm={12} sx={{ width: "400px", textAlign: "left" }} className="pt-5">
                    <input type="file" name="img" accept="image/*" onChange={handleFileInput} />
                </Grid> */}

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        sx={{ width: "400px" }}
                        onClick={AddCustomBlocklistHandler}
                        variant="contained">
                        Add new custom window
                    </Button>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-20">
                    <Button
                        className="h-12"
                        sx={{ width: "400px" }}
                        onClick={GetCustomBlockListHandler}
                        color='warning'
                        variant="contained">
                        Show Articles
                    </Button>
                </Grid>
            </Grid>

            <Box sx={{ marginTop: '30px', marginBottom: '50px' }} >
                {
                    apiCalled && blockList.map((item) => {
                        return (
                            <Grid className='outerGrid' align="center" justify="center" sx={{ width: "400px", margin: "0 auto" }} spacing={2} id={item._id} key={item._id}>
                                <Grid item lg={4} md={6} sm={12} className="pt-6" sx={{ width: "400px" }}>
                                    <Card sx={{ maxWidth: "400px" }}>
                                        <CardHeader
                                            title={item.title}
                                        />
                                        <CardMedia
                                            component="img"
                                            image={item.thumbnailLink}
                                            alt="Block Window"
                                        />
                                        <CardActions disableSpacing>
                                            {/* <IconButton aria-label="add to favorites" onClick={() => deleteScreenHandler(item._id)}>
                                                <LooksOneIcon />
                                            </IconButton>
                                            <IconButton aria-label="add to favorites" onClick={() => deleteScreenHandler(item._id)}>
                                                <LooksTwoIcon />
                                            </IconButton>
                                            <IconButton aria-label="add to favorites" onClick={() => deleteScreenHandler(item._id)}>
                                                <Looks3Icon />
                                            </IconButton> */}
                                            <IconButton aria-label="delete" onClick={() => deleteArticleHandler(item._id)}>
                                                <DeleteIcon style={{ fontSize: 30 }} />
                                            </IconButton>
                                            <TextField disabled id="outlined-disabled" className='currentLanguage' sx={{ marginRight: "10px", textAlign: "center" }} defaultValue={capitalizeFirstLetter(item.language)} />
                                            <TextField disabled id="outlined-disabled" className='currentRank' sx={{ marginRight: "10px" }} defaultValue={item.rank} />
                                            <TextField
                                                id="outlined-basic"
                                                className='newRank'
                                                label="New Rank"
                                                onChange={(event, item) => {
                                                    setNewRank(event.target.value)
                                                }}
                                                variant="outlined" />
                                            <IconButton aria-label="delete" onClick={() => UpdateRankOfPostHandler(item._id)}>
                                                <SendIcon style={{ fontSize: 30 }} />
                                            </IconButton>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            </Grid>
                        )
                    })
                }
            </Box>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </>
    );
}

const languageOptions = [
    {
        label: 'English',
        name: 'english'
    },
    {
        label: 'Spanish',
        name: 'spanish'
    },
    {
        label: 'Portuguese',
        name: 'portuguese'
    },
    {
        label: 'Japanese',
        name: 'japanese'
    },
    {
        label: 'Korean',
        name: 'korean'
    },
    {
        label: 'Hebrew',
        name: 'hebrew'
    },
    {
        label: 'Polish',
        name: 'polish'
    },
    {
        label: 'French',
        name: 'french'
    },
    {
        label: 'German',
        name: 'german'
    },
    {
        label: 'Italian',
        name: 'italian'
    },
    {
        label: 'Dutch',
        name: 'dutch'
    },
    {
        label: 'Swedish',
        name: 'swedish'
    },
];
