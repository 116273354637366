import secureLocalStorage from 'react-secure-storage'
import React, { useEffect, useState } from 'react'
import './ViewTicketsRevamped.css'
import { useNavigate } from 'react-router-dom'
import { Chip } from '@mui/material'
import { showTimeInAgoFormat } from '../../../Utils/time'
import appleIcon from '../../../assets/images/apple.png'
import androidIcon from '../../../assets/images/android-seeklogo.com.svg'
import windowsIcon from '../../../assets/images/windows.png'
import chromeIcon from '../../../assets/images/chrome.svg'


const getFirstFiveWords = (sentence) => {
    const words = sentence.split(' '); // Split the sentence into an array of words
    const firstFiveWords = words.slice(0, 5); // Get the first five words
    return firstFiveWords.join(' '); // Join the words back into a sentence
}

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

const showTicketStatus = (text) => {
    const words = text.split("_")
    let result = ''
    words.forEach((word) => {
        result += capitalizeFirstLetter(word) + ' '
    })
    return result
}


function ViewTicketsRevamped() {

    const [ticketsList, setTicketsList] = useState([])
    const [filterTicketsList, setFilterTicketsList] = useState([])
    const [currentFilter, setCurrentFilter] = useState('All Tickets')
    const [apiCalled, setApiCalled] = useState(false)
    const dashboardUid = secureLocalStorage.getItem("uid");
    const navigate = useNavigate()
    const [pendingTicketList, setPendingTicketsList] = useState([])
    const [awaitingUserReplyList, setAwaitingUserReplyList] = useState([])
    const [techReviewList, setTechReviewList] = useState([])

    const filterTicketsBased = (ticket_list) => {
        let pendingArr = []
        let awaitingUserReplyArr = []
        let techReviewArr = []
        ticket_list.forEach((eachTicket) => {
            if (eachTicket.status === 'pending') {
                pendingArr.push(eachTicket)
            } else if (eachTicket.status === 'awaiting_user_reply') {
                awaitingUserReplyArr.push(eachTicket)
            } else if (eachTicket.status === 'tech_review') {
                techReviewArr.push(eachTicket)
            }
        })
        setPendingTicketsList(pendingArr)
        setAwaitingUserReplyList(awaitingUserReplyArr)
        setTechReviewList(techReviewArr)
    }

    const openEachTicketContainer = (ticketId) => {
        console.log(ticketId)
        navigate(`/customer-tickets/${ticketId}`)
    }

    const showAllFilterTickets = (STATUS) => {
        if (STATUS === 'all') {
            setFilterTicketsList(ticketsList)
            return
        }

        let newTickets = []
        ticketsList.forEach((ticket) => {
            console.log('Current Ticket : ', ticket)
            if (ticket.status === STATUS) {
                newTickets.push(ticket)
            }
        })
        setFilterTicketsList(newTickets)
    }

    const getPendingTickets = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                "dashboardUid": dashboardUid
            })
        };

        fetch(`https://dev.blockerx.net/api/tickets/pending`, requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    const ticket_list = data.data.list.reverse()
                    console.log('Tickets_list : ', ticket_list)
                    setTicketsList(ticket_list)
                    setFilterTicketsList(ticket_list)
                    filterTicketsBased(ticket_list)
                    setApiCalled(true)
                } else {
                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const showTicketChips = (createdAt, updatedAt, respondedAt, status) => {

        if (respondedAt > updatedAt && status !== 'tech_review') {
            return <Chip label="Awaiting User Response" sx={{ marginLeft: "10px", marginBottom: "4px" }} color="warning" />
        }

        if (status === 'tech_review') {
            return <Chip label="Under Tech Review" sx={{ marginLeft: "10px", marginBottom: "4px" }} color="info" />
        }

        if (createdAt === updatedAt && (new Date().getTime() - createdAt > (86400000) && new Date().getTime() - createdAt < (2 * 86400000))) {
            return <Chip label="First Response Due" sx={{ marginLeft: "10px", marginBottom: "4px" }} color="secondary" />
        }

        if (createdAt === updatedAt && (new Date().getTime() - createdAt > (2 * 86400000))) {
            return <Chip label="Overdue" sx={{ marginLeft: "10px", marginBottom: "4px" }} color="error" />
        }

        if (createdAt === updatedAt) {
            return (<><Chip label="New" sx={{ marginLeft: "10px", marginBottom: "4px", marginRight: "10px" }} color="primary" /></>)
        }

        if (respondedAt < updatedAt) {
            return <Chip label="User Responded" sx={{ marginLeft: "10px", marginBottom: "4px" }} color="success" />
        }
    }

    const redirectToClosedTicketsPage = () => {
        navigate('/closed-customer-tickets')
    }

    const redirectToIndividualTicket = (ticketID) => {
        navigate(`/customer-tickets/${ticketID}`)
    }

    useEffect(() => {
        if (!apiCalled) {
            getPendingTickets()
        }
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <div className='font-bold text-4xl text-center my-10'>Customer Tickets</div>
            <div className='revamped__ticket__container'>
                <div className='revamped__type__ticket__container'>
                    <div className='revamped__ticket__header'>
                        <div className='heading__text'>Pending Tickets ({pendingTicketList.length})</div>
                    </div>
                    <div className='revamped__tickets__list'>
                        {pendingTicketList.length > 0 && pendingTicketList.map((eachTicket) => {
                            return (<div className='each__revamped__ticket cursor-pointer' onClick={() => redirectToIndividualTicket(eachTicket._id)}>
                                <div className='revamped__icon'>
                                    {
                                        eachTicket.platform === 'ios' && <img className='' src={appleIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'android' && <img className='' src={androidIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'windows' && <img className='' src={windowsIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'chrome' && <img className='device__icon mb-4' src={chromeIcon} alt="" />
                                    }
                                </div>
                                <div className='each__revamped__body'>
                                    <div className='font-bold'>
                                        #{eachTicket._id}
                                    </div>
                                    <div>
                                        {eachTicket.description.substr(0, 70)}...
                                    </div>
                                    <div className='font-bold'>
                                        {showTimeInAgoFormat(eachTicket.time)}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
                <div className='revamped__type__ticket__container'>
                    <div className='revamped__ticket__header'>
                        <div className='heading__text'>Awaiting User Response ({awaitingUserReplyList.length})</div>
                    </div>
                    <div className='revamped__tickets__list'>
                        {awaitingUserReplyList.length > 0 && awaitingUserReplyList.map((eachTicket) => {
                            return (<div className='each__revamped__ticket cursor-pointer' onClick={() => redirectToIndividualTicket(eachTicket._id)}>
                                <div className='revamped__icon'>
                                    {
                                        eachTicket.platform === 'ios' && <img className='' src={appleIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'android' && <img className='' src={androidIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'windows' && <img className='' src={windowsIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'chrome' && <img className='device__icon mb-4' src={chromeIcon} alt="" />
                                    }
                                </div>
                                <div className='each__revamped__body'>
                                    <div className='font-bold'>
                                        #{eachTicket._id}
                                    </div>
                                    <div>
                                        {eachTicket.description.substr(0, 70)}...
                                    </div>
                                    <div className='font-bold'>
                                        {showTimeInAgoFormat(eachTicket.time)}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
                <div className='revamped__type__ticket__container'>
                    <div className='revamped__ticket__header'>
                        <div className='heading__text'>Tech Review ({techReviewList.length})</div>
                    </div>
                    <div className='revamped__tickets__list'>
                        {techReviewList.length > 0 && techReviewList.map((eachTicket) => {
                            return (<div className='each__revamped__ticket cursor-pointer' onClick={() => redirectToIndividualTicket(eachTicket._id)}>
                                <div className='revamped__icon'>
                                    {
                                        eachTicket.platform === 'ios' && <img className='' src={appleIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'android' && <img className='' src={androidIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'windows' && <img className='' src={windowsIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'chrome' && <img className='device__icon mb-4' src={chromeIcon} alt="" />
                                    }
                                </div>
                                <div className='each__revamped__body'>
                                    <div className='font-bold'>
                                        #{eachTicket._id}
                                    </div>
                                    <div>
                                        {eachTicket.description.substr(0, 70)}...
                                    </div>
                                    <div className='font-bold'>
                                        {showTimeInAgoFormat(eachTicket.time)}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
                <div className='revamped__type__ticket__container'>
                    <div className='revamped__ticket__header'>
                        <div className='heading__text'>All Tickets ({filterTicketsList.length})</div>
                    </div>
                    <div className='revamped__tickets__list'>
                        {filterTicketsList.length > 0 && filterTicketsList.map((eachTicket) => {
                            return (<div className='each__revamped__ticket cursor-pointer' onClick={() => redirectToIndividualTicket(eachTicket._id)}>
                                <div className='revamped__icon'>
                                    {
                                        eachTicket.platform === 'ios' && <img className='' src={appleIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'android' && <img className='' src={androidIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'windows' && <img className='' src={windowsIcon} alt="" />
                                    }
                                    {
                                        eachTicket.platform === 'chrome' && <img className='device__icon mb-4' src={chromeIcon} alt="" />
                                    }
                                </div>
                                <div className='each__revamped__body'>
                                    <div className='font-bold'>
                                        #{eachTicket._id}
                                    </div>
                                    <div>
                                        {eachTicket.description.substr(0, 70)}...
                                    </div>
                                    <div className='font-bold'>
                                        {showTimeInAgoFormat(eachTicket.time)}
                                    </div>
                                </div>
                            </div>)
                        })}

                    </div>
                </div>
            </div>
        </>
    )
}

export default ViewTicketsRevamped
