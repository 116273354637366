/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { showTimeInAgoFormat } from '../../Utils/time'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { convertMillisecondsToDate } from '../../Utils/time'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#4F709C',
        color: theme.palette.common.white,
        fontSize: 16
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

function ChatbotLogs() {
    const [chatData, setChatData] = useState([])

    useEffect(() => {
        fetch('https://events.blockerx.net/api/logs/chatbot/queries')
            .then((response) => response.json())
            .then((data) => {
                if (data.status === 200) {
                    setChatData(data.data.queries)
                } else {
                    console.error('Failed to fetch chat data')
                }
            })
            .catch((error) => console.error('Error fetching chat data:', error))
    }, [])

    return (
        <div className='campaign__list__container'>
            <TableContainer component={Paper} className=''>
                <h1 className='text-center text-4xl font-bold p-4 text-gray-700'>Customer Support Chatbot Logs</h1>
                <Table aria-label='customized table'>
                    <TableHead>
                        <TableRow>
                            <StyledTableCell>Input</StyledTableCell>
                            <StyledTableCell align='center'>Output</StyledTableCell>
                            <StyledTableCell align='center'>Time</StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {chatData.map((row, index) => (
                            <StyledTableRow key={index}>
                                <StyledTableCell scope='row'>{row.input}</StyledTableCell>
                                <StyledTableCell scope='row'>{row.output}</StyledTableCell>
                                <StyledTableCell scope='row' sx={{ width: '10%' }}>
                                    {showTimeInAgoFormat(Number(row.time))}
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ChatbotLogs
