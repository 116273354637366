import secureLocalStorage from 'react-secure-storage'
import * as React from 'react';
import { useEffect } from 'react';
import { Autocomplete, Button, Grid, Snackbar, Stack, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useState } from "react";
import MuiAlert from '@mui/material/Alert';

import { useNavigate } from 'react-router-dom';

import ReactGA from "react-ga";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const datasetType = [
    {
        label: 'Combined'
    },
    {
        label: 'All-Dates'
    },
    {
        label: 'Annual'
    }
];

const Stripe = () => {

    const [apiCalled, setApiCalled] = useState(false);

    const [revenueData, setRevenueData] = useState([]);
    const [revenueTableData, setRevenueTableData] = useState([]);

    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const [currentDataSetType, setCurrentDataSetType] = useState("Combined");

    let navigate = useNavigate();
    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid");

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    const resetAllFields = () => {
    }

    const getPurchaseBetweenDates = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "startDate": startDate, "endDate": endDate, "datasetType": currentDataSetType, "dashboardUid": dashboardUid })
        };

        fetch('https://healify.blockerx.net/api/dashboard/getStripeWebRevenueBwDates', requestOptions)
            .then(response => response.json())
            .then((data) => {
                if (data.status === "success" && data.data.length) {
                    console.log(data);
                    setApiCalled(true);
                    setRevenueData(data.data)
                    setRevenueTableData(data.data);
                } else if (data.status === "invalid_token") {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    setOpenErrorAlert(true);
                    resetAllFields();
                }
            });

    }

    // const getDateInMs = (date) => {
    //     const myDate = date.split("-");
    //     const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime();
    //     console.log(newDate);
    // }

    const convertMsToDate = (date) => {
        let DATE = new Date(date);
        return DATE.toLocaleDateString();
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    return (
        <Box sx={{ flexGrow: 2 }}>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <h1 className="header-top mt-5">Stripe Revenue</h1>
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <TextField
                        label="Start Date"
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "14%", margin: "1%" }}
                        onChange={(event, item) => {
                            const myDate = event.target.value.split("-");
                            const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime();
                            setStartDate(newDate);
                        }}
                    />

                    <TextField
                        label="End Date"
                        id="combo-box-demo"
                        type='date'
                        InputLabelProps={{ shrink: true }}
                        sx={{ width: "14%", margin: "1%" }}
                        onChange={(event, item) => {
                            const myDate = event.target.value.split("-");
                            const newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]).getTime();
                            setEndDate(newDate)
                        }}
                    />
                </Grid>

                {apiCalled === false ? <></> : ''}

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        options={datasetType}
                        value={currentDataSetType}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCurrentDataSetType(item.label);
                            setApiCalled(false);
                        }}
                        renderInput={(params) => <TextField {...params} label="Choose Dataset Type" />}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        variant="contained"
                        sx={{ width: "30%" }}
                        onClick={getPurchaseBetweenDates}>
                        Get Revenue
                    </Button>
                </Grid>

                {apiCalled === true && currentDataSetType === "Combined" ?
                    <>
                        <Grid item lg={4} md={6} sm={12} className="pt-5 " sx={{ width: "30%", marginBottom: "100px" }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center' sx={{ fontWeight: 'bold' }}>Plan</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Revenue</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {revenueData.map((row) => (
                                            <TableRow
                                                key={row._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{row._id === "annual" ? "Annual" : "Lifetime"}</TableCell>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {row.count ? "$ " + row.count.toFixed(2) : 0}
                                                </TableCell>
                                                {/* <TableCell align="center">{row.lifetime ? "$ " + row.lifetime.toFixed(2) : 0}</TableCell> */}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </> : ''
                }

                {apiCalled === true && currentDataSetType === "All-Dates" ?
                    <>
                        <Grid item lg={4} md={6} sm={12} className="pt-5 " sx={{ width: "30%", marginBottom: "100px" }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center'>Date</TableCell>
                                            <TableCell align="center">Annual</TableCell>
                                            <TableCell align="center">Lifetime</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {revenueTableData.map((row) => (
                                            <TableRow
                                                key={row._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{convertMsToDate(row._id)}</TableCell>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {row.annual ? "$ " + row.annual.toFixed(2) : 0}
                                                </TableCell>
                                                <TableCell align="center">{row.lifetime ? "$ " + row.lifetime.toFixed(2) : 0}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </> : ''
                }

                {apiCalled === true && currentDataSetType === "Annual" ?
                    <>
                        <Grid item lg={4} md={6} sm={12} className="pt-5 " sx={{ width: "30%", marginBottom: "100px" }}>
                            <TableContainer component={Paper}>
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align='center' sx={{ fontWeight: 'bold' }}>Annual</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Count</TableCell>
                                            <TableCell align="center" sx={{ fontWeight: 'bold' }}>Revenue</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {revenueData.map((row) => (
                                            <TableRow
                                                key={row._id}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                            >
                                                <TableCell align="center">{row.status === "conversion" ? "Conversions" : row.type === "renewal" ? "Total Renewals" : "Free Trials"}</TableCell>
                                                <TableCell component="th" scope="row" align='center'>
                                                    {row.count ? row.count : 0}
                                                </TableCell>
                                                <TableCell align="center">{row.type === "renewal" || row.status === "conversion" ? "$ " + (row.total * 1.00).toFixed(2) : 0}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </> : ''
                }
            </Grid>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                    <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                        Success!!!
                    </Alert>
                </Snackbar>
            </Stack>

            <Stack spacing={2} sx={{ width: '100%' }}>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                    <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                        Error!!!
                    </Alert>
                </Snackbar>
            </Stack>
        </Box >
    )
}

export default Stripe;