import secureLocalStorage from 'react-secure-storage'
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import MuiAlert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import { Input } from "@mui/material";
import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import ReactGA from "react-ga";
import { useNavigate } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.primary.dark,
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
    },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
    },
    '&:last-child td, &:last-child th': {
        border: 0,
    },
}));

function convertMillisecondsToDate(ms) {
    return new Date(ms).toString();
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const GmailUsers = () => {
    return <UserPost />;
};

const UserPost = () => {

    let navigate = useNavigate();

    const [username, setUsername] = useState(null);
    const [userData, setUserData] = useState({})

    const token = secureLocalStorage.getItem("token");
    const dashboardUid = secureLocalStorage.getItem("uid")

    const [open, setOpen] = React.useState(false);
    const [invalidTokenOpen, setInvalidTokenOpen] = React.useState(false);

    const [infoFetched, setInfoFetched] = React.useState(false);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleInvalidTokenClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setInvalidTokenOpen(false);
    };

    const GmailUsersHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "username": username, "dashboardUid": dashboardUid })
        };
        fetch('https://dev.blockerx.net/api/tickets/gmailUsernames', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === 200) {
                    setUserData(data.data.list)
                    setInfoFetched(true);
                    setOpen(true);
                } else if (data.status === 401) {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                } else if (data.status === 403) {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    secureLocalStorage.clear();
                    navigate("/tokenExpired");
                }
            })
            .catch((error) => {
                console.log(error);
                setInvalidTokenOpen(true)
            });
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    function makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
          counter += 1;
        }
        return result;
    }    

    return (
        <Box sx={{ flexGrow: 2 }}>
            <h1 className="header-top mt-5">Get Gmail Users</h1>
            <Grid align="center" justify="center" spacing={2}>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Input
                        disablePortal
                        placeholder="Enter username"
                        id="combo-box-demo"
                        sx={{ width: "400px" }}
                        onChange={(event, item) => {
                            setUsername(event.target.value);
                            setInfoFetched(false);
                        }}
                    />
                </Grid>

                <Grid item lg={4} md={6} sm={12} className="pt-5">
                    <Button
                        className="h-12"
                        sx={{ width: "400px" }}
                        onClick={GmailUsersHandler}
                        color="success"
                        variant="contained">
                        Get Users
                    </Button>
                </Grid>

                {infoFetched === true ?
                    <Grid>
                        <TableContainer>
                            <Table sx={{ width: 1400, marginTop: 10, marginBottom: 20 }} aria-label="customized table">
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>Username</StyledTableCell>
                                        <StyledTableCell>Country</StyledTableCell>
                                        <StyledTableCell>Language</StyledTableCell>
                                        <StyledTableCell>App Version</StyledTableCell>
                                        <StyledTableCell>Email</StyledTableCell>
                                        <StyledTableCell>Device</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>

                                    {
                                        userData.map((user) => {
                                            return (
                                                <StyledTableRow key={user.email + makeid(2)}>
                                                    <StyledTableCell component="th" scope="row">
                                                        {user.gmailUsername}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {user.country}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {user.language}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {user.appVersion}
                                                    </StyledTableCell>
                                                    <StyledTableCell component="th" scope="row">
                                                        {user.email}
                                                    </StyledTableCell>
                                                    <StyledTableCell align="left">
                                                        {user.device}
                                                    </StyledTableCell>
                                                </StyledTableRow>
                                            )
                                        })
                                    }

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    : <Grid> </Grid>}

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={open} autoHideDuration={2000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                            User information has been fetched!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={invalidTokenOpen} autoHideDuration={2000} onClose={handleInvalidTokenClose}>
                        <Alert onClose={handleInvalidTokenClose} severity="error" sx={{ width: '100%' }}>
                            Error
                        </Alert>
                    </Snackbar>
                </Stack>
            </Grid>


        </Box>
    );
}

export default GmailUsers;