import secureLocalStorage from 'react-secure-storage'
import React, { useState } from "react";

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import MuiAlert from '@mui/material/Alert';
import { Avatar, Card, CardContent, CardHeader, Input, Typography } from "@mui/material";
import { red } from '@mui/material/colors';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


import { useEffect } from 'react';
import ReactGA from "react-ga";
import { useNavigate } from "react-router-dom";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const FindUser = () => {

    const [disabled, setDisabled] = useState(false);
    const [premiumDisabled, setPremiumDisabled] = useState(false);
    let navigate = useNavigate();

    const [searchType, setSearchType] = useState("null");
    const [userValue, setUserValue] = useState("null");
    const [apiCalled, setApiCalled] = useState(false);

    const [uid, setUid] = useState("null");
    const [userPresent, setUserPresent] = useState(false);

    // Success
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const closeSuccessAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenSuccessAlert(false);
    }

    // Error
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const closeErrorAlert = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenErrorAlert(false);
    }

    // Uid copied to clipboard
    const [openCopiedToClipboard, setOpenCopiedToClipboard] = useState(false);
    const closeCopiedToClipboard = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenCopiedToClipboard(false);
    }


    const token = secureLocalStorage.getItem("token");

    const givePremiumHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', "authorization": `Bearer ${token}` },
            body: JSON.stringify({ "searchType": searchType, "userValue": userValue, "dashboardUid": secureLocalStorage.getItem("uid") })
        };

        fetch('http://dev.blockerx.net/api/dashboard/find/user', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setOpenSuccessAlert(true)
                    setUid(data.data.uid)
                    setApiCalled(true)
                } else if (data.status === 401) {
                    setOpenErrorAlert(true)
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else if (data.status === 403) {
                    secureLocalStorage.clear();
                    navigate("/forbidden");
                } else {
                    setOpenErrorAlert(true)
                    setApiCalled(false)
                }
            })
            .catch((error) => {
                setOpenErrorAlert(true)
                setApiCalled(false)
            })
    }

    useEffect(() => {
        ReactGA.pageview(window.location.pathname);
    }, []);

    const copyUidHandler = () => {
        navigator.clipboard.writeText(uid);
        setOpenCopiedToClipboard(true);
    }


    return (
        <>
            <Box sx={{ flexGrow: 2 }}>
                <h1 className="header-top mt-5">Find User</h1>
                <Grid align="center" justify="center" spacing={2}>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Autocomplete
                            disablePortal
                            id="combo-box-demo"
                            options={methodList}
                            sx={{ width: 450 }}
                            onChange={(event, item) => {
                                setSearchType(item.type);
                            }}
                            renderInput={(params) => <TextField {...params} label="Choose method to find user" />}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Input
                            disablePortal
                            placeholder="Enter value"
                            id="combo-box-demo"
                            sx={{ width: 450 }}
                            onChange={(event, item) => {
                                setUserValue(event.target.value);
                                setApiCalled(false)
                            }}
                        />
                    </Grid>

                    <Grid item lg={4} md={6} sm={12} className="pt-5">
                        <Button
                            className="h-12"
                            variant="contained"
                            sx={{ width: 450 }}
                            disabled={premiumDisabled}
                            onClick={givePremiumHandler}>
                            Find User
                        </Button>
                    </Grid>

                    {apiCalled ?
                        <Grid item lg={4} md={6} sm={12} className="pt-10">
                            <Card sx={{ width: 400 }}>
                                <CardHeader
                                    avatar={
                                        <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                            {uid ? uid[0] : ""}
                                        </Avatar>
                                    }
                                    title={"User ID"}
                                    align="left"
                                />
                                <CardContent>
                                    <Typography variant="body2" color="text.secondary">
                                         { uid }  <ContentCopyIcon className='copy--icon cursor-pointer' onClick={copyUidHandler} />
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid> : ""}

                </Grid>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openSuccessAlert} autoHideDuration={2000} onClose={closeSuccessAlert}>
                        <Alert onClose={closeSuccessAlert} severity="success" sx={{ width: '100%' }}>
                            Success!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={closeErrorAlert}>
                        <Alert onClose={closeErrorAlert} severity="error" sx={{ width: '100%' }}>
                            Error!!!
                        </Alert>
                    </Snackbar>
                </Stack>

                <Stack spacing={2} sx={{ width: '100%' }}>
                    <Snackbar open={openCopiedToClipboard} autoHideDuration={2000} onClose={closeCopiedToClipboard}>
                        <Alert onClose={closeCopiedToClipboard} severity="success" sx={{ width: '100%' }}>
                            The uid has been copied to clipboard.
                        </Alert>
                    </Snackbar>
                </Stack>
            </Box>
        </>
    )
}

const methodList = [
    {
        label: 'Community Username',
        type: 'communityUsername'
    }, {
        label: 'Email of user',
        type: 'email'
    }, {
        label: 'Partner Email',
        type: 'partnerMail'
    }, {
        label: 'Purchase Token',
        type: 'purchaseToken'
    }, {
        label: 'Order ID',
        type: 'orderId'
    }
];

export default FindUser;