import React, { useEffect, useState } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { Box } from '@mui/system'
import { styled } from '@mui/material/styles'
import TableRow from '@mui/material/TableRow'
import { Chip, CircularProgress, Grid, Tooltip } from '@mui/material'
import { showTimeInAgoFormat } from '../../../Utils/time.js'

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: '#353535',
        color: theme.palette.common.white,
        fontSize: 20
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16
    }
}))

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    '&:last-child td, &:last-child th': {
        border: 0
    }
}))

// Utility function to format status
const formatStatus = (status) => {
    try {
        return status.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
    } catch {
        return status
    }
}

// Custom styled component for the dot
const DotChip = styled(Chip)(({ theme, isTechReview }) => ({
    position: 'relative',
    '&::after': {
        content: '""',
        display: isTechReview ? 'block' : 'none',
        position: 'absolute',
        bottom: 4,
        right: 8,
        width: 5,
        height: 5,
        backgroundColor: '#ffffff',
        borderRadius: '50%',
    }
}));

function DeveloperTickets() {
    const [apiCalled, setApiCalled] = useState(false)
    const [developerList, setDeveloperList] = useState([])
    const [developerData, setDeveloperData] = useState([])
    const [showLoading, setShowLoading] = useState(true)

    const getDeveloperTickets = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' }
        }

        fetch('https://dev.blockerx.net/api/tickets/v2/developer', requestOptions)
            .then((response) => response.json())
            .then((data) => {
                console.log(data)
                if (data.status === 200) {
                    setDeveloperList(data.data.list)
                    if (Array.isArray(data.data.developerAssignedDetails)) {
                        setDeveloperData(data.data.developerAssignedDetails)
                    }
                    setApiCalled(true)
                    setShowLoading(false)
                } else {
                }
            })
            .catch(() => { })
    }

    const goToTicketPage = (ticketId) => {
        window.open(`/customer-tickets/${ticketId}`, '_blank')
    }

    useEffect(() => {
        if (!apiCalled) {
            getDeveloperTickets()
        }
    })

    return (
        <div>
            {developerList.length > 0 && (
                <Box sx={{ flexGrow: 2 }}>
                    <h1 className='header-top my-6 text-gray-700 font-bold'>Developer Tickets</h1>
                    <Grid align='center' justify='center' spacing={2}>
                        <Grid>
                            <TableContainer>
                                <Table sx={{ width: '80%', marginBottom: 10, border: '1px solid gray' }} aria-label='customized table'>
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell sx={{ width: '15%' }} align='left'>
                                                Developer
                                            </StyledTableCell>
                                            <StyledTableCell sx={{ width: '5%' }} align='center'>
                                                Count
                                            </StyledTableCell>
                                            <StyledTableCell align='left'>Assigned Tickets (Red color indicates that no private note added by developer in last 24 hrs)</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {developerList.length > 0 &&
                                            developerList
                                                .filter((user) => user.name !== 'None')
                                                .map((user) => {
                                                    return (
                                                        <StyledTableRow key={user.name}>
                                                            <StyledTableCell component='th' scope='row' align='left'>
                                                                {user.name}
                                                            </StyledTableCell>
                                                            <StyledTableCell align='center'>{user.count}</StyledTableCell>
                                                            <StyledTableCell align='left'>
                                                                {user.ticket_with_notes &&
                                                                    user.ticket_with_notes.map((ticket, index) => (
                                                                        <Tooltip key={ticket.ticketId} title={`Status: ${formatStatus(ticket.status)}`} arrow>
                                                                            <span onClick={() => goToTicketPage(ticket.ticketId)}>
                                                                                <DotChip
                                                                                    label={ticket.ticketId}
                                                                                    sx={{ marginLeft: '10px', marginBottom: '4px', cursor: 'pointer' }}
                                                                                    color='success'
                                                                                    isTechReview={ticket.status === 'tech_review'}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    ))}
                                                                {user.ticket_with_notes_v2 &&
                                                                    user.ticket_with_notes_v2.map((ticket, index) => (
                                                                        <Tooltip key={ticket.ticketId} title={`Status: ${formatStatus(ticket.status)}`} arrow>
                                                                            <span onClick={() => goToTicketPage(ticket.ticketId)}>
                                                                                <DotChip
                                                                                    label={ticket.ticketId}
                                                                                    sx={{ marginLeft: '10px', marginBottom: '4px', cursor: 'pointer' }}
                                                                                    color='warning'
                                                                                    isTechReview={ticket.status === 'tech_review'}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    ))}
                                                                {user.ticket_without_notes &&
                                                                    user.ticket_without_notes.map((ticket, index) => (
                                                                        <Tooltip key={ticket.ticketId} title={`Status: ${formatStatus(ticket.status)}`} arrow>
                                                                            <span onClick={() => goToTicketPage(ticket.ticketId)}>
                                                                                <DotChip
                                                                                    label={ticket.ticketId}
                                                                                    sx={{ marginLeft: '10px', marginBottom: '4px', cursor: 'pointer' }}
                                                                                    color='error'
                                                                                    isTechReview={ticket.status === 'tech_review'}
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    ))}
                                                            </StyledTableCell>
                                                        </StyledTableRow>
                                                    )
                                                })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Grid>
                    </Grid>

                    {developerData.length > 0 && (
                        <Grid align='center' justify='center' spacing={2}>
                            <Grid>
                                <TableContainer>
                                    <Table sx={{ width: '80%', marginBottom: 10, border: '1px solid gray' }} aria-label='customized table'>
                                        <TableHead>
                                            <TableRow>
                                                <StyledTableCell>Ticket</StyledTableCell>
                                                <StyledTableCell>Developer</StyledTableCell>
                                                <StyledTableCell>Assigned By</StyledTableCell>
                                                <StyledTableCell>Assigned At</StyledTableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {developerData.length > 0 &&
                                                developerData
                                                    .filter((ticket) => ticket.name !== 'None')
                                                    .map((ticket) => {
                                                        return (
                                                            <StyledTableRow key={ticket._id}>
                                                                <StyledTableCell component='th' scope='row' align='left'>
                                                                    {ticket._id}
                                                                </StyledTableCell>
                                                                <StyledTableCell>{ticket.developer}</StyledTableCell>
                                                                <StyledTableCell>{ticket.developerAssignedBy}</StyledTableCell>
                                                                <StyledTableCell>{showTimeInAgoFormat(ticket.developerAssignedAt)}</StyledTableCell>
                                                            </StyledTableRow>
                                                        )
                                                    })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>
                        </Grid>
                    )}
                </Box>
            )}

            {showLoading && (
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '300px'
                    }}>
                    <CircularProgress size='7rem' color='grey' />
                </Box>
            )}

            {!showLoading && developerList.length === 0 && (
                <>
                    {' '}
                    <div className='text-center mt-10 text-4xl font-bold text-gray-700'>No Developer Ticket found</div>
                </>
            )}
        </div>
    )
}

export default DeveloperTickets
