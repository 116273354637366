import React, { useState } from 'react'
import './Campaigns.css'
import { Autocomplete, Button, TextField } from '@mui/material';
import { currentCampaignTypeAutocomplete } from '../../arrays/customEvents';

function Campaigns() {

    const [campaignsList, setCampaignsList] = useState([{ label: 'campaign_list_usa' }])
    const [currentCampaign, setCurrentCampaign] = useState('campaign_list_usa')
    const [emailTemplateListAutocomplete, setEmailTemplateListAutocomplete] = useState([{ label: 'customer_support_feedback' }])
    const [currentEmailTemplate, setCurrentEmailTemplate] = useState('customer_support_feedback')
    const [notificationTemplateAutocomplete, setNotificationTemplateAutocomplete] = useState([ { label: 'notification_template'}])
    const [currentNotificationTemplate, setCurrentNotificationTemplate] = useState('notification_template')
    const [currentCampaignType, setCurrentCampaignType] = useState('Email')
    const [apiCalled, setApiCalled] = useState(false)

    const getCountOfCurrentPropertyValue = () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        fetch('https://events.blockerx.net/api/events/campaigns/list', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                    setCampaignsList(data.data.autocompleteList)
                    setCurrentCampaign(data.data.autocompleteList[0].label)
                    setApiCalled(true)
                } else {

                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    const startCampaignHandler = () => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ "name": currentCampaign, "type": currentCampaignType, "template": currentEmailTemplate })
        };

        fetch('https://events.blockerx.net/api/events/campaigns', requestOptions)
            .then(response => response.json())
            .then((data) => {
                console.log('Data : ', data)
                if (data.status === 200) {
                } else {

                }
            })
            .catch((error) => {
                console.log('Error : ', error)
            })
    }

    useState(() => {
        if (!apiCalled) {
            getCountOfCurrentPropertyValue()
        }
    })

    return (
        <div className='campaign__container'>
            <div className='campaign__heading font-bold text-4xl my-4'>
                Target Campaign
            </div>
            <div className='event__selection__container'>
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={currentCampaign}
                    options={campaignsList}
                    sx={{ width: "30%" }}
                    onChange={(event, item) => {
                        setCurrentCampaign(item.label)
                    }}
                    renderInput={(params) => <TextField {...params} label="Select Users list" />}
                />
                
                <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    value={currentCampaignType}
                    options={currentCampaignTypeAutocomplete}
                    sx={{ width: "30%" }}
                    onChange={(event, item) => {
                        setCurrentCampaignType(item.label)
                    }}
                    renderInput={(params) => <TextField {...params} label="Select campaign type" />}
                />
                {
                    currentCampaignType === 'Notification' && <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentNotificationTemplate}
                        options={notificationTemplateAutocomplete}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCurrentCampaignType(item.label)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select notification template" />}
                    />
                }

                {
                    currentCampaignType === 'Email' && <Autocomplete
                        disablePortal
                        id="combo-box-demo"
                        value={currentEmailTemplate}
                        options={emailTemplateListAutocomplete}
                        sx={{ width: "30%" }}
                        onChange={(event, item) => {
                            setCurrentCampaignType(item.label)
                        }}
                        renderInput={(params) => <TextField {...params} label="Select email template" />}
                    />
                }

                <Button sx={{ width: "30%", height: "50px", fontSize: "20px" }} variant='contained' onClick={startCampaignHandler}>Send Now</Button>
            </div>
        </div>
    )
}

export default Campaigns